import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AddKinForm from "./AddKinForm";
import { grey } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FetchHook from "../../../Api/FetchHooks";
import EditIcon from "@mui/icons-material/Edit";
import EditKinForm from "./EditKinForm";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteKinForm from "./DeleteKinForm";
import AlertComponent from "../../../Components/AlertComponent";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

const KinInfo = ({ user }) => {
  const [openModal, setOpen] = useState(false);
  const [openModal2, setOpen2] = useState(false);
  const [openModal3, setOpen3] = useState(false);
  const [kins, setKins] = useState([]);
  const [kin, setKin] = useState();
  const [check, setCheck] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loading, kinResponse, kinRequest] = FetchHook();

  const handleModal = () => {
    setOpen(!openModal);
  };

  const handleModal2 = () => {
    setOpen2(!openModal2);
  };

  const handleModal3 = () => {
    setOpen3(!openModal3);
  };

  useEffect(() => {
    if ((user?.id && kinResponse === undefined) || check === 0) {
      kinRequest({
        url: `/next_kin?member_entity_id=${user?.id}&status=active`,
      });
      setCheck(1);
    }

    if (kinResponse && kinResponse.status === 200) {
      setKins(kinResponse.data.Data);
    }
  }, [user, kinResponse, check]);

  return (
    <>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={openModal} onClose={handleModal}>
        <DialogTitle>Add next of kin/beneficiary form</DialogTitle>
        <DialogContent>
          <AddKinForm
            handleModal={handleModal}
            user={user}
            setCheck={setCheck}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openModal2} onClose={handleModal2}>
        <DialogTitle>Edit next of kin/beneficiary</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
          x
        >
          <EditKinForm
            user={{ kin: kin, user: user }}
            handleModal={handleModal2}
            setSeverity={setSeverity}
            handleSnackbar={handleSnackbar}
            setCheck={setCheck}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openModal3} onClose={handleModal3}>
        <DialogTitle>Edit next of kin/beneficiary</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <DeleteKinForm
            user={kin}
            handleModal={handleModal3}
            setCheck={setCheck}
            handleSnackbar={handleSnackbar}
            setSeverity={setSeverity}
          />
        </DialogContent>
      </Dialog>

      <Card variant="outlined">
        <CardHeader
          sx={{ background: grey[400] }}
          title={
            <Typography variant="h6">Kin / Beneficiary Information</Typography>
          }
          action={
            <Button variant="contained" color="primary" onClick={handleModal}>
              Add kin
            </Button>
          }
        />

        <CardContent>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : kins.length > 0 ? (
            <Box>
              {kins.map((el, i) => (
                <Card mb={2} key={i}>
                  <CardHeader
                    action={
                      <>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleModal2();
                            setKin(el);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => {
                            handleModal3();
                            setKin(el);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                    title={
                      <>
                        <Typography
                          component={"span"}
                          fontWeight="bold"
                          fontSize="lg"
                          style={{
                            textTransform: "capitalize",
                            marginRight: ".5rem",
                          }}
                        >
                          Name:{` ${el?.first_name} ${el?.other_names}`}
                        </Typography>

                        <Typography
                          component={"span"}
                          fontWeight="bold"
                          fontSize="lg"
                          style={{
                            textTransform: "capitalize",
                            marginRight: ".5rem",
                          }}
                        >
                          Allocation: {`${el?.allocation}`}
                        </Typography>
                      </>
                    }
                  />
                </Card>
              ))}
            </Box>
          ) : (
            <Box>
              <Typography align="center">
                No next of kin/beneficiary found
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default KinInfo;
