const button = {
  styleOverrides: {
    root: {
      borderRadius: "20px",
      // height: "50px",
    },
    containedPrimary: {
      "&:hover": {
        background: "#6D4C73",
      },
    },
  },
};

export default button;
