import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";
import { getUserDetails } from "../../../../utils/helpers";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import ApplyLoanForm from "./ApplyLoanForm";
import { grey } from "@mui/material/colors";
import AlertComponent from "../../../../Components/AlertComponent";
import PageLoader from "../../../../Components/PageLoader";
import TableComponent from "../../../../Components/TableComponent";

const UserLoans = ({ fetchUser }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(location.state?.user);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [check, setCheck] = useState(0);
  const [status, setStatus] = useState();
  const [severity, setSeverity] = useState({});

  const [loading, response, request] = FetchHook();

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  useEffect(() => {
    if (location.state === null) {
      setUser(getUserDetails());
    }
  }, [location]);

  useEffect(() => {
    if (check === 0 && user?.id) {
      request({
        url: `loan?entity_id='${user.id}'`,
      });
      setCheck(1);
    }
  }, [check, user]);

  const columns = [
    {
      field: "id",
      headerName: "Loan Id",
      width: 180,
    },
    // {
    //   field: "entity_id",
    //   headerName: "Entity Id",
    //   width: 150,
    // },
    // {
    //   field: "product_id",
    //   headerName: "Product Id",
    //   width: 150,
    // },
    // {
    //   field: "supplier_id",
    //   headerName: "Supplier Id",
    //   width: 150,
    // },
    {
      field: "request_id",
      headerName: "Request Id",
      width: 200,
    },
    {
      field: "loan_name",
      headerName: "Loan Name",
      width: 150,
    },
    // {
    //   field: "initial_payment",
    //   headerName: "Initial payment",
    //   width: 150,
    // },
    {
      field: "loan_principal",
      headerName: "Loan Amount",
    },
    {
      field: "amount_disburse",
      headerName: "Amount disbursed",
    },
    {
      field: "repayment_count",
      headerName: "Repayment duration ",
    },
    // {
    //   field: "charges_payable",
    //   headerName: "Charges",
    //   flex: 1,
    //   minWidth: 150,
    // },
    {
      field: "loan_interest",
      headerName: "Interest charged",
    },
    {
      field: "application_fee_payable",
      headerName: "Processing Fee",
    },
    // {
    //   field: "principal_payable",
    //   headerName: "Principal balance",
    //   flex: 1,
    //   minWidth: 200,
    // },
    // {
    //   field: "insurance_payable",
    //   headerName: "Insurance Balance",
    //   flex: 1,
    //   minWidth: 200,
    // },
    // {
    //   field: "interest_payable",
    //   headerName: "Interest Balance",
    //   width: 200,
    // },
    {
      field: "instalment_payable",
      headerName: "Instalment Balance",
    },
    {
      field: "balance_payable",
      headerName: "Total Balance Payable",
    },
    // {
    //   field: "loan_interest_bal",
    //   headerName: "Loan interest balance",
    //   width: 200,
    // },
    // {
    //   field: "application_instalment_fee_bal",
    //   headerName: "Application instalment fee balance",
    //   width: 150,
    // },
    // {
    //   field: "insurance_amount",
    //   headerName: "Insurance amount",
    //   width: 150,
    // },
    // {
    //   field: "insurance_bal",
    //   headerName: "Insurance balance",
    //   width: 150,
    // },
    // {
    //   field: "insurance_instalment_bal",
    //   headerName: "Insurance instalment balance",
    //   width: 150,
    // },
    // {
    //   field: "other_charges",
    //   headerName: "Other charges",
    //   width: 150,
    // },
    // {
    //   field: "other_charges_bal",
    //   headerName: "Other charges balance",
    //   width: 150,
    // },
    // {
    //   field: "other_instalment_charges_bal",
    //   headerName: "Other instalment charges balance",
    //   width: 150,
    // },

    // {
    //   field: "interest_last_date",
    //   headerName: "Last interest date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "rollover",
    //   headerName: "Rollover",
    //   width: 150,
    // },
    // {
    //   field: "rollover_interest",
    //   headerName: "Rollover interest",
    //   width: 150,
    // },
    // {
    //   field: "rollover_interest_bal",
    //   headerName: "Rollover interest balance",
    //   width: 150,
    // },
    // {
    //   field: "rollover_times",
    //   headerName: "Rollover times",
    //   width: 150,
    // },
    // {
    //   field: "rollover_start_date",
    //   headerName: "Rollover start date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "rollover_end_date",
    //   headerName: "Rollover end date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "rollover_next_date",
    //   headerName: "Rollover next date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "debt_collection",
    //   headerName: "Debt collection",
    //   width: 150,
    // },
    // {
    //   field: "debt_collection_bal",
    //   headerName: "Debt collection balance",
    //   width: 150,
    // },
    // {
    //   field: "debt_collection_start_date",
    //   headerName: "Debt colection start date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "debt_collection_end_date",
    //   headerName: "Debt collection end date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "last_contractual_instal_date",
    //   headerName: "last contractual installment date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "next_contractual_instal_date",
    //   headerName: "Next contractual installment date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "next_actual_instal_date",
    //   headerName: "Next actual installment date",
    //   flex: 1,
    //   minWidth: 240,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "net_actual_instal_date",
    //   headerName: "Net actual installment date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    {
      field: "loan_start_date",
      headerName: "Loan start date",
      width: 150,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "loan_end_date",
      headerName: "Loan end date",
      width: 150,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    // {
    //   field: "start_date",
    //   headerName: "Start date",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY"),
    // },
    // {
    //   field: "end_date",
    //   headerName: "End date",
    //   width: 150,
    //   valueFormatter: (params) => {
    //     if (params?.value) {
    //       moment(params?.value).format("DD/MM/YYYY hh:mm A");
    //     }
    //   },
    // },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "active_status",
      headerName: "Status",
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    // },
    {
      field: "loan_transactions",
      headerName: "",
      width: 180,
      renderCell: (row) => (
        <Button
          variant="outlined"
          onClick={() =>
            navigate("/dashboard/users/user-profile/loan-profile", {
              state: { user: location.state.user, loan: row.row },
            })
          }
        >
          View
        </Button>
      ),
    },
    // {
    //   field: "loan_transactions",
    //   headerName: "Loan transactions",
    //   width: 200,
    //   renderCell: (row) => (
    //     <Button
    //       variant="contained"
    //       onClick={() =>
    //         navigate("/dashboard/users/user-profile/loan-transactions", {
    //           state: location.state.user,
    //         })
    //       }
    //     >
    //       Loan transactions
    //     </Button>
    //   ),
    // },
    // {
    //   field: "loan_logs",
    //   headerName: "Loan logs",
    //   width: 200,
    //   renderCell: (row) => (
    //     <Button
    //       variant="contained"
    //       onClick={() =>
    //         navigate("/dashboard/users/user-profile/loan-logs", {
    //           state: row.row,
    //         })
    //       }
    //     >
    //       Loan logs
    //     </Button>
    //   ),
    // },
    // {
    //   field: "loan_instalment",
    //   headerName: "Loan instalment",
    //   width: 200,
    //   renderCell: (row) => (
    //     <Button
    //       variant="contained"
    //       onClick={() =>
    //         navigate("/dashboard/users/user-profile/loan-instalments", {
    //           state: row.row,
    //         })
    //       }
    //     >
    //       Loan instalments
    //     </Button>
    //   ),
    // },
  ];

  return (
    <Box>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle sx={{ background: grey[200] }}>
          Loan Application form
        </DialogTitle>
        <DialogContent sx={{ width: { sm: "350px" }, py: "40px", px: "40px" }}>
          <ApplyLoanForm
            handleModal={handleModal}
            setSeverity={setSeverity}
            user={user}
            handleSnackbar={handleSnackbar}
            fetchUser={fetchUser}
            fetchLoan={setCheck}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          title={<Typography variant="h5">User Loans</Typography>}
          action={
            <Box display={"flex"}>
              {/* <FormControl
                sx={{
                  width: "20vw",
                  mr: "20px",
                }}
              >
                <InputLabel id="status" size="small">
                  Status
                </InputLabel>

                <Select
                  labelId="loan_status"
                  id="loan_status"
                  value={status}
                  label="status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCheck(0);
                  }}
                  size="small"
                  defaultValue=""
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="domant">Domant</MenuItem>
                  <MenuItem value="suspended">Suspended</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                  <MenuItem value="incomplete">Incomplete</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                </Select>
              </FormControl> */}

              <Button variant="contained" onClick={handleModal}>
                Apply loan
              </Button>
            </Box>
          }
        />
        <CardContent>
          {response && response.data.StatusCode === 0 ? (
            <TableComponent
              data={response.data.Loan}
              columns={columns}
              loading={loading}
            />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserLoans;
