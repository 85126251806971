import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AlertComponent from "../../Components/AlertComponent";
import { MuiTelInput } from "mui-tel-input";
import FetchHook from "../../Api/FetchHooks";
import { useNavigate } from "react-router-dom";

const FindUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState();
  const [msisdn, setMsisdn] = useState();
  const [severity, setSeverity] = useState({});

  const [loading, response, request] = FetchHook();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = () => {
    if ((msisdn && msisdn.length < 13) || (msisdn && msisdn.length > 13)) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: "Enter a correct number",
      });
    } else {
      request({
        url: `entity?filter_value=${msisdn.replaceAll("+", "")}`,
      });
    }
  };

  useEffect(() => {
    if (response && response.status === 200) {
      navigate("/dashboard/users/user-profile", {
        state: { user: response.data.Data },
      });
    }
  }, [response]);

  return (
    <Box>
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Search user
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <FormControl fullWidth>
                <MuiTelInput
                  defaultCountry="KE"
                  value={msisdn}
                  disableFormatting
                  onChange={(e) => setMsisdn(e)}
                  required
                  fullWidth
                />
                {errors.msisdn && (
                  <FormHelperText>Phone number required!</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="contained"
                sx={{
                  my: "20px",
                }}
                type="submit"
              >
                search
              </Button>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FindUser;
