import { useState } from "react";
import {
  Box,
  Card,
  Avatar,
  Typography,
  CardHeader,
  CardContent,
  Grid,
  Button,
  Chip,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  CircularProgress,
} from "@mui/material";
// components
import { stringAvatar } from "../../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import UserTabs from "./UserTabs";
import { useForm } from "react-hook-form";
import PostHooks from "../../Api/PostHooks";
import { MuiTelInput } from "mui-tel-input";
import AlertComponent from "../../Components/AlertComponent";
import { LoadingButton } from "@mui/lab";
import FetchHook from "../../Api/FetchHooks";
import UserSavingsForm from "./Components/UserSavingsForm";
import PurchaseAirtimeForm from "./Components/PurchaseAirtimeForm";
import BoostShares from "../Shares/component/BoostShares";
import ApplyLoanForm from "./Components/UserLoan/ApplyLoanForm";
import FundPoolForm from "../PoolFund/FundPoolForm";
import { green } from "@mui/material/colors";
import moment from "moment";

export default function UserProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const LoggedInUser = useSelector((state) => state.auth.user);

  const [depositLoading, depositResponse, depositRequest] = PostHooks();
  const [activateLoading, activateResponse, activateRequest] = PostHooks();
  const [saveLoading, saveResponse, saveRequest] = PostHooks();
  const [balanceLoading, balanceResponse, balanceRequest] = FetchHook();
  const [withdrawPoolLoading, withdrawPoolResponse, withdrawPoolRequest] =
    PostHooks();
  const [, userResponse, userRequest] = FetchHook();

  const [user, setUser] = useState(location.state?.user);
  const name =
    user?.first_name ??
    null + " " + user?.other_name ??
    null + " " + user?.surname ??
    null;
  const [userBalances, setUserBalances] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [msisdn, setMsisdn] = useState();
  const [fetch, setFetch] = useState(0);
  const [userFetch, setUserFetch] = useState(1);
  const [actualSaving, setActualSaving] = useState(0);

  const handleModal = () => {
    setOpen(!open);
  };

  const handleModal2 = () => {
    setOpen2(!open2);
  };

  const handleModal3 = () => {
    setOpen3(!open3);
  };

  const handleModal4 = () => {
    setOpen4(!open4);
  };

  const handleModal5 = () => {
    setOpen5(!open5);
  };

  const handleModal6 = () => {
    setOpen6(!open6);
  };

  const handleModal7 = () => {
    setOpen7(!open7);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  useEffect(() => {
    if (user === undefined) {
      setUser(LoggedInUser);
    }

    if (userResponse && userResponse.data.StatusCode === 0) {
      setUser(userResponse.data.Data[0]);
    }
  }, [user, userResponse]);

  const fetchUser = () => {
    userRequest({
      url: `/entity?type=customer&entity_id=${user.id}`,
    });
  };

  useEffect(() => {
    if (userFetch === 0) {
      fetchUser();
      setUserFetch(1);
    }
  }, [userFetch]);

  const fetchUserBalances = () => {
    balanceRequest({
      url: `/account_bal?entity_id='${user?.id}'&type="customer"`,
    });
  };

  useEffect(() => {
    if (fetch === 0) {
      fetchUserBalances();
      setFetch(1);
    }

    if (
      balanceResponse &&
      balanceResponse.status === 200 &&
      balanceResponse.data.StatusCode === 0
    ) {
      setUserBalances(balanceResponse.data.Loan);

      // balanceResponse.data.Loan.map((el => ))

      const saving_wallet = balanceResponse.data.Loan.find(
        (el) => el.type === "saving_wallet"
      );
      const guarantorship_wallet = balanceResponse.data.Loan.find(
        (el) => el.type === "guarantorship_wallet"
      );

      const actual_saving =
        saving_wallet?.account_balance + guarantorship_wallet?.account_balance;

      setActualSaving(actual_saving);
    } else if (balanceResponse && balanceResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: "Token required!",
      });
      handleSnackbar();
    }
  }, [balanceResponse, fetch]);

  const deposit = (e) => {
    if (msisdn === undefined || (msisdn && msisdn.length < 13)) {
      setSeverity({
        severity: "error",
        message: "Please enter a valid phone number.",
      });
      handleSnackbar();
    } else {
      let formData = {
        amount: e.amount,
        msisdn: msisdn.replace("+", ""),
        entity_id: user.document_number,
      };

      depositRequest({
        url: "/stktest",
        body: formData,
      });
    }
  };

  useEffect(() => {
    if (
      depositResponse &&
      depositResponse.status === 200 &&
      depositResponse.data.StatusCode === 0
    ) {
      setSeverity({
        severity: "success",
        message: "Deposit initiated, check phone!",
      });
      handleSnackbar();
      handleModal();
    } else if (depositResponse && depositResponse.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: depositResponse.data.ResultDesc,
      });
      handleSnackbar();
    } else if (depositResponse && depositResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: depositResponse.response.data.message,
      });
      handleSnackbar();
    }
  }, [depositResponse]);

  const activateAcc = () => {
    let formData = {
      username: user.username,
      channel_name: user.channel_name,
    };

    activateRequest({
      url: "/approve_profile",
      body: formData,
    });
  };

  useEffect(() => {
    if (
      activateResponse &&
      activateResponse.status === 200 &&
      activateResponse.data.StatusCode === 0
    ) {
      setSeverity({
        severity: "success",
        message: "Account activated successfully!",
      });
      handleSnackbar();
      setUserFetch(0);
    } else if (
      activateResponse &&
      activateResponse.status === 200 &&
      activateResponse.data.StatusCode !== 0
    ) {
      setSeverity({
        severity: "error",
        message: activateResponse.data.StatusMessage,
      });
      handleSnackbar();
    } else if (activateResponse && activateResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: activateResponse.response.data.message,
      });
      handleSnackbar();
    }
  }, [activateResponse]);

  const save = (e) => {
    let formData = {
      entity_id: user.id,
      amount: e.amount,
    };

    saveRequest({
      url: "/save",
      body: formData,
    });
  };

  useEffect(() => {
    if (
      saveResponse &&
      saveResponse.status === 200 &&
      saveResponse.data.StatusCode === 0
    ) {
      handleModal2();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Save successful!",
      });
      setFetch(0);
    } else if (
      saveResponse &&
      saveResponse.status === 200 &&
      saveResponse.data.StatusCode !== 0
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: saveResponse.data.StatusMessage,
      });
    } else if (saveResponse && saveResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: saveResponse.data.StatusMessage,
      });
    }
  }, [saveResponse]);

  console.log(saveResponse);

  const withdrawPool = (e) => {
    let formData = {
      entity_id: user.id,
      amount: parseInt(e.amount),
    };

    withdrawPoolRequest({
      url: "/request_pool_fund",
      body: formData,
    });
  };

  useEffect(() => {
    if (withdrawPoolResponse && withdrawPoolResponse.data.StatusCode === 0) {
      handleModal4();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Withdraw request sent, await approval!",
      });
      setFetch(0);
      setUserFetch(0);
    } else if (
      withdrawPoolResponse &&
      withdrawPoolResponse.data.StatusCode !== 0
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: withdrawPoolResponse.data.StatusMessage,
      });
    } else if (withdrawPoolResponse && withdrawPoolResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: withdrawPoolResponse.data.StatusMessage,
      });
    }
  }, [withdrawPoolResponse]);

  return (
    <>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      {/* Deposit modal */}
      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Deposit Form</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <form onSubmit={handleSubmit(deposit)} style={{ width: "auto" }}>
            <FormControl fullWidth>
              <MuiTelInput
                sx={{
                  border: "1px solid black",
                }}
                defaultCountry="KE"
                disableFormatting
                value={msisdn}
                onChange={(e) => setMsisdn(e)}
              />
            </FormControl>

            <FormControl
              fullWidth
              error={!!errors.amount}
              sx={{
                mt: "20px",
              }}
            >
              <InputLabel htmlFor="amount">Amount</InputLabel>
              <OutlinedInput
                id="amount"
                type="amount"
                {...register("amount", { required: true })}
                label="Username"
              />
              {errors.amount && (
                <FormHelperText>Please enter an amount!</FormHelperText>
              )}
            </FormControl>

            <Box
              sx={{
                pt: "40px",
                display: "flex",
                direction: "row",
                justifyContent: "space-between",
              }}
            >
              <Button type="reset" variant="outlined" onClick={handleModal}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={depositLoading}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/* Top us savings modal */}
      <Dialog open={open2} onClose={handleModal2}>
        <DialogTitle variant="h5">Top us savings </DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <UserSavingsForm
              save={save}
              handleModal2={handleModal2}
              saveLoading={saveLoading}
            />
          </Box>
        </DialogContent>
      </Dialog>

      {/* Pool fund modal */}
      <Dialog open={open3} onClose={handleModal3}>
        <DialogTitle>Pool fund</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <FundPoolForm
            handleModal3={handleModal3}
            handleSnackbar={handleSnackbar}
            setSeverity={setSeverity}
            setFetch={setFetch}
            fetchUser={fetchUser}
            user={user}
          />
        </DialogContent>
      </Dialog>

      {/* Withdraw pool fund */}
      <Dialog open={open4} onClose={handleModal4}>
        <DialogTitle>Withdraw your pool fund</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <form onSubmit={handleSubmit(withdrawPool)} style={{ width: "auto" }}>
            <FormControl
              fullWidth
              error={!!errors.amount}
              sx={{
                mt: "5px",
              }}
            >
              <InputLabel htmlFor="amount">Amount</InputLabel>
              <OutlinedInput
                id="amount"
                type="number"
                {...register("amount", { required: true })}
                label="Username"
              />
              {errors.amount && (
                <FormHelperText>Please enter an amount!</FormHelperText>
              )}
            </FormControl>

            <Box
              sx={{
                pt: "40px",
                display: "flex",
                direction: "row",
                justifyContent: "space-between",
              }}
            >
              <Button type="reset" variant="outlined" onClick={handleModal4}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={withdrawPoolLoading}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/* Purchase airtime modal */}
      <Dialog open={open5} onClose={handleModal5}>
        <DialogTitle>Purchase airtime</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <PurchaseAirtimeForm
            user={user}
            setSeverity={setSeverity}
            handleModal={handleModal5}
            handleSnackbar={handleSnackbar}
          />
        </DialogContent>
      </Dialog>

      {/* Boost shares */}
      <Dialog open={open6} onClose={handleModal6}>
        <DialogTitle>Boost shares (KSH 10 per share)</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <BoostShares
            user={user}
            fetchUser={fetchUser}
            setSeverity={setSeverity}
            handleModal={handleModal6}
            handleSnackbar={handleSnackbar}
          />
        </DialogContent>
      </Dialog>

      {/* Loan application */}
      <Dialog open={open7} onClose={handleModal7}>
        <DialogTitle>Apply loan</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <ApplyLoanForm
            user={user}
            fetchUser={fetchUser}
            setSeverity={setSeverity}
            handleModal={handleModal7}
            handleSnackbar={handleSnackbar}
            fetchLoan={fetchUser}
          />
        </DialogContent>
      </Dialog>

      {user !== undefined && (
        <Box>
          <Card
            sx={{
              mb: 3,
              mx: "20px",
            }}
          >
            <CardHeader
              title={<Typography variant="h3">User Profile</Typography>}
              action={
                location.pathname !== "/dashboard" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                )
              }
            />

            <CardContent>
              <Box sx={{ display: "flex", gap: "20px", py: "20px" }}>
                {user?.active_status === "active" ? (
                  <Chip
                    label="Registration paid fully"
                    variant="contained"
                    color="success"
                    sx={{ color: "white" }}
                  />
                ) : (
                  user?.active_status === "inactive" && (
                    <Chip
                      label="Registration not paid"
                      variant="contained"
                      color="error"
                    />
                  )
                )}

                {user?.kyc_status === "active" ? (
                  <Chip
                    label="active"
                    color="success"
                    sx={{ color: "white" }}
                  />
                ) : user?.kyc_status === "suspended" ? (
                  <Chip label="suspended" color="warning" />
                ) : (
                  <Chip label="inactive" color="error" />
                )}
              </Box>

              <Grid container spacing={{ md: 10, sm: 4 }} sx={{ pb: "20px" }}>
                <Grid
                  item
                  lg={6}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  <Stack>
                    <Typography variant="h5">Username</Typography>
                    <Typography>{user?.username}</Typography>
                  </Stack>

                  <Stack>
                    <Typography variant="h5">Name</Typography>
                    <Typography>
                      {user?.first_name ??
                        "" + " " + user?.other_names ??
                        "" + " " + user?.surname ??
                        ""}
                    </Typography>
                  </Stack>

                  <Stack>
                    <Typography variant="h5">Phone number</Typography>
                    <Typography>{user?.msisdn}</Typography>
                  </Stack>

                  <Stack>
                    <Typography variant="h5">Id Number</Typography>
                    <Typography>{user?.id_number}</Typography>
                  </Stack>

                  <Stack>
                    <Typography variant="h5">Date joined</Typography>
                    <Typography>
                      {moment(user?.start_date).format("YYYY-MM-DD hh:mm a")}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid
                  item
                  lg={6}
                  sx={{
                    py: 4,
                  }}
                >
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal}
                  >
                    Wallet Deposit
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal2}
                  >
                    Boost Savings
                  </Button>
                  &nbsp;&nbsp;
                  {LoggedInUser.role === "admin" &&
                    user?.kyc_status !== "active" && (
                      <LoadingButton
                        sx={{
                          my: "6px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => activateAcc()}
                        loading={activateLoading}
                      >
                        Activate
                      </LoadingButton>
                    )}
                  &nbsp;&nbsp;
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal3}
                  >
                    Pool fund
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal4}
                  >
                    Withdraw pool fund
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal5}
                  >
                    Purchase airtime
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal6}
                  >
                    Boost shares
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    sx={{
                      my: "6px",
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleModal7}
                  >
                    Apply loan
                  </Button>
                  {LoggedInUser.role === "admin" && (
                    <>
                      &nbsp;&nbsp;
                      <Button
                        sx={{
                          my: "6px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Exit
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        sx={{
                          my: "6px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Unblock
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>

              {/* {LoggedInUser?.role !== "admin" && ( */}
              <Stack
                display={"flex"}
                direction={"row"}
                // spacing={{ md: 4 }}
              >
                {balanceLoading ? (
                  <CircularProgress />
                ) : (
                  userBalances.length > 0 && (
                    <>
                      <Grid container gap={2}>
                        {userBalances.map((el, i) => (
                          <Grid item key={i} xs={6} md={6} lg={2}>
                            <Card variant="outlined">
                              <CardContent>
                                <Typography variant="h5" component="h2">
                                  {el.type === "in_wallet"
                                    ? "E-wallet"
                                    : el.type === "saving_wallet"
                                    ? "Available Savings"
                                    : el.type === "pool_fund"
                                    ? "Pool Fund"
                                    : el.type === "guarantorship_wallet"
                                    ? "Actual savings"
                                    : el.type === "no_of_shares"
                                    ? "Share"
                                    : ""}
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                  Balance
                                </Typography>
                                <Typography variant="h6" component="p">
                                  KSH{" "}
                                  {el.type === "in_wallet"
                                    ? el.account_balance.toLocaleString()
                                    : el.type === "saving_wallet"
                                    ? el.account_balance.toLocaleString()
                                    : el.type === "pool_fund"
                                    ? el.account_balance.toLocaleString()
                                    : el.type === "guarantorship_wallet"
                                    ? actualSaving.toLocaleString()
                                    : el.type === "no_of_shares"
                                    ? el.account_balance.toLocaleString()
                                    : ""}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )
                )}
              </Stack>
              {/* )} */}
            </CardContent>

            <CardActions>
              <UserTabs user={user} fetchUser={fetchUser} />
            </CardActions>
          </Card>
        </Box>
      )}
    </>
  );
}
