import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FetchHook from "../../Api/FetchHooks";
import AlertComponent from "../../Components/AlertComponent";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Components/PageLoader";
import TableComponent from "../../Components/TableComponent";

const LoanRequests = ({ user }) => {
  const navigate = useNavigate();

  const [status, setStatus] = useState("pending");
  const [check, setCheck] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alert, setAlert] = useState({});

  const [loading, response, request] = FetchHook();

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  useEffect(() => {
    if (check === 0 && user?.id && status !== undefined) {
      request({
        url: `/loan_request?entity_id=${user?.id}&status='${status}'`,
      });
      setCheck(1);
    } else if (check === 0 && user?.id) {
      request({
        url: `/loan_request?entity_id=${user?.id}`,
      });
      setCheck(1);
    } else if (check === 0) {
      request({
        url: `/loan_request`,
      });
      setCheck(1);
    }
  }, [check, user]);

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 160,
    },
    {
      field: "loan_name",
      headerName: "Loan Name",
      width: 130,
    },
    // {
    //   field: "inventory_id",
    //   headerName: "Inventory id",
    //   width: 160,
    // },
    // {
    //   field: "product_id",
    //   headerName: "Product id",
    //   width: 150,
    // },
    // {
    //   field: "supplier_id",
    //   headerName: "Supplier id",
    //   width: 150,
    // },
    // {
    //   field: "entity_id",
    //   headerName: "Entity id",
    //   width: 150,
    // },
    // {
    //   field: "amount_paid",
    //   headerName: "Amount paid",
    //   width: 120,
    // },
    {
      field: "loan_principal",
      headerName: "Amount applied",
    },
    // {
    //   field: "principal_balance",
    //   headerName: "Principle balance",
    //   width: 130,
    // },
    // {
    //   field: "loan_interest",
    //   headerName: "Loan interest",
    //   width: 130,
    // },
    // {
    //   field: "loan_interest_bal",
    //   headerName: "Loan interest bal",
    //   width: 130,
    // },
    // {
    //   field: "rollover_interest",
    //   headerName: "Rollover interest",
    //   width: 130,
    // },
    // {
    //   field: "rollover_interest_bal",
    //   headerName: "Rollover interest bal",
    //   width: 150,
    // },
    // {
    //   field: "debt_collection",
    //   headerName: "Debt collection",
    //   width: 130,
    // },
    // {
    //   field: "debt_collection_bal",
    //   headerName: "Debt collection bal",
    //   width: 130,
    // },
    // {
    //   field: "application_fee_amount",
    //   headerName: "Application fee amount",
    //   width: 150,
    // },
    // {
    //   field: "application_fee_bal",
    //   headerName: "Application fee bal",
    //   width: 150,
    // },
    // {
    //   field: "insurance_amount",
    //   headerName: "Insuarance amount",
    //   width: 150,
    // },
    // {
    //   field: "insurance_bal",
    //   headerName: "Insuarance amount bal",
    //   width: 150,
    // },
    // {
    //   field: "other_charges",
    //   headerName: "Other charges",
    //   width: 130,
    // },
    {
      field: "guarantor_saving",
      headerName: "Guarantorship amount",
      width: 150,
    },
    {
      field: "guarantorship_balance",
      headerName: "Guarantorship balance",
      width: 150,
    },
    // {
    //   field: "other_charges_bal",
    //   headerName: "Other charges",
    //   width: 130,
    // },
    // {
    //   field: "start_date",
    //   headerName: "Start date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "end_date",
    //   headerName: "End date",
    //   width: 170,
    //   valueFormatter: (params) => {
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A");
    //   },
    // },
    // {
    //   field: "last_contractual_instal_date",
    //   headerName: "last_contractual_instal_date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "next_actual_instal_date",
    //   headerName: "next_actual_instal_date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "next_contractual_instal_date",
    //   headerName: "next_contractual_instal_date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "net_actual_instal_date",
    //   headerName: "net_actual_instal_date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },

    // {
    //   field: "loan_approval_date",
    //   headerName: "Loan approval date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "loan_start_date",
    //   headerName: "Loan start date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "loan_end_date",
    //   headerName: "Loan end date",
    //   width: 170,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 400,
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    // },
    {
      field: "active_status",
      headerName: "Status",
    },
    // {
    //   field: "loan_approval",
    //   headerName: "Loan approval",
    //   width: 120,
    // },
    {
      field: "loan_request_date",
      headerName: "Loan request date",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (row) => (
        <Box>
          <Button
            variant="outlined"
            style={{ marginLeft: 16, fontWeight: "bold" }}
            onClick={() => {
              navigate("/dashboard/loan-requests/profile", { state: row.row });
            }}
          >
            More
          </Button>
        </Box>
      ),
      width: 180,
    },
  ];

  return (
    <Box sx={{ margin: { sm: "20px" } }}>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={alert}
      />

      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">Loan Requests</Typography>
            </Box>
          }
          action={
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="status" size="small">
                Status
              </InputLabel>

              <Select
                labelId="status"
                id="status"
                value={status}
                label="status"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setCheck(0);
                }}
                size="small"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
                <MenuItem value="domant">Domant</MenuItem>
                <MenuItem value="suspended">Suspended</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
                <MenuItem value="incomplete">Incomplete</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <TableComponent
              data={response.data.Loan}
              columns={columns}
              loading={loading}
            />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoanRequests;
