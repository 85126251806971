import { createSlice } from "@reduxjs/toolkit";

const initialState = { loans: "{}" };

export const loanslice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    loans: (state, action) => {
      state.loans = action.payload;
    },
  },
});

export const { loans } = loanslice.actions;

export default loanslice.reducer;
