import { createSlice } from "@reduxjs/toolkit";

const initialState = { inventories: "{}" };

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    inventories: (state, action) => {
      state.inventories = action.payload;
    },
  },
});

export const { inventories } = inventorySlice.actions;

export default inventorySlice.reducer;
