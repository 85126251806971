import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FetchHook from "../../Api/FetchHooks";
import PutHooks from "../../Api/PutHook";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../../utils/helpers";
import TableComponent from "../../Components/TableComponent";

const UserSavings = ({ user }) => {
  const location = useLocation();
  const loggedInUser = getUserDetails();

  const [status, setStatus] = useState("active");
  const [check, setCheck] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alert, setAlert] = useState();

  const [loading, response, request] = FetchHook();

  console.log("response", response);

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  useEffect(() => {
    if (user?.id === undefined) {
      user = location.state;
    } else if (check === 0 && user?.id !== undefined) {
      request({
        url: `/wallet_transaction?type=saving_wallet&entity_id="${user.id}"`,
      });
      setCheck(1);
    }
  }, [check, user]);

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction id",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "source_transaction_id",
      headerName: "Source transaction id",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "account_number",
      headerName: "Account number",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "loan_id",
      headerName: "Loan id",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "global_request_id",
      headerName: "Global request id",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "dr_cr_pair_id",
      headerName: "Dr Cr pair",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "debit",
      headerName: "Debit (money out)",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "credit",
      headerName: "Credit (money in)",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "balance_before",
      headerName: "Balance before",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "balance_after",
      headerName: "Balance after",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "transaction_status",
      headerName: "Status",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "transaction_settlement_time",
      headerName: "Transaction settlement time",
      minWidth: 170,
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "transaction_timestamp",
      headerName: "Transaction time",
      minWidth: 170,
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   renderCell: (row) => (
    //     <Button
    //       variant="contained"
    //       style={{ marginLeft: 16, fontWeight: "bold" }}
    //       disabled={row.row.status === 1 ? false : true}
    //       onClick={() => fixLoanRequest(row.row)}
    //     >
    //       {fixLoading ? "Fixing..." : "Fix request"}
    //     </Button>
    //   ),
    //   width: 200,
    // },
  ];

  const custCols = [
    {
      field: "transaction_id",
      headerName: "Transaction id",
      width: 170,
    },
    {
      field: "debit",
      headerName: "Debit (money out)",
    },
    {
      field: "credit",
      headerName: "Credit (money in)",
    },
    {
      field: "balance_before",
      headerName: "Balance before",
    },
    {
      field: "balance_after",
      headerName: "Balance after",
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
    },
    {
      field: "transaction_status",
      headerName: "Status",
    },
    {
      field: "transaction_timestamp",
      headerName: "Transaction time",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ margin: { sm: "20px" } }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbar}
      >
        <Alert
          onClose={handleSnackbar}
          severity={alert && alert.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alert && alert.message}
        </Alert>
      </Snackbar>

      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">User Savings</Typography>

              {/* <FormControl>
                <InputLabel id="status">Status</InputLabel>

                <Select
                  labelId="loan_status"
                  id="loan_status"
                  value={status}
                  label="status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCheck(0);
                  }}
                  size="small"
                  defaultValue="active"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="domant">Domant</MenuItem>
                  <MenuItem value="suspended">Suspended</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl> */}
            </Box>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <TableComponent
              data={response.data.Loan}
              // columns={loggedInUser.role === "admin" ? columns : custCols}
              columns={custCols}
              loading={loading}
            />
          ) : (
            <Grid display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserSavings;
