import { createTheme } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import palette from "./components/palette";
import typography from "./components/typography";
import button from "./components/button";

const DefaultTheme = createTheme({
  palette: palette.light,
  typography,
  components: {
    MuiButton: button,
    MuiLoadingButton: button,
  },
});

export default DefaultTheme;
