import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import CustomerRegistrationForm from "./components/CustomerRegistrationForm";
import { styled } from "@mui/material";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const CustomerRegistration = () => {
  return (
    // <Container>
    //   <Grid
    //     Container
    //     spacing={2}
    //     display={"flex"}
    //     direction={"column"}
    //     justifyContent="center"
    //     alignItems="center"
    //     sx={{
    //       px: "40px",
    //       height: "fit-content",
    //       width: { sm: "fit-content", md: "500px" },
    //       m: "auto",
    //     }}
    //   >
    //     <Grid item>
    //       <Stack direction="row" alignItems="center">
    //         <Box sx={{ flexGrow: 1, py: "20px" }}>
    //           <Box sx={{ mb: "30px" }}>
    //             <img
    //               src="logo/dsacco.png"
    //               alt="logo"
    //               width={150}
    //               height={120}
    //             />
    //           </Box>

    //           <Typography variant="h4" gutterBottom>
    //             Welcome to customer self sign up.
    //           </Typography>
    //           <Typography sx={{ color: "text.secondary" }}>
    //             Enter your details below.
    //           </Typography>
    //         </Box>
    //       </Stack>
    //     </Grid>

    //     <Grid item>
    //       <CustomerRegistrationForm />
    //     </Grid>
    //   </Grid>
    // </Container>

    <Box>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="column" alignItems="center" spacing={6}>
              <Box>
                <img
                  src="/images/logo/dSacco.png"
                  alt="logo"
                  width={150}
                  height={120}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                  Welcome to member self sign up.
                </Typography>
                <Typography sx={{ color: "text.secondary" }} align="center">
                  Enter your details below.
                </Typography>
              </Box>

              <Box
                sx={{
                  m: "auto",
                }}
              >
                <CustomerRegistrationForm />
              </Box>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Box>
  );
};

export default CustomerRegistration;
