import React from "react";
import FetchHook from "../../Api/FetchHooks";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import TableComponent from "../../Components/TableComponent";
import PageLoader from "../../Components/PageLoader";

const PrinciplePaid = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [check, setCheck] = useState(0);

  const [open, setOpen] = React.useState(false);

  const [loading, response, request] = FetchHook();

  const fetchFees = () => {
    request({
      url: `/loan_report?status=closed&start_date='${
        moment(startDate).format("YYYY-MM-DD") ??
        moment(new Date()).format("YYYY-MM-DD")
      }'&end_date='${
        moment(endDate).format("YYYY-MM-DD") ??
        moment(new Date()).format("YYYY-MM-DD")
      }'`,
    });
  };

  useEffect(() => {
    if (check === 0) {
      fetchFees();
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "loan_name",
      headerName: "Loan name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "msisdn",
      headerName: "Phone Number",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "principal_paid",
      headerName: "Amount",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "transaction_timestamp",
      headerName: "Transaction Date",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("YYYY-MM-DD"),
    },
  ];

  console.log("response", response);

  return (
    <Box sx={{ p: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card elevation={4}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item flex={1}>
                  <Typography variant="h4">Principle Repaid</Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <DatePicker
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          onClick={(e) => setOpen(true)}
                          label="Start Date"
                        />
                      );
                    }}
                  />{" "}
                  ----{" "}
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          onClick={(e) => setOpen(true)}
                          label="End Date"
                        />
                      );
                    }}
                  />
                  {startDate !== undefined && endDate !== undefined && (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        setCheck(0);
                      }}
                    >
                      search
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} sx={{ height: "calc(100vh - 180px)" }}>
                  {response && response.data.StatusCode === 0 ? (
                    <TableComponent
                      data={response.data.Report}
                      columns={columns}
                      loading={loading}
                      sortModel={[
                        {
                          field: "transaction_timestamp",
                          sort: "desc",
                        },
                      ]}
                    />
                  ) : (
                    <PageLoader />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrinciplePaid;
