import {
  Backdrop,
  Box,
  CircularProgress,
  styled,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";

const TableComponent = ({ columns, data, loading, sortModel }) => {
  const theme = useTheme();

  const [pageSize, setPageSize] = useState(20);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid #BBC5C9`,
    },
    "& .MuiDataGrid-root, .MuiDataGrid-row": {
      "&:nth-of-type(2n)": {
        backgroundColor: grey[300],
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
      lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeader": {
      // Forced to use important since overriding inline styles
      height: "unset !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // Forced to use important since overriding inline styles
      maxHeight: "168px !important",
    },
  }));

  return (
    <Box sx={{ height: "calc(100vh - 180px)" }}>
      <StyledDataGrid
        // autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20, 30]}
        rows={data}
        columns={columns}
        loading={loading}
        getRowId={(row) => generateRandom()}
        components={{
          Toolbar: CustomToolbar,
        }}
        slots={{
          loadingOverlay: (
            <Backdrop
              sx={{
                color: theme.palette.background.default,
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ),
        }}
        sortingOrder={["desc", "asc"]}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
        }}
      />
    </Box>
  );
};

export default TableComponent;
