import { Grid, Table, TableBody, TableRow } from "@mui/material";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React from "react";

import { useSelector } from "react-redux";
import moment from "moment";
import KinInfo from "./KinInfo";

const UserProfileInfo = ({ user }) => {
  const loggedInUser = useSelector((state) => state.auth.user);

  const username = user.username;
  const role = user.role;
  const document_number = user.document_number;
  const id = user.id;
  const msisdn = user?.msisdn;
  const start_date = user?.start_date;
  const name =
    user?.first_name ??
    null + " " + user?.other_name ??
    null + " " + user?.surname ??
    null;

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          my: 3,
          width: "auto",
        }}
      >
        <Grid item>
          <Card variant="outlined">
            <CardHeader
              title={<Typography variant="h6">Personal Information</Typography>}
              sx={{ background: grey[400] }}
            />

            <CardContent>
              <table>
                <tbody>
                  <tr>
                    <td>Username</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {username}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "200px",
                      }}
                    >
                      Name
                    </td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {name}
                    </td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {role}
                    </td>
                  </tr>
                  <tr>
                    <td>Identification No</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {document_number}
                    </td>
                  </tr>

                  <tr>
                    <td>Phone number:</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {msisdn}
                    </td>
                  </tr>

                  {(loggedInUser.role === "admin" ||
                    loggedInUser.role === "finance" ||
                    loggedInUser.role === "ccr") && (
                    <>
                      <tr>
                        <td>Platform id</td>
                        <td style={{ color: green[800], fontWeight: "bold" }}>
                          {id}
                        </td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td>Date joined:</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {moment(start_date).format("YYYY-MM-DD hh:mm a")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        </Grid>

        {/* <Grid
          item
          sx={{
            width: { xs: "100%", md: "400px" },
          }}
        >
          <Card variant="outlined">
            <CardHeader
              title={<Typography variant="h6">Account Details</Typography>}
              sx={{ background: grey[400] }}
            />

            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <td>Pool fund</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {user.pool_fund}
                    </td>
                  </TableRow>
                  <TableRow>
                    <td>Shares</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {user.shares}
                    </td>
                  </TableRow>
                  <TableRow>
                    <td>Registration fee</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {user.reg}
                    </td>
                  </TableRow>
                  <TableRow>
                    <td>Registration balance</td>
                    <td style={{ color: green[800], fontWeight: "bold" }}>
                      {user.bal_reg}
                    </td>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid> */}

        <Grid
          item
          sx={{
            width: { xs: "100%", md: "400px" },
          }}
        >
          <KinInfo user={user} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfileInfo;
