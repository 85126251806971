import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FetchHook from "../../Api/FetchHooks";
import { userData, usersData } from "../../Redux/userSlice";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { Backdrop } from "@mui/material";
import PageLoader from "../../Components/PageLoader";
import TableComponent from "../../Components/TableComponent";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.usersData);

  const [open, setOpen] = useState(false);
  const [fetch, setFetch] = useState(0);
  const [data, setData] = useState(users.usersData);
  const [loading, response, request] = FetchHook();

  const handleModal = () => {
    setOpen(!open);
  };

  // function to fetch users
  const fetchUsers = () => {
    let props = {
      url: "/entity",
    };

    request(props);
  };

  useEffect(() => {
    if (fetch === 0 || users.usersData.length <= 0) {
      fetchUsers();
      setFetch(1);
    }

    if (response && response.status === 200) {
      dispatch(usersData(response.data.Data));
      setData(response.data.Data);
    }
  }, [fetch, response]);

  const columns = [
    { field: "id", headerName: "Entity Id", width: 180 },
    {
      field: "document_number",
      headerName: "Identification No",
      width: 150,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      valueGetter: (params) => {
        return `${params.row?.first_name ?? null} ${
          params.row?.surname ?? null
        } ${params.row?.other_names ?? null}`;
      },
      width: 200,
    },
    { field: "msisdn", headerName: "Phone No", minWidth: 150 },
    { field: "role", headerName: "Role" },
    { field: "active_status", headerName: "Status" },
    {
      field: "start_date",
      headerName: "Start date",
      minWidth: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "profile",
      headerName: "Profile",
      renderCell: (row) => (
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 16, fontWeight: "normal" }}
          onClick={() => {
            navigate("/dashboard/users/user-profile", {
              state: { user: row.row },
            });
            dispatch(userData(row.row));
          }}
        >
          Profile
        </Button>
      ),
      width: 100,
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Card>
        <CardHeader
          title={<Typography variant="h5">Users</Typography>}
          action={
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate("/dashboard/users/create-user")}
              >
                add user
              </Button>
            </Box>
          }
        />
        <CardContent>
          {data && data.length ? (
            <TableComponent columns={columns} data={data} loading={loading} />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Users;
