import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";
import { userAccount, userAccounts } from "../../../../Redux/userSlice";
import { getUserDetails } from "../../../../utils/helpers";
import { Backdrop } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import PostHookAccount from "../../../../Api/PostHookAccount";
import { grey } from "@mui/material/colors";
import AddAccountForm from "./AddAccountForm";
import AlertComponent from "../../../../Components/AlertComponent";
import TableComponent from "../../../../Components/TableComponent";

const UserAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedInUser = getUserDetails();
  const accData = useSelector((state) => state.usersData.userAccounts);

  const [user, setUser] = useState(location.state?.user);
  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [account_t, AccountTypeID] = useState("");
  const [fetchAcc, setFetchAcc] = useState(0);
  const [props, setProps] = useState();
  const [checkP, setCheckP] = useState(0);
  const [account_type, AccountType] = useState([
    {
      label: "Shareholder",
      value: "shareholder",
    },
    {
      label: "SMS Supplier",
      value: "sms_supplier",
    },
    // {
    //   label: "TV Supplier",
    //   value: "tv_supplier",
    // },
    // {
    //   label: "Fridge Supplier",
    //   value: "fridge_supplier",
    // },
    // {
    //   label: "SMS Inventory",
    //   value: "sms_inventory",
    // },
    // {
    //   label: "TV Inventory",
    //   value: "tv_inventory",
    // },
    // {
    //   label: "Fridge Inventory",
    //   value: "fridge_inventory",
    // },
    {
      label: "Airtime supplier",
      value: "airtime_supplier",
    },
    {
      label: "Airtime inventory",
      value: "airtime_inventory",
    },
  ]);

  const [loading, response, request] = FetchHook();
  const [accLoading, accResponse, accRequest] = PostHookAccount();

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const fetchAccounts = () => {
    request({ url: `account?type="${user?.role}"&entity_id="${user?.id}"` });
  };

  useEffect(() => {
    if (((check === 0 && accData.length <= 0) || fetchAcc === 0) && user?.id) {
      if (user?.id) {
        fetchAccounts();
      }
      setCheck(1);
      setFetchAcc(1);
    }
    if (response && response.status === 200) {
      dispatch(userAccounts(response.data.Loan));
    }
  }, [response, check, fetchAcc, location, user]);

  useEffect(() => {
    if (location.state === null) {
      setUser(getUserDetails());
    }
  }, [location]);

  const columns = [
    {
      field: "account_number",
      headerName: "Account number",
      width: 200,
    },
    {
      field: "account_name",
      headerName: "Account name",
      width: 300,
    },
    {
      field: "type",
      headerName: "Account type",
      width: 200,
    },
    {
      field: "account_balance",
      headerName: "Account balance",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (row) => (
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: 16, fontWeight: "bold" }}
          onClick={() => {
            navigate("/dashboard/users/user-profile/account-profile", {
              state: { account: row.row, user: user },
            });
            dispatch(userAccount(row.row));
          }}
        >
          More
        </Button>
      ),
      width: 200,
    },
  ];

  const addAccount = (e) => {
    if (account_t == "shareholder") {
      let formDataShareholder = {
        shareholder_entity_id: user.id,
        notification: "Yes",
        notification_type: "sms",
        type: account_t,
        admin_channel: "",
        callback_url: "",
        role: user.role,
      };
      const customerProps = {
        // post body and the url
        url: "api_1",
        body: formDataShareholder,
      };
      setProps(customerProps);
      setCheckP(1);
    }
    // if (account_t == "sms_inventory") {
    //   let formDataShareholder = {
    //     inventory_item_entity_id: user.id,
    //     notification: "Yes",
    //     notification_type: "sms",
    //     type: account_t,
    //     admin_channel: "",
    //     callback_url: "",
    //     role: user.role,
    //   };
    //   const customerProps = {
    //     // post body and the url
    //     url: "api_3",
    //     body: formDataShareholder,
    //   };
    //   setProps(customerProps);
    //   setCheckP(1);
    // } else
    //  if (account_t == "fridge_inventory") {
    //   let formDataShareholder = {
    //     inventory_item_entity_id: user.id,
    //     notification: "Yes",
    //     notification_type: "sms",
    //     type: "inventory",
    //     admin_channel: "",
    //     callback_url: "",
    //     role: user.role,
    //   };
    //   const customerProps = {
    //     // post body and the url
    //     url: "api_3",
    //     body: formDataShareholder,
    //   };
    //   setProps(customerProps);
    //   setCheckP(1);
    // } else if (account_t == "fridge_supplier") {
    //   let formDataShareholder = {
    //     supplier_entity_id: user.id,
    //     notification: "Yes",
    //     notification_type: "sms",
    //     type: "supplier",
    //     admin_channel: "",
    //     callback_url: "",
    //     role: user.role,
    //   };
    //   const customerProps = {
    //     // post body and the url
    //     url: "api_2",
    //     body: formDataShareholder,
    //   };
    //   setProps(customerProps);
    //   setCheckP(1);
    // } else
    else if (account_t == "sms_supplier") {
      let formDataShareholder = {
        supplier_entity_id: user.id,
        notification: "Yes",
        notification_type: "sms",
        type: account_t,
        admin_channel: "",
        callback_url: "",
        role: user.role,
      };
      const customerProps = {
        // post body and the url
        url: "api_2",
        body: formDataShareholder,
      };
      setProps(customerProps);
      setCheckP(1);
    }
    //  if (account_t == "tv_supplier") {
    //   let formDataShareholder = {
    //     supplier_entity_id: user.id,
    //     notification: "Yes",
    //     notification_type: "sms",
    //     type: "supplier",
    //     admin_channel: "",
    //     callback_url: "",
    //     role: user.role,
    //   };
    //   const customerProps = {
    //     // post body and the url
    //     url: "api_2",
    //     body: formDataShareholder,
    //   };
    //   setProps(customerProps);
    //   setCheckP(1);
    // } else if (account_t == "tv_inventory") {
    //   let formDataShareholder = {
    //     inventory_item_entity_id: user.id,
    //     notification: "Yes",
    //     notification_type: "sms",
    //     type: "inventory",
    //     admin_channel: "",
    //     callback_url: "",
    //     role: user.role,
    //   };
    //   const customerProps = {
    //     // post body and the url
    //     url: "api_3",
    //     body: formDataShareholder,
    //   };
    //   setProps(customerProps);
    //   setCheckP(1);
    // } else
    else if (account_t == "airtime_inventory") {
      let formDataShareholder = {
        inventory_item_entity_id: user.id,
        notification: "Yes",
        notification_type: "sms",
        type: "airtime_inventory",
        admin_channel: "",
        callback_url: "",
        role: user.role,
      };
      const customerProps = {
        // post body and the url
        url: "api_3",
        body: formDataShareholder,
      };
      setProps(customerProps);
      setCheckP(1);
    } else if (account_t == "airtime_supplier") {
      let formDataShareholder = {
        supplier_entity_id: user.id,
        notification: "Yes",
        notification_type: "sms",
        type: "airtime_supplier",
        admin_channel: "",
        callback_url: "",
        role: user.role,
      };
      const customerProps = {
        // post body and the url
        url: "api_2",
        body: formDataShareholder,
      };
      setProps(customerProps);
      setCheckP(1);
    }
  };

  useEffect(() => {
    if (checkP === 1) {
      accRequest(props);
      setCheckP(0);
    }

    if (
      accResponse &&
      accResponse.status === 200 &&
      accResponse.data.StatusCode === 0
    ) {
      handleSnackbar();
      handleModal();
      setSeverity({
        severity: "success",
        message: "Acoount added successfuly!",
      });
      setFetchAcc(0);
    } else if (
      accResponse &&
      accResponse.status === 200 &&
      accResponse.data.StatusCode !== 0
    ) {
      setSeverity({
        severity: "error",
        message: accResponse.data.StatusMessage,
      });
      handleSnackbar();
    } else if (accResponse && accResponse.status !== 201) {
      setSeverity({
        severity: "error",
        message: accResponse.response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [checkP, accResponse]);

  return (
    <Box>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle sx={{ background: grey[200] }}>Add account</DialogTitle>
        <DialogContent>
          <AddAccountForm
            account_t={account_t}
            AccountTypeID={AccountTypeID}
            account_type={account_type}
            handleModal={handleModal}
            accLoading={accLoading}
            addAccount={addAccount}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          sx={{ background: grey[200] }}
          title={<Typography variant="h6">User Accounts</Typography>}
          action={
            <Box>
              {(loggedInUser.role === "admin" ||
                loggedInUser.role === "finance") && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModal}
                >
                  Add Account
                </Button>
              )}
            </Box>
          }
        />
        <CardContent>
          {accData && accData.length >= 1 ? (
            <TableComponent
              columns={columns}
              data={accData}
              loading={loading}
            />
          ) : accData && accData.length <= 0 && loading === true ? (
            <Grid display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Grid>
          ) : (
            <Typography variant="h6">No accounts found. Create one!</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserAccounts;
