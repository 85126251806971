import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { boxSizing } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import PostHooks from "../../../Api/PostHooks";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../Components/AlertComponent";
import PercentIcon from "@mui/icons-material/Percent";

const CreateProductForm = ({}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [loading, response, request] = PostHooks();
  const [loadingcreate, createResponse, createRequest] = PostHooks();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const now = new Date();
  const [open, setOpen] = useState();
  const [severity, setSeverity] = useState({});
  const [productProps, setProductProps] = useState();
  const [notification_freq, set_notification_freq] = useState();
  const [loan_type, set_loan_type] = useState();
  const [currency, setCurrency] = useState();
  const [interest_type, set_interest_type] = useState();
  const [loan_approval, set_loan_approval] = useState();
  const [loan_approval_date, set_loan_approval_date] = useState(new Date());
  const [loan_start_date, set_loan_start_date] = useState(new Date());
  const [rollover_start_date, set_rollover_start_date] = useState(new Date());
  const [loan_end_date, set_loan_end_date] = useState();
  const [priority_rule, set_priority_rule] = useState([]);
  const [loan_duration, set_loan_duration] = useState();
  const [upfront, set_upfront] = useState();
  const [loan_interest_frequency, set_loan_interest_frequency] = useState();
  const [rollover_frequency, set_rollover_frequency] = useState();
  const [debt_collection, set_debt_collection] = useState();
  const [debt_collection_type, set_debt_collection_type] = useState();
  const [early_repayment, set_early_repayment] = useState();
  const [early_repayment_plan, set_early_repayment_plan] = useState();
  const [loan_agent, set_loan_agent] = useState();
  const [top_up, set_top_up] = useState();
  const [insurance_fee, set_insurance_fee] = useState();
  const [guarantorship, setGuarantorship] = useState();
  const [allowMultiplierTimes, setAllowMultiplierTimes] = useState();
  const [loan_colleteral, set_loan_colleteral] = useState();
  const [active_status, set_active_status] = useState("active");
  const [recovery, setRecovery] = useState();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = (e) => {
    let formData = {
      loan_name: e.loan_name,
      entity_id: user.id,
      publisher_name: e.publisher_name,
      rev_commission_p: e.rev_commission_p,
      product_owner_id: user.id,
      notification_freq: notification_freq,
      loan_type: loan_type,
      currency: currency,
      interest_type: interest_type,
      loan_approval: "engine",
      loan_approval_date: moment(loan_approval_date).format(
        "YYYY-MM-DD hh:mm:ss"
      ),
      loan_start_date: moment(now).format("YYYY-MM-DD hh:mm:ss"),
      loan_end_date: moment(now).format("YYYY-MM-DD hh:mm:ss"),
      priority_rule: JSON.stringify(priority_rule),
      minimum_instalment_count: e.minimum_instalment_count,
      maximum_loan_amount: e.maximum_loan_amount,
      minimum_loan_amount: e.minimum_loan_amount,
      minimum_saving_amount: e.minimum_saving_amount,
      grace_period_interest: e.grace_period_interest,
      loan_duration: loan_duration,
      upfront: upfront,
      loan_interest_rate: e.loan_interest_rate,
      loan_interest_frequency: loan_interest_frequency,
      rollover_frequency: rollover_frequency,
      rollover_interest_rate: e.rollover_interest_rate,
      rollover_days: e.rollover_days,
      rollover_start_date: "",
      grace_period_b_rollover: e.grace_period_b_rollover,
      debt_collection: debt_collection,
      debt_collection_fee: "0",
      debt_collection_fee_p: e.debt_collection_fee_p,
      days_to_debt_collection: e.days_to_debt_collection,
      debt_collection_type: debt_collection_type,
      application_fee_amount: "0",
      application_fee_p: e.application_fee_p,
      early_repayment: early_repayment,
      early_repayment_plan: early_repayment_plan,
      loan_agent: loan_agent,
      agent_commission: "0",
      agent_commission_p: e.agent_commission_p,
      top_up: top_up,
      top_up_amount: "0",
      top_up_p: e.top_up_p,
      insurance_fee: insurance_fee,
      insurance_amount: "0",
      insurance_p: e.insurance_p,
      loan_colleteral: loan_colleteral,
      collecteral_types: e.collecteral_types,
      other_charges: e.other_charges,
      marker: "",
      checker: "",
      active_status: "active",
      guarantorship: guarantorship,
      minimum_saving_amount: e.minimum_saving_amount,
      maximum_saving_percentage: e.maximum_saving_percentage,
      upto_saving_percentage: e.upto_saving_percentage,
      maximum_guarantor_percentage: e.maximum_guarantor_percentage,
      recovery: recovery,
      instalment_before_recovery: e.instalment_before_recovery,
    };

    let reqProps = {
      url: "create_product",
      body: formData,
    };

    request(reqProps);
  };

  useEffect(() => {
    if (response && response.status === 200) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Product created successfuly!",
      });
      // setTimeout(() => {
      //   navigate("/dashboard/products");
      // }, 3000);
    } else if (response && response.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.response.data.StatusMessage,
      });
    }
  }, [response]);

  useEffect(() => {
    if (createResponse && createResponse.status === 200) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Accounts created successfuly!",
      });
    } else if (createResponse && createResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: createResponse.createResponse.data.StatusMessage,
      });
    }
  }, [createResponse]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Grid container display={"flex"} spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="loan_type">Loan type</InputLabel>
            <Select
              labelId="loan_type"
              id="loan_type"
              defaultValue=""
              value={loan_type}
              label="Loan type"
              onChange={(e) => set_loan_type(e.target.value)}
            >
              <MenuItem value="postpaid">Postpaid</MenuItem>
              <MenuItem value="prepaid">Prepaid</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.loan_name}>
            <InputLabel htmlFor="loan_name">Loan Name</InputLabel>
            <OutlinedInput
              id="loan_name"
              type="text"
              {...register("loan_name")}
              label="Loan name"
              fullWidth
            />
            {errors.loan_name && (
              <FormHelperText>Enter loan name</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="currency">Currency</InputLabel>
            <Select
              labelId="currency"
              id="currency"
              defaultValue=""
              value={currency}
              label="Currency"
              onChange={(e) => setCurrency(e.target.value)}
            >
              <MenuItem value="KES">KES</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="loan_duration">Loan duration</InputLabel>
            <Select
              labelId="loan_duration"
              id="loan_duration"
              defaultValue=""
              value={loan_duration}
              label="Loan duration"
              onChange={(e) => set_loan_duration(e.target.value)}
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="two week">Two weeks</MenuItem>
              <MenuItem value="three week">Three weeks</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="three month">Three Months</MenuItem>
              <MenuItem value="six month">Six Months</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.maximum_loan_amount}>
            <InputLabel htmlFor="maximum_loan_amount">
              Maximum loan amount
            </InputLabel>
            <OutlinedInput
              id="maximum_loan_amount"
              type="number"
              {...register("maximum_loan_amount")}
              label="Maximum loan amount"
              fullWidth
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
            />
            {errors.maximum_loan_amount && (
              <FormHelperText>Enter maximum loan amount</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.minimum_loan_amount}>
            <InputLabel htmlFor="minimum_loan_amount">
              Minimum loan amount
            </InputLabel>
            <OutlinedInput
              id="minimum_loan_amount"
              type="number"
              {...register("minimum_loan_amount")}
              label="Minimum loan amount"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
            />
            {errors.minimum_loan_amount && (
              <FormHelperText>Enter minimum loan amount</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="guarantorship">Guarantorship</InputLabel>
            <Select
              labelId="guarantorship"
              id="guarantorship"
              defaultValue=""
              value={guarantorship}
              label="Guarantorship"
              onChange={(e) => setGuarantorship(e.target.value)}
            >
              <MenuItem value="YES">Other</MenuItem>
              <MenuItem value="NO">Self</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {guarantorship === "YES" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl
                fullWidth
                error={!!errors.maximum_guarantor_percentage}
              >
                <InputLabel htmlFor="maximum_guarantor_percentage">
                  Maximum guarantor percentage
                </InputLabel>
                <OutlinedInput
                  id="maximum_guarantor_percentage"
                  type="number"
                  {...register("maximum_guarantor_percentage")}
                  label="Maximum guarantor percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  fullWidth
                  defaultValue="100"
                  inputProps={{
                    step: "any",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.maximum_guarantor_percentage && (
                  <FormHelperText>
                    Enter maximum guarantor percentage!
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl
                fullWidth
                error={!!errors.maximum_guarantor_percentage}
              >
                <InputLabel htmlFor="maximum_saving_percentage">
                  Maximum saving percentage
                </InputLabel>
                <OutlinedInput
                  id="maximum_saving_percentage"
                  type="number"
                  {...register("maximum_saving_percentage")}
                  label="Maximum saving percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  fullWidth
                  defaultValue="0"
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.maximum_saving_percentage && (
                  <FormHelperText>
                    Enter maximum saving percentage!
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="allowMultiplierTimes">
                  Allow multiplier times
                </InputLabel>
                <Select
                  labelId="allowMultiplierTimes"
                  id="allowMultiplierTimes"
                  defaultValue=""
                  value={allowMultiplierTimes}
                  label="Allow multiplier times"
                  onChange={(e) => setAllowMultiplierTimes(e.target.value)}
                >
                  <MenuItem value="YES">Yes</MenuItem>
                  <MenuItem value="NO">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {allowMultiplierTimes === "YES" && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{
                    paddingTop: "20px",
                  }}
                >
                  <FormControl fullWidth error={!!errors.multiplier_times}>
                    <InputLabel htmlFor="multiplier_times">
                      Multiplier times
                    </InputLabel>
                    <OutlinedInput
                      id="multiplier_times"
                      type="number"
                      {...register("multiplier_times")}
                      label="Multiplier times"
                      onWheel={(event) => {
                        event.target.blur();
                      }}
                      inputProps={{
                        step: "any",
                      }}
                      fullWidth
                      defaultValue="0"
                    />
                    {errors.multiplier_times && (
                      <FormHelperText>Enter multiplier times!</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.minimum_instalment_count}>
            <InputLabel htmlFor="minimum_instalment_count">
              Minimum Installment count
            </InputLabel>
            <OutlinedInput
              id="minimum_instalment_count"
              type="number"
              {...register("minimum_instalment_count")}
              label="Minimum Installment count"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
            />
            {errors.minimum_instalment_count && (
              <FormHelperText>Enter minimum installment count!</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {guarantorship === "NO" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.minimum_saving_amount}>
                <InputLabel htmlFor="minimum_saving_amount">
                  Minimum saving amount
                </InputLabel>
                <OutlinedInput
                  id="minimum_saving_amount"
                  type="number"
                  {...register("minimum_saving_amount")}
                  label="Minimum saving amount"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  fullWidth
                  defaultValue="0"
                />
                {errors.minimum_saving_amount && (
                  <FormHelperText>Enter minimum saving amount!</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.upto_saving_percentage}>
                <InputLabel htmlFor="upto_saving_percentage">
                  Upto saving amount
                </InputLabel>
                <OutlinedInput
                  id="upto_saving_percentage"
                  type="number"
                  {...register("upto_saving_percentage")}
                  label="Upto saving amount"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  fullWidth
                  defaultValue="0"
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.upto_saving_percentage && (
                  <FormHelperText>Enter Upto saving amount!</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="interest_type">Interest type</InputLabel>
            <Select
              labelId="interest_type"
              id="interest_type"
              defaultValue=""
              value={interest_type}
              label="Interest type"
              onChange={(e) => set_interest_type(e.target.value)}
            >
              <MenuItem value="reducing_balance">Reducing Balance</MenuItem>
              <MenuItem value="static">Static</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="loan_interest_frequency">
              Loan interest frequency
            </InputLabel>
            <Select
              labelId="loan_interest_frequency"
              id="loan_interest_frequency"
              defaultValue=""
              value={loan_interest_frequency}
              label="Loan interest frequency"
              onChange={(e) => set_loan_interest_frequency(e.target.value)}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="two_months">Two months</MenuItem>
              <MenuItem value="three_months">Three months</MenuItem>
              <MenuItem value="six_months">Six months</MenuItem>
              <MenuItem value="one_year">One year</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.loan_interest_rate}>
            <InputLabel htmlFor="loan_interest_rate">
              Loan interest rate
            </InputLabel>
            <OutlinedInput
              id="loan_interest_rate"
              type="number"
              {...register("loan_interest_rate")}
              label="Loan interest rate"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              }
              fullWidth
            />
            {errors.loan_interest_rate && (
              <FormHelperText>Enter loan interest rate</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.application_fee_p}>
            <InputLabel htmlFor="application_fee_p">
              Application fee percentage
            </InputLabel>
            <OutlinedInput
              id="application_fee_p"
              type="number"
              {...register("application_fee_p")}
              label="Application fee percentage"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              }
            />
            {errors.application_fee_p && (
              <FormHelperText>Enter application fee</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="insurance_fee">Insuarance fee</InputLabel>
            <Select
              labelId="insurance_fee"
              id="insurance_fee"
              defaultValue=""
              value={insurance_fee}
              label="Insuarance fee"
              onChange={(e) => set_insurance_fee(e.target.value)}
            >
              <MenuItem value="YES">Yes</MenuItem>
              <MenuItem value="NO">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {insurance_fee === "YES" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.insurance_p}>
                <InputLabel htmlFor="insurance_p">
                  Insuarance percentage
                </InputLabel>
                <OutlinedInput
                  id="insurance_p"
                  type="number"
                  {...register("insurance_p")}
                  label="Insuarance percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  fullWidth
                  defaultValue="0"
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.insurance_p && (
                  <FormHelperText>Enter loan name</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="upfront">Upfront</InputLabel>
            <Select
              labelId="upfront"
              id="upfront"
              defaultValue=""
              value={upfront}
              label="Upfront"
              onChange={(e) => set_upfront(e.target.value)}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="early_repayment">Early repayment</InputLabel>
            <Select
              labelId="early_repayment"
              id="early_repayment"
              defaultValue=""
              value={early_repayment}
              label="Early repayment"
              onChange={(e) => set_early_repayment(e.target.value)}
            >
              <MenuItem value="YES">Yes</MenuItem>
              <MenuItem value="NO">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="early_repayment_plan">
              Early repayment plan
            </InputLabel>
            <Select
              labelId="early_repayment_plan"
              id="early_repayment_plan"
              defaultValue=""
              value={early_repayment_plan}
              label="Early repayment plan"
              onChange={(e) => set_early_repayment_plan(e.target.value)}
            >
              <MenuItem value="days_in_loan">Days in loan</MenuItem>
              <MenuItem value="full_loan">Full loan</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.publisher_name}>
            <InputLabel htmlFor="publisher_name">Publisher Name</InputLabel>
            <OutlinedInput
              id="publisher_name"
              type="text"
              {...register("publisher_name")}
              label="Publisher name"
              fullWidth
            />
            {errors.password && (
              <FormHelperText>Enter publisher name</FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="notification_freq">
              Notification frequency
            </InputLabel>
            <Select
              labelId="notification_freq"
              id="notification_freq"
              defaultValue=""
              value={notification_freq}
              label="Notification frequency"
              onChange={(e) => set_notification_freq(e.target.value)}
            >
              <MenuItem value="hourly">Hourly</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="two_weeks">Two weeks</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="three_months">Three Months</MenuItem>
              <MenuItem value="six_months">Six Months</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.rollover_days}>
            <InputLabel htmlFor="rollover_days">Rollover days</InputLabel>
            <OutlinedInput
              id="rollover_days"
              type="number"
              {...register("rollover_days")}
              label="Rollover days"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
            />
            {errors.rollover_days && (
              <FormHelperText>Enter rollover days</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="rollover_frequency">Rollover frequency</InputLabel>
            <Select
              labelId="rollover_frequency"
              id="rollover_frequency"
              // defaultValue={""}
              value={rollover_frequency}
              label="Rollover frequency"
              onChange={(e) => set_rollover_frequency(e.target.value)}
            >
              <MenuItem value="day">Daily</MenuItem>
              <MenuItem value="week">weekly</MenuItem>
              <MenuItem value="month">Monthly</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.rollover_interest_rate}>
            <InputLabel htmlFor="rollover_interest_rate">
              Rollover interest rate
            </InputLabel>
            <OutlinedInput
              id="rollover_interest_rate"
              type="number"
              {...register("rollover_interest_rate")}
              label="Rollover interest rate"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
            />
            {errors.rollover_interest_rate && (
              <FormHelperText>Enter rollover interest rate</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.grace_period_b_rollover}>
            <InputLabel htmlFor="grace_period_b_rollover">
              Grace period before rollover
            </InputLabel>
            <OutlinedInput
              id="grace_period_b_rollover"
              type="number"
              {...register("grace_period_b_rollover")}
              label="Grace period before rollover"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
            />
            {errors.grace_period_b_rollover && (
              <FormHelperText>
                Enter Grace period before rollover
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.grace_period_interest}>
            <InputLabel htmlFor="grace_period_interest">
              Grace period interest
            </InputLabel>
            <OutlinedInput
              id="grace_period_interest"
              type="number"
              {...register("grace_period_interest")}
              label="Grace period interest"
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
              fullWidth
            />
            {errors.grace_period_interest && (
              <FormHelperText>Enter grace period interest</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="recovery">Recovery</InputLabel>
            <Select
              labelId="recovery"
              id="recovery"
              defaultValue=""
              value={recovery}
              label="Recovery"
              onChange={(e) => setRecovery(e.target.value)}
            >
              <MenuItem value="debt_collection">Debt collection</MenuItem>
              <MenuItem value="rollover">Rollover</MenuItem>
              <MenuItem value="loan_end_date">Loan end date</MenuItem>
              <MenuItem value="before_loan_end_date">
                Before Loan end date
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {recovery === "before_loan_end_date" && (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{
              paddingTop: "20px",
            }}
          >
            <FormControl fullWidth error={!!errors.instalment_before_recovery}>
              <InputLabel htmlFor="instalment_before_recovery">
                Installment before recovery
              </InputLabel>
              <OutlinedInput
                id="instalment_before_recovery"
                type="number"
                {...register("instalment_before_recovery")}
                label="Installment before recovery"
                onWheel={(event) => {
                  event.target.blur();
                }}
                inputProps={{
                  step: "any",
                }}
                fullWidth
                defaultValue="0"
              />
              {errors.instalment_before_recovery && (
                <FormHelperText>Enter installment</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="debt_collection">Debt collection</InputLabel>
            <Select
              labelId="debt_collection"
              id="debt_collection"
              defaultValue=""
              value={debt_collection}
              label="Debt collection"
              onChange={(e) => set_debt_collection(e.target.value)}
            >
              <MenuItem value="YES">Yes</MenuItem>
              <MenuItem value="NO">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {debt_collection === "YES" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.debt_collection_fee_p}>
                <InputLabel htmlFor="debt_collection_fee_p">
                  Debt collection fee percentage
                </InputLabel>
                <OutlinedInput
                  id="debt_collection_fee_p"
                  type="number"
                  {...register("debt_collection_fee_p")}
                  label="Debt collection fee percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  fullWidth
                  defaultValue="0"
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.debt_collection_fee_p && (
                  <FormHelperText>Enter loan name</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.days_to_debt_collection}>
                <InputLabel htmlFor="days_to_debt_collection">
                  Days to debt collection
                </InputLabel>
                <OutlinedInput
                  id="days_to_debt_collection"
                  type="number"
                  {...register("days_to_debt_collection")}
                  label="Days to debt collection"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  fullWidth
                  defaultValue="0"
                />
                {errors.days_to_debt_collection && (
                  <FormHelperText>Enter loan name</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="debt_collection_type">
                  Debt collection type
                </InputLabel>
                <Select
                  labelId="debt_collection_type"
                  id="debt_collection_type"
                  defaultValue=""
                  value={debt_collection_type}
                  label="Debt collection type"
                  onChange={(e) => set_debt_collection_type(e.target.value)}
                >
                  <MenuItem value="one_off">One off</MenuItem>
                  <MenuItem value="reccur">Reccur</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="priority_rule">Priority rule</InputLabel>
            <Select
              labelId="priority_rule"
              id="priority_rule"
              multiple
              value={priority_rule}
              onChange={(e) => {
                const {
                  target: { value },
                } = e;

                set_priority_rule(
                  typeof value === "string" ? value.split(",") : value
                );
              }}
              input={<OutlinedInput label="Priority rule" />}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="principal">
                <Checkbox checked={priority_rule.includes("principal")} />
                <ListItemText primary={"principal"} />
              </MenuItem>
              <MenuItem value="application_fee">
                <Checkbox checked={priority_rule.includes("application_fee")} />
                <ListItemText primary={"application_fee"} />
              </MenuItem>
              <MenuItem value="other_charges">
                <Checkbox checked={priority_rule.includes("other_charges")} />
                <ListItemText primary={"other_charges"} />
              </MenuItem>
              <MenuItem value="insurance">
                <Checkbox checked={priority_rule.includes("insurance")} />

                <ListItemText primary={"insurance"} />
              </MenuItem>
              <MenuItem value="interest">
                <Checkbox checked={priority_rule.includes("interest")} />
                <ListItemText primary={"interest"} />
              </MenuItem>
              <MenuItem value="rollover">
                <Checkbox checked={priority_rule.includes("rollover")} />

                <ListItemText primary={"rollover"} />
              </MenuItem>
              <MenuItem value="debt_collection">
                <Checkbox checked={priority_rule.includes("debt_collection")} />
                <ListItemText primary={"debt_collection"} />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <DesktopDatePicker
              label="Rollover start date"
              inputFormat="MM/DD/YYYY"
              value={rollover_start_date}
              onChange={(e) => set_rollover_start_date(e)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.identification_number && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="loan_agent">Loan agent</InputLabel>
            <Select
              labelId="loan_agent"
              id="loan_agent"
              defaultValue=""
              value={loan_agent}
              label="Loan agent"
              onChange={(e) => set_loan_agent(e.target.value)}
            >
              <MenuItem value="YES">Yes</MenuItem>
              <MenuItem value="NO">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {loan_agent === "YES" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.agent_commission_p}>
                <InputLabel htmlFor="agent_commission_p">
                  Agent commision fee percentage
                </InputLabel>
                <OutlinedInput
                  id="agent_commission_p"
                  type="number"
                  {...register("agent_commission_p")}
                  label="Agent commision fee percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                  fullWidth
                  defaultValue="0"
                />
                {errors.agent_commission_p && (
                  <FormHelperText>Enter loan name</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.rev_commission_p}>
                <InputLabel htmlFor="rev_commission_p">
                  Rev commision percentage
                </InputLabel>
                <OutlinedInput
                  id="rev_commission_p"
                  type="number"
                  {...register("rev_commission_p")}
                  label="Rev commision percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  defaultValue=""
                  fullWidth
                  inputProps={{
                    step: "any",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.rev_commission_p && (
                  <FormHelperText>
                    Enter revenue commision percentage
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="top_up">Top up</InputLabel>
            <Select
              labelId="top_up"
              id="top_up"
              defaultValue=""
              value={top_up}
              label="Top up"
              onChange={(e) => set_top_up(e.target.value)}
            >
              <MenuItem value="YES">Yes</MenuItem>
              <MenuItem value="NO">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {top_up === "YES" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.top_up_p}>
                <InputLabel htmlFor="top_up_p">Top up percentage</InputLabel>
                <OutlinedInput
                  id="top_up_p"
                  type="number"
                  {...register("top_up_p")}
                  label="Top up percentage"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  inputProps={{
                    step: "any",
                  }}
                  fullWidth
                  defaultValue="0"
                  endAdornment={
                    <InputAdornment position="end">
                      <PercentIcon />
                    </InputAdornment>
                  }
                />
                {errors.top_up_p && (
                  <FormHelperText>Enter loan name</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="loan_colleteral">Loan Collateral</InputLabel>
            <Select
              labelId="loan_colleteral"
              id="loan_colleteral"
              defaultValue=""
              value={loan_colleteral}
              label="Loan Collateral"
              onChange={(e) => set_loan_colleteral(e.target.value)}
            >
              <MenuItem value="YES">Yes</MenuItem>
              <MenuItem value="NO">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {loan_colleteral === "YES" && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.collecteral_types}>
                <InputLabel htmlFor="collecteral_types">
                  Collateral type{" "}
                </InputLabel>
                <OutlinedInput
                  id="collecteral_types"
                  type="text"
                  {...register("collecteral_types")}
                  label="Collateral type "
                  fullWidth
                />
                {errors.collecteral_types && (
                  <FormHelperText>Enter loan name</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.other_charges}>
            <InputLabel htmlFor="other_charges">Other charges </InputLabel>
            <OutlinedInput
              id="other_charges"
              type="number"
              {...register("other_charges")}
              label="Other charges"
              fullWidth
              onWheel={(event) => {
                event.target.blur();
              }}
              inputProps={{
                step: "any",
              }}
            />
            {errors.other_charges && (
              <FormHelperText>Enter other cahrges</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* 
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="active_status">Status</InputLabel>
            <Select
              labelId="active_status"
              id="active_status"
              defaultValue=""
              value={active_status}
              label="Top up"
              onChange={(e) => set_active_status(e.target.value)}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
              <MenuItem value="dormant">Dormant</MenuItem>
              <MenuItem value="suspended">Suspended</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="loan_approval">Loan approval</InputLabel>
            <Select
              labelId="loan_approval"
              id="loan_approval"
              defaultValue=""
              value={loan_approval}
              label="loan_approval"
              onChange={(e) => set_loan_approval(e.target.value)}
            >
              <MenuItem value="engine">Engine</MenuItem>
              <MenuItem value="manual">Manual</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <DesktopDatePicker
              label="Loan approval date"
              inputFormat="MM/DD/YYYY"
              value={loan_approval_date}
              onChange={(e) => set_loan_approval_date(e)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.identification_number && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <DesktopDatePicker
              label="Loan start date"
              inputFormat="MM/DD/YYYY"
              value={loan_start_date}
              onChange={(e) => set_loan_start_date(e)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.identification_number && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <DesktopDatePicker
              label="Loan end date"
              inputFormat="MM/DD/YYYY"
              value={loan_end_date}
              onChange={(e) => set_loan_end_date(e)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.loan_end_date && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid> */}
      </Grid>

      <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        spacing={4}
        sx={{
          py: "20px",
        }}
      >
        <Grid item>
          <Button
            variant="outlined"
            type="reset"
            size="large"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            size="large"
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateProductForm;
