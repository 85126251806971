import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout/Layout";
import Login from "./Pages/Authentication/Login";
import Registration from "./Pages/Authentication/Registration";
import Inventory from "./Pages/Inventory/Inventory";
import LoanRequests from "./Pages/Loans/LoanRequests";
import Loans from "./Pages/Loans/Loans";
import ProductProfile from "./Pages/Products/ProductProfile";
import Products from "./Pages/Products/Products";
import UserLoanDistribution from "./Pages/Users/Components/UserLoan/UserLoanDistribution";
import UserLoanInstalmentDetails from "./Pages/Users/Components/UserLoan/UserLoanInstalmentDetails";
import UserLoanInstalments from "./Pages/Users/Components/UserLoan/UserLoanInstalments";
import UserLoanLogs from "./Pages/Users/Components/UserLoan/UserLoanLogs";
import UserLoanReceipt from "./Pages/Users/Components/UserLoan/UserLoanReceipt";
import UserProfile from "./Pages/Users/UserProfile2";
import Users from "./Pages/Users/Users";
import Transactions from "./Pages/Transactions/Transactions";
import Dashboard from "./Pages/Dashboards/Dashboard";
import UserLoanTransactions from "./Pages/Users/Components/UserLoan/UserLoanTransactions";
import FindUser from "./Pages/Users/FindUser";
import CreateProductForm from "./Pages/Products/Components/CreateProductForm";
import CustomerRegistration from "./Pages/Authentication/CustomerRegistration";
import UserAccountProfile from "./Pages/Users/Components/UserAccounts/UserAccountProfile";
import LoanRequestProfile from "./Pages/Loans/LoanRequestProfile";
import Customers from "./Pages/Users/customers";
import FindCustomer from "./Pages/Users/FindCustomer";
import UserTransactions from "./Pages/Transactions/userTransactions";
import AccountsPage from "./Pages/Authentication/AccountsPage";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import WithdrawRequests from "./Pages/PoolFund/WithdrawRequests";
import UserLoanProfile from "./Pages/Users/Components/UserLoan/UserLoanProfile";
import TransactionsWithdrawReq from "./Pages/Transactions/TransactionsWithdrawReq";
import PoolBasket from "./Pages/PoolFund/PoolBasket";
import RegistrationFees from "./Pages/Reports/RegistrationFees";
import Shares from "./Pages/Reports/Shares";
import SavingsReport from "./Pages/Reports/SavingsReport";
import Refunds from "./Pages/Reports/Refunds";
import CommissionsPaid from "./Pages/Reports/CommisionsPaid";
import LoansGiven from "./Pages/Reports/LoansGiven";
import PrinciplePaid from "./Pages/Reports/PrinciplePaid";
import InstallmentRepaid from "./Pages/Reports/InterestRepaid";
import RolloverInstallment from "./Pages/Reports/RolloverInstallment";
import Recovery from "./Pages/Reports/Recovery";
import AutoLogout from "./Hooks/AutoLogout";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/user-accounts",
    element: <AccountsPage />,
  },
  {
    path: "/customer-registration",
    element: <CustomerRegistration />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/dashboard",
    element: (
      <AutoLogout>
        <Layout />
      </AutoLogout>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "find-user",
        element: <FindUser />,
      },
      {
        path: "customers",
        children: [
          {
            path: "",
            element: <Customers />,
          },
          {
            path: "find-customer",
            element: <FindCustomer />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            path: "",
            element: <Users />,
          },
          {
            path: "create-user",
            element: <Registration />,
          },
          {
            path: "user-profile",
            children: [
              {
                path: "",
                element: <UserProfile />,
              },
              {
                path: "user-deposits",
                element: <UserTransactions />,
              },
              {
                path: "account-profile",
                element: <UserAccountProfile />,
              },
              {
                path: "loan-profile",
                element: <UserLoanProfile />,
              },
              {
                path: "loan-transactions",
                element: <UserLoanTransactions />,
              },
              {
                path: "loan-logs",
                element: <UserLoanLogs />,
              },
              {
                path: "loan-instalments",
                children: [
                  {
                    path: "",
                    element: <UserLoanInstalments />,
                  },
                  {
                    path: "loan-instalment-details",
                    children: [
                      {
                        path: "",
                        element: <UserLoanInstalmentDetails />,
                      },
                      {
                        path: "receipt",
                        element: <UserLoanReceipt />,
                      },
                      {
                        path: "distribution",
                        element: <UserLoanDistribution />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "products",
        children: [
          {
            path: "",
            element: <Products />,
          },
          {
            path: "product-profile",
            element: <ProductProfile />,
          },
          {
            path: "create-product",
            element: <CreateProductForm />,
          },
        ],
      },

      {
        path: "inventory",
        element: <Inventory />,
      },

      {
        path: "loans",
        children: [
          {
            path: "",
            element: <Loans />,
          },
        ],
      },
      {
        path: "loan-requests",
        children: [
          {
            path: "",
            element: <LoanRequests />,
          },
          {
            path: "profile",
            element: <LoanRequestProfile />,
          },
        ],
      },
      {
        path: "pool-fund-withdraw-requests",
        element: <WithdrawRequests />,
      },
      {
        path: "transactions-withdraw-requests",
        element: <TransactionsWithdrawReq />,
      },
      {
        path: "pool-basket",
        element: <PoolBasket />,
      },
      {
        path: "reports",
        children: [
          {
            path: "registration-fees",
            element: <RegistrationFees />,
          },
          {
            path: "shares",
            element: <Shares />,
          },
          {
            path: "savings",
            element: <SavingsReport />,
          },
          {
            path: "refunds",
            element: <Refunds />,
          },
          {
            path: "commission-paid",
            element: <CommissionsPaid />,
          },
          {
            path: "loans-given",
            element: <LoansGiven />,
          },
          {
            path: "principle-repaid",
            element: <PrinciplePaid />,
          },
          {
            path: "installment-repaid",
            element: <InstallmentRepaid />,
          },
          {
            path: "rollover-installment",
            element: <RolloverInstallment />,
          },
          {
            path: "Recovery",
            element: <Recovery />,
          },
        ],
      },
    ],
  },
]);

export default Router;
