import { Grid } from "@mui/material";
import React from "react";
import RegistrationForm from "./components/RegistrationForm";

const Registration = () => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ p: "40px" }}
    >
      <RegistrationForm />
    </Grid>
  );
};

export default Registration;
