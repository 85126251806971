import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";

const UserLoanDistribution = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [check, setCheck] = useState(0);

  const [loading, response, request] = FetchHook();

  useEffect(() => {
    if (check === 0) {
      request({
        url: `distribution?loan_instalment_detail_id='${location.state.loan_instalment_detail.id}'&receipt_id='${location.state.loan_receipt.id}'`,
      });
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 180,
    },
    {
      field: "receipt_id",
      headerName: "Receipt id",
      width: 180,
    },
    {
      field: "instal_detail_id",
      headerName: "Instalment detail id",
      width: 180,
    },
    {
      field: "distribution_type",
      headerName: "Distribution type",
      width: 150,
    },

    {
      field: "received_amount",
      headerName: "Received amount",
      width: 150,
    },
    {
      field: "actual_paid",
      headerName: "Actual paid",
      width: 150,
    },
    {
      field: "balance_amount",
      headerName: "Balance amount",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },

    {
      field: "date_created",
      headerName: "Date created",
      width: 300,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      <Card>
        <CardHeader
          sx={{
            px: "30px",
          }}
          title={
            <Typography variant="h6">
              Loan Distribution (receipt_id: {location.state.loan_receipt.id})
            </Typography>
          }
          action={
            <Box>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Box>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <DataGrid
              autoHeight
              rows={response.data.Distribution}
              columns={columns}
            />
          ) : (
            <Grid>
              <CircularProgress />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserLoanDistribution;
