import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { type } from "@testing-library/user-event/dist/type";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ProductProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = Object.entries(location.state);

  return (
    <Card
      sx={{
        py: "30px",
        px: "30px",
      }}
    >
      <CardHeader
        title={<Typography variant="h5">Product Profile</Typography>}
        action={
          <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
        sx={{
          background: grey[300],
        }}
      />
      <CardContent>
        {data.map((el, i) => (
          <Table key={i}>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    borderBottom: "1px solid #BBC5C9",
                  },
                }}
              >
                <TableCell component="th" scope="row" sx={{ width: "400px" }}>
                  <Typography variant="subtitle1">
                    {el[0].replace(/_/g, " ")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  >
                    {el[1]}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ))}
      </CardContent>
    </Card>
  );
};

export default ProductProfile;
