import styled from "@emotion/styled";
import {
  alpha,
  Avatar,
  Badge,
  Box,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SideBarContent from "./components/SideBarContent";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { getUserDetails, stringAvatar } from "../utils/helpers";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutUser } from "../Redux/authSlice";
import { useDispatch } from "react-redux";

const SideNav = ({ open, isMobile, onToggleNav, drawerWidth }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUserDetails();

  // logout function
  const onLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  const allowedRoutes = SideBarContent.filter((el) => {
    return el.roles.includes("all") || el.roles.includes(user.role);
  });

  const currentLocation = location.pathname;

  const currentPathKey =
    allowedRoutes.find((el) =>
      el.routes?.some((subEl) => currentLocation.indexOf(subEl.path || "") > -1)
    )?.key || "";

  const [expanded, setExpanded] = useState(currentPathKey);

  const expandNav = (key) => {
    if (!expanded.includes(key)) {
      setExpanded([...expanded, key]);
    } else {
      setExpanded(expanded.filter((el) => el !== key));
    }
  };

  const onClickMenu = ({ key, path }) => {
    if (path) {
      if (isMobile) {
        onToggleNav();
      }
      navigate(path);
    } else {
      expandNav(key);
    }
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(1, 1),
    padding: "20px",
    justifyContent: "center",
    height: "fit-content",
  }));

  return (
    <Drawer
      open={open}
      anchor="left"
      variant={isMobile ? "temporary" : "persistent"}
      onClose={onToggleNav}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background.neutral,
          backgroundImage: "none",
        },
        "> *": {
          overflow: "hidden",
          overflowY: "scroll",
        },
      }}
    >
      <DrawerHeader>
        {/* <Badge
          color="secondary"
          overlap="circular"
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        > */}
        <Avatar
          {...stringAvatar(`${user.first_name} ${user.surname}`)}
          sx={{ width: 60, height: 60 }}
        />
        <Stack sx={{ pl: "20px" }}>
          <Typography sx={{ fontWeight: "700" }} variant="h6">
            {user.first_name + " " + user.surname}{" "}
          </Typography>
          <Typography variant="subtitle2">{user.role}</Typography>
        </Stack>
        {/* </Badge> */}
        <IconButton color="inherit" onClick={onLogout} sx={{ ml: "auto" }}>
          {theme.direction === "ltr" ? (
            <Grid
              container
              display={"flex"}
              direction={"column"}
              justifyContent={"center"}
            >
              <LogoutIcon />
            </Grid>
          ) : (
            <LogoutIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <Divider />

      <List component="nav" sx={{ p: 2 }}>
        {allowedRoutes.map((content) => (
          <Box key={content.key}>
            <ListItemButton
              onClick={() => onClickMenu(content)}
              selected={currentLocation === content.path}
              sx={[
                { mb: 1, borderRadius: ".5rem" },
                {
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                  },
                  "&.Mui-selected": {
                    color: theme.palette.secondary.main,
                    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                  },
                },
              ]}
            >
              <ListItemIcon sx={[{ minWidth: 50, color: "inherit" }]}>
                {content.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: 700 }} variant="subtitle1">
                    {content.label}
                  </Typography>
                }
              />
              {content.routes && expanded.includes(content.key) && (
                <ExpandLess />
              )}
              {content.routes && !expanded.includes(content.key) && (
                <ExpandMore />
              )}
            </ListItemButton>
            {content.routes && (
              <Collapse in={expanded.includes(content.key)}>
                {content.routes.map((subRoute) => {
                  return (
                    <List
                      key={subRoute.path}
                      component="div"
                      onClick={() => onClickMenu(subRoute)}
                      disablePadding
                      dense
                    >
                      {subRoute.roles.find(
                        (allowedSubRoute) =>
                          allowedSubRoute === "all" ||
                          allowedSubRoute === user.role
                      ) && (
                        <ListItemButton
                          selected={currentLocation === subRoute.path}
                          sx={[
                            {
                              mb: 1,
                              pl: 10,
                              borderRadius: ".5rem",
                              color: theme.palette.text.drawer,
                            },
                            {
                              "&:hover": {
                                backgroundColor: alpha(
                                  theme.palette.secondary.main,
                                  0.2
                                ),
                              },

                              "&.Mui-selected": {
                                backgroundColor: alpha(
                                  theme.palette.secondary.main,
                                  0.3
                                ),
                              },
                            },
                          ]}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                }}
                                variant="subtitle2"
                              >
                                {subRoute.label}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      )}
                    </List>
                  );
                })}
              </Collapse>
            )}
          </Box>
        ))}
      </List>
    </Drawer>
  );
};

export default SideNav;
