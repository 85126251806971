import { Theme } from "@mui/material";
import DefaultTheme from "./defaultTheme";

const themeMap = {
  default: DefaultTheme,
};

export default function getThemeByName(theme) {
  return themeMap[theme];
}
