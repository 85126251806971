import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

const UserSavingsForm = ({ save, handleModal2, saveLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <form onSubmit={handleSubmit(save)} style={{ width: "auto" }}>
      <FormControl
        fullWidth
        error={!!errors.amount}
        sx={{
          mt: "5px",
        }}
      >
        <InputLabel htmlFor="amount">Amount</InputLabel>
        <OutlinedInput
          id="amount"
          type="number"
          {...register("amount", { required: true })}
          label="Username"
        />
        {errors.amount && (
          <FormHelperText>Please enter an amount!</FormHelperText>
        )}
      </FormControl>

      <Box
        sx={{
          pt: "40px",
          display: "flex",
          direction: "row",
          justifyContent: "space-between",
        }}
      >
        <Button type="reset" variant="outlined" onClick={handleModal2}>
          Cancel
        </Button>
        <LoadingButton variant="contained" type="submit" loading={saveLoading}>
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
};

export default UserSavingsForm;
