import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import { inventorySlice } from "./inventorySlice";
import loansSlice from "./loansSlice";
import productSlice from "./productSlice";
import userSlice from "./userSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    usersData: userSlice,
    products: productSlice,
    inventory: inventorySlice,
    loans: loansSlice,
  },
});

export default store;
