import { Box } from "@mui/material";
import React, { useState } from "react";
import { getUserDetails } from "../../utils/helpers";
import AdminDashboard from "./AdminDashboard";
import UserProfile from "../Users/UserProfile2";

const Dashboard = () => {
  const loggedInUser = getUserDetails();

  return (
    <Box>
      {loggedInUser?.role === "admin" ? <AdminDashboard /> : <UserProfile />}
    </Box>
  );
};

export default Dashboard;
