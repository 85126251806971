import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import CategoryIcon from "@mui/icons-material/Category";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import WorkIcon from "@mui/icons-material/Work";
import AssessmentIcon from "@mui/icons-material/Assessment";

const SideBarContent = [
  {
    key: "1",
    path: "/dashboard",
    label: "Dashboard",
    icon: <DashboardIcon />,
    roles: ["all"],
  },
  {
    key: "2",
    path: "/dashboard/products",
    label: "Products",
    icon: <CategoryIcon />,
    roles: ["admin", "finance"],
  },
  {
    key: "12",
    label: "Customers",
    icon: <PeopleOutlineIcon />,
    routes: [
      {
        key: "13",
        label: "Find Customers",
        path: "/dashboard/customers/find-customer",
        roles: ["admin", "finance", "ccr"],
      },
      {
        key: "11",
        label: "Customers",
        path: "/dashboard/customers",
        roles: ["admin", "finance", "ccr"],
      },
    ],
    roles: ["admin"],
  },
  {
    key: "7",
    label: "Users",
    icon: <GroupTwoToneIcon />,
    routes: [
      // {
      //   key: "13",
      //   label: "Find Customers",
      //   path: "/dashboard/customers/find-customer",
      //   roles: ["admin", "finance", "ccr"],
      // },
      // {
      //   key: "11",
      //   label: "Customers",
      //   path: "/dashboard/customers",
      //   roles: ["admin", "finance", "ccr"],
      // },
      {
        key: "8",
        label: "All Users",
        path: "/dashboard/users",
        roles: ["admin", "finance"],
      },
    ],
    roles: ["admin"],
  },
  // {
  //   key: "3",
  //   path: "/dashboard/inventory",
  //   label: "Inventory",
  //   icon: <InventoryIcon />,
  //   roles: ["supplier", "admin", "finance"],
  // },

  {
    key: "4",
    label: "Loans",
    icon: <VerticalSplitIcon />,
    roles: ["admin", "finance"],
    routes: [
      {
        key: "5",
        label: "Loans",
        path: "/dashboard/loans",
        roles: ["admin", "finance"],
      },
      {
        key: "6",
        label: "Loan requests",
        path: "/dashboard/loan-requests",
        roles: ["admin", "finance"],
      },
    ],
  },

  {
    key: "9",
    label: "Transactions",
    icon: <ReceiptIcon />,
    path: "/dashboard/transactions",
    roles: ["admin", "finance"],
  },
  {
    key: "16",
    label: "Pool Basket",
    icon: <WorkIcon />,
    path: "/dashboard/pool-basket",
    roles: ["admin"],
  },
  {
    key: "14",
    label: "Withdraw requests",
    icon: <MoveDownIcon />,
    roles: ["admin", "finance", "ccr"],
    routes: [
      {
        key: "14",
        label: "Pool withdraw",
        path: "/dashboard/pool-fund-withdraw-requests",
        roles: ["admin", "finance", "ccr"],
      },
      {
        key: "15",
        label: "Transactions Withdraw",
        path: "/dashboard/transactions-withdraw-requests",
        roles: ["admin", "finance", "ccr"],
      },
    ],
  },
  {
    key: "17",
    label: "Reports",
    icon: <AssessmentIcon />,
    roles: ["admin"],
    routes: [
      {
        key: "20",
        label: "Registration fees",
        roles: ["admin"],
        path: "/dashboard/reports/registration-fees",
      },
      {
        key: "21",
        label: "Shares",
        roles: ["admin"],
        path: "/dashboard/reports/shares",
      },
      {
        key: "22",
        label: "Savings",
        roles: ["admin"],
        path: "/dashboard/reports/savings",
      },
      {
        key: "23",
        label: "Refunds",
        roles: ["admin"],
        path: "/dashboard/reports/refunds",
      },
      // {
      //   key: "24",
      //   label: "Commisions paid",
      //   roles: ["admin"],
      //   path: "/dashboard/reports/commission-paid",
      // },
      {
        key: "25",
        label: "Loans given",
        roles: ["admin"],
        path: "/dashboard/reports/loans-given",
      },
      {
        key: "26",
        label: "Principle repaid",
        roles: ["admin"],
        path: "/dashboard/reports/principle-repaid",
      },
      {
        key: "27",
        label: "Interest repaid",
        roles: ["admin"],
        path: "/dashboard/reports/installment-repaid",
      },
      {
        key: "28",
        label: "Rollover installment",
        roles: ["admin"],
        path: "/dashboard/reports/rollover-installment",
      },
      // {
      //   key: "29",
      //   label: "Recovery",
      //   roles: ["admin"],
      //   path: "/dashboard/reports/recovery",
      // },
    ],
  },
];

export default SideBarContent;
