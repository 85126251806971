import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import LoanRepaymentTabs from "./LoanRepaymentTabs";
import { useState } from "react";
import moment from "moment";
import { stringAvatar } from "../../../../utils/helpers";
import { green } from "@mui/material/colors";

const UserLoanDetails = ({ data, handleSnackbar, setSeverity }) => {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <Box>
      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Loan early repayment</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { lg: "400px" },
          }}
        >
          <LoanRepaymentTabs
            handleModal={handleModal}
            loan={data}
            handleSnackbar={handleSnackbar}
            setSeverity={setSeverity}
          />
        </DialogContent>
      </Dialog>

      <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        sx={{ p: { sm: "10px" } }}
      >
        <Button variant="contained" onClick={() => handleModal()}>
          Repay loan
        </Button>
      </Grid>
      {/* <Grid
        container
        sx={{
          my: "30px",
        }}
      >
        <Grid container display={"flex"} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="subtitle1" sx={{ fontSize: "17px" }}>
              Balance:{" "}
              <span
                style={{
                  color: green[600],
                }}
              >
                KSH {data?.balance_payable.toLocaleString()}
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ fontSize: "17px" }}>
              Balance:{" "}
              <span
                style={{
                  color: green[600],
                }}
              >
                KSH {data?.balance_payable.toLocaleString()}
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ fontSize: "17px" }}>
              Balance:{" "}
              <span
                style={{
                  color: green[600],
                }}
              >
                KSH {data?.balance_payable.toLocaleString()}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid container display={"flex"}>
        <Grid item>
          <Card>
            <CardHeader
              title={<Typography variant="h6">Loan Details </Typography>}
            />

            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1" sx={{ fontSize: "17px" }}>
                        {" "}
                        Loan id:{" "}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {data.id}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1">Loan name: </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {data.loan_name}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1">
                        Amount applied{" "}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {data.loan_principal.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1">
                        Amount disbursed{" "}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.amount_disburse.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: "200px" }}
                    >
                      <Typography variant="subtitle1">
                        Balance payable
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.balance_payable.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: "200px" }}
                    >
                      <Typography variant="subtitle1">Amount paid</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.amount_paid.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle1">Loan Status</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        {data.status}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <CardHeader
              title={<Typography variant="h6">Loan balances</Typography>}
            />
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                      <Typography variant="subtitle1">
                        Application fee payable
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.application_fee_payable.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                      <Typography variant="subtitle1">
                        Principle payable
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.principal_payable.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: "200px" }}
                    >
                      <Typography variant="subtitle1">
                        Instalment payable
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.instalment_payable.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: "200px" }}
                    >
                      <Typography variant="subtitle1">
                        Interest payable
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.interest_payable.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: "200px" }}
                    >
                      <Typography variant="subtitle1">
                        Balance payable
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      >
                        KSH {data.balance_payable.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {data.status !== "instalment" && (
                    <>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "200px" }}
                        >
                          <Typography variant="subtitle1">
                            Rollover payable
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            KSH {data.rollover_payable.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "200px" }}
                        >
                          <Typography variant="subtitle1">
                            Debt collection payable
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            KSH {data.debt_collection_payable.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "200px" }}
                        >
                          <Typography variant="subtitle1">
                            Charges payable
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            KSH {data.charges_payable.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle1">
                            Insurance payable
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            KSH {data.insurance_payable}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          {data.status !== "instalment" && (
            <Card>
              <CardHeader
                title={<Typography variant="h6">Roll over details</Typography>}
              />

              <CardContent>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "200px" }}
                      >
                        <Typography variant="subtitle1">
                          loan default amount
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                          KSH {data.balance_payable.toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "200px" }}
                      >
                        <Typography variant="subtitle1">
                          Rollover amount
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                          KSH {data.rollover_payable.toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "200px" }}
                      >
                        <Typography variant="subtitle1">
                          Date defaulted
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                          {moment(data.rollover_start_date).format(
                            "DD MMM YYYY hh:mm:ss a"
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "200px" }}
                      >
                        <Typography variant="subtitle1">Due date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                          {moment(data.rollover_next_date).format(
                            "DD MMM YYYY hh:mm:ss a"
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserLoanDetails;
