import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FetchHook from "../../Api/FetchHooks";
import { grey } from "@mui/material/colors";
import CreateProductForm from "./Components/CreateProductForm";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../utils/helpers";
import moment from "moment";
import TableComponent from "../../Components/TableComponent";

const Products = () => {
  const navigate = useNavigate();
  const LoggedInUser = getUserDetails();

  const [status, setStatus] = useState("active");
  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();

  const [loading, response, request] = FetchHook();

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const fetchProducts = () => {
    request({ url: `product?status="${status}"` });
  };

  useEffect(() => {
    if (check === 0) {
      fetchProducts();
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "id",
      headerName: "Product Id",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "product_onwer_id",
      headerName: "Product owner Id",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "loan_name",
      headerName: "Loan name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "loan_start_date",
      headerName: "Loan Start date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "profile",
      headerName: "Profile",
      renderCell: (row) => (
        <Button
          variant="outlined"
          style={{ marginLeft: 16 }}
          onClick={() => {
            navigate("/dashboard/products/product-profile", { state: row.row });
            // dispatch(userData(row.row));
          }}
        >
          Profile
        </Button>
      ),
      flex: 1,
      minWidth: 200,
    },
  ];

  return (
    <Grid sx={{ padding: "20px" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbar}
      >
        <Alert
          onClose={handleSnackbar}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* dialog box to add products */}
      <Dialog open={open} onClose={handleModal}>
        <DialogTitle sx={{ background: grey[200] }}>
          Add Product Form
        </DialogTitle>
        <DialogContent>
          <CreateProductForm
            handleModal={handleModal}
            handleSnackbar={handleSnackbar}
            setSeverity={setSeverity}
            setMessage={setMessage}
            setCheck={setCheck}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          title={<Typography variant="h5">Products</Typography>}
          action={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              // onClick={() => handleModal()}
              onClick={() => navigate("/dashboard/products/create-product")}
              disabled={LoggedInUser?.role !== "admin"}
              sx={{
                fontWeight: "bold",
              }}
            >
              Add New
            </Button>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <TableComponent
              columns={columns}
              data={response.data.Loan}
              loading={loading}
            />
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Products;
