import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment, { now } from "moment";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useLocation, useNavigate } from "react-router-dom";
import PostHooks from "../../../Api/PostHooks";
import AlertComponent from "../../../Components/AlertComponent";

const AddKinForm = ({ handleModal, user, setCheck }) => {
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const now = new Date();

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState({});
  const msisdn = watch("msisdn");

  const [loading, response, request] = PostHooks();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = async (e) => {
    let formData = {
      first_name: e.first_name,
      surname: "",
      other_names: e.other_names,
      company_name: "",
      state: "",
      residential_address: "",
      postal_address: "",
      id_number: e.identification_number,
      passport_number: "",
      county: "",
      subcounty: "",
      ward: "",
      village: "",
      serial_no: "",
      msisdn: msisdn.replace("+", ""),
      document_registration_date: moment(now).format("YYYY-MM-DD"),
      document_expiry_date: moment(now).format("YYYY-MM-DD"),
      name: e.first_name,
      document_number: e.identification_number,
      description: "Customer registration",
      registry_file_number: "",
      role: "next_kin",
      channel_name: "mobile_number",
      member_entity_id: user?.id,
      username: e.identification_number,
      allocation: e.allocation,
    };

    let props = {
      url: "/user",
      body: formData,
    };

    request(props);
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({ severity: "success", message: "Registration successful" });
      setOpen(true);
      handleModal();
      setCheck(1);
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
      setOpen(true);
    }
  }, [response]);

  console.log("msisdn", msisdn);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form">
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Grid
        container
        rowSpacing={3}
        sx={{ my: "10px" }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.first_name}>
            <InputLabel htmlFor="first_name">First name</InputLabel>
            <OutlinedInput
              id="first_name"
              {...register("first_name", { required: true })}
              label="First name"
              xs={12}
              variant="filled"
            />
            {errors.first_name && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.other_names}>
            <InputLabel htmlFor="other_names">Other name</InputLabel>
            <OutlinedInput
              variant="filled"
              id="other_names"
              {...register("other_names", { required: true })}
              label="Other name"
              xs={12}
            />
            {errors.other_names && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.identification_number}>
            <InputLabel>Id number</InputLabel>
            <OutlinedInput
              variant="filled"
              id="identification_number"
              {...register("identification_number", {
                required: true,
                maxLength: 10,
                minLength: 7,
              })}
              label="Id number"
              xs={12}
              type="number"
            />
            {errors.identification_number && (
              <FormHelperText>Enter your id number</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* 
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.username}>
            <InputLabel htmlFor="username">Username</InputLabel>
            <OutlinedInput
              variant="filled"
              id="username"
              {...register("username", { required: true })}
              label="Username"
              xs={12}
            />
            {errors.username && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid> */}

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <Controller
            name="msisdn"
            control={control}
            rules={{
              validate: (value) => matchIsValidTel(value),
            }}
            defaultValue={"+254"}
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                fullWidth
                defaultCountry="KE"
                onlyCountries={["KE"]}
                helperText={fieldState.invalid ? "Invalid mobile number!" : ""}
                error={fieldState.error}
                value={field.value || ""}
                disableFormatting
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.allocation}>
            <InputLabel htmlFor="allocation">Allocation</InputLabel>
            <OutlinedInput
              variant="filled"
              id="allocation"
              {...register("allocation")}
              label="Allocation"
              type="number"
              defaultValue={100}
            />
            {errors.allocation && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      <Box
        sx={{
          pt: "20px",
          gap: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button size="large" type="reset" onClick={() => handleModal()}>
          cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ ml: "10px" }}
          size="large"
          type="submit"
          loading={loading}
        >
          Add
        </LoadingButton>
      </Box>
    </form>
  );
};

export default AddKinForm;
