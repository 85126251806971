import { createSlice } from "@reduxjs/toolkit";
import {
  getUserDetails,
  logout,
  setRefreshToken,
  setToken,
  setUserDetails,
} from "../utils/helpers";

const initialState = {
  user: getUserDetails() || undefined,
  userCreds: { username: undefined, password: undefined },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      setUserDetails(action.payload.PayLoad);
      setToken(action.payload.Token);
      setRefreshToken(action.payload.RefreshToken);
      state.user = action.payload.PayLoad;
    },

    logoutUser: (state) => {
      logout();
      state.user = undefined;
    },

    userLogins: (state, action) => {
      state.userCreds = action.payload;
    },
  },
});

export const { login, logoutUser, userLogins } = authSlice.actions;

export default authSlice.reducer;
