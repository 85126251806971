import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";
import PageLoader from "../../../../Components/PageLoader";
import { getUserDetails } from "../../../../utils/helpers";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import PostHooks from "../../../../Api/PostHooks";
import WarningIcon from "@mui/icons-material/Warning";
import AlertComponent from "../../../../Components/AlertComponent";

const UserLoanInstalment = ({ user, loan }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const loggedInUser = getUserDetails();

  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [instalment, setInstalment] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const [loading, response, request] = FetchHook();
  const [reverseLoading, reverseResponse, reverseRequest] = PostHooks();

  useEffect(() => {
    if (check === 0) {
      request({
        url: `instalment?loan_id='${loan.id}'`,
      });
      setCheck(1);
    }
  }, [check]);

  const reverseInstalment = (e) => {
    let formData = {
      entity_id: user?.id,
      loan_id: instalment?.actual_loan_id,
      amount: instalment?.actual_paid_amount,
      instalment_id: instalment?.id,
    };

    reverseRequest({
      url: "/reverse_loan_instalment",
      body: formData,
    });
  };

  useEffect(() => {
    if (reverseResponse && reverseResponse.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Reverse successful!",
      });
      handleSnackbar();
      handleModal();
      setCheck(0);
    } else if (reverseResponse && reverseResponse.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: reverseResponse.data.StatusMessage,
      });
      handleSnackbar();
    } else if (reverseResponse && reverseResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: reverseResponse.response.data.message,
      });
      handleSnackbar();
    }
  }, [reverseResponse]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 200,
    },
    {
      field: "actual_loan_id",
      headerName: "Actual Loan Id",
      width: 200,
    },
    {
      field: "instal_type",
      headerName: "Instalment type",
      width: 150,
    },
    {
      field: "expected_collection_amount",
      headerName: "Expected collection amount",
      width: 200,
    },
    {
      field: "actual_paid_amount",
      headerName: "Actual paid amount",
      width: 150,
    },
    {
      field: "balance_amount_payable",
      headerName: "Balance amount payable",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "date_expected",
      headerName: "Date expected",
      width: 300,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    // {
    //   field: "date_queued",
    //   headerName: "Date queued",
    //   width: 300,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    // {
    //   field: "date_created",
    //   headerName: "Date created",
    //   width: 300,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    {
      field: "date_last_payment",
      headerName: "Last payment date",
      width: 300,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "loan_instalment_details",
      headerName: "",
      width: 200,
      renderCell: (row) => (
        <>
          {loggedInUser?.role === "admin" && (
            <>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    "/dashboard/users/user-profile/loan-instalments/loan-instalment-details",
                    {
                      state: row.row,
                    }
                  )
                }
              >
                Details
              </Button>
            </>
          )}

          <Button
            variant="outlined"
            onClick={() => {
              handleModal();
              setInstalment(row.row);
            }}
          >
            Reverse
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle id="form-dialog-title">Reverse Instalment</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <form onSubmit={handleSubmit(reverseInstalment)}>
            <Grid
              display={"flex"}
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <WarningIcon color="warning" sx={{ fontSize: 100 }} />
              <Typography align="center">
                You are about to reverse an instalment of <br /> amount:{" "}
                {instalment?.actual_paid_amount}
              </Typography>
            </Grid>

            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ pt: "40px" }}
            >
              <Button variant="outlined" onClick={handleModal} type="button">
                Cancel
              </Button>
              <LoadingButton
                loading={reverseLoading}
                variant="contained"
                type="submit"
              >
                Reverse
              </LoadingButton>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          sx={{
            px: "30px",
          }}
          title={
            <Typography variant="h6">
              Loan instalments(Loan_id: {loan?.id})
            </Typography>
          }
          action={
            <Box>
              {/* <FormControl
                sx={{
                  width: "20vw",
                }}
              >
                <InputLabel id="status">Status</InputLabel>

                <Select
                  labelId="loan_status"
                  id="loan_status"
                  value={status}
                  label="status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCheck(0);
                  }}
                  size="small"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="domant">Domant</MenuItem>
                  <MenuItem value="suspended">Suspended</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                  <MenuItem value="incomplete">Incomplete</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                </Select>
              </FormControl> */}

              {/* <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                Back
              </Button> */}
            </Box>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <DataGrid
              autoHeight
              rows={response.data.Instalment}
              columns={columns}
              loading={loading}
            />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserLoanInstalment;
