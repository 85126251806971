import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import PostHooks from "../../../../Api/PostHooks";
import { useState } from "react";
import { MuiTelInput } from "mui-tel-input";

const GuarantorNominationForm = ({
  data,
  handleModal,
  setSeverity,
  handleSnackbar,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, response, request] = PostHooks();

  const [type, setType] = useState();
  const [msisdn, setMsisdn] = useState();

  const onSubmit = (e) => {
    if (msisdn === undefined || (msisdn && msisdn.length < 13)) {
      setSeverity({
        severity: "error",
        message: "Please enter a valid phone number.",
      });
      handleSnackbar();
    } else {
      let formData = {
        amount: data.guarantor_saving,
        msisdn: msisdn.replace("+", ""),
        // id_number: e.id_number,
        loan_req_id: data.id,
        asset_name: e.asset_name,
        description: e.description,
        url: "n/a",
        worthyness: e.worthyness,
        type: type,
        guarantorship_entity_id: "",
      };

      request({
        url: "/nominate_guarantor",
        body: formData,
      });
    }
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Guarantor nominated successfully",
      });
      handleSnackbar();
      handleModal();
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
      handleSnackbar();
    } else if (response && response.status !== 201) {
      setSeverity({
        severity: "error",
        message: response.response.statusText,
      });
      handleSnackbar();
    }
  }, [response]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          fullWidth
          sx={{
            mt: "15px",
          }}
        >
          <InputLabel id="type">Guarantor type</InputLabel>
          <Select
            labelId="type"
            id="type"
            defaultValue=""
            value={type}
            label="Guarantor type"
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="other_members">Other members</MenuItem>
            <MenuItem value="assets">Assets</MenuItem>
            <MenuItem value="pool_fund">Pool fund</MenuItem>
          </Select>
        </FormControl>

        {type === "other_members" && (
          <FormControl
            fullWidth
            error={!!errors.id_number}
            sx={{
              mt: "15px",
            }}
          >
            <MuiTelInput
              sx={{
                border: "1px solid black",
              }}
              defaultCountry="KE"
              disableFormatting
              value={msisdn}
              onChange={(e) => setMsisdn(e)}
            />
          </FormControl>
        )}

        {/* 
        <FormControl
          fullWidth
          error={!!errors.id_number}
          sx={{
            mt: "15px",
          }}
        >
          <InputLabel htmlFor="id_number">Id Number</InputLabel>
          <OutlinedInput
            id="id_number"
            type="number"
            {...register("id_number", { required: true })}
            label="Id Number"
            variant="filled"
          />
          {errors.id_number && <FormHelperText>Required</FormHelperText>}
        </FormControl> */}

        {/* <FormControl
          fullWidth
          error={!!errors.amount}
          sx={{
            my: "15px",
          }}
        >
          <InputLabel htmlFor="amount">Amount</InputLabel>
          <OutlinedInput
            id="amount"
            type="number"
            {...register("amount", { required: true })}
            label="Id Number"
            variant="filled"
          />
          {errors.amount && <FormHelperText>Required</FormHelperText>}
        </FormControl> */}

        {type === "assets" && (
          <>
            <FormControl
              fullWidth
              error={!!errors.asset_name}
              sx={{
                mt: "15px",
              }}
            >
              <InputLabel htmlFor="asset_name">Asset name</InputLabel>
              <OutlinedInput
                id="asset_name"
                type="text"
                {...register("asset_name")}
                label="Asset name"
                variant="filled"
                defaultValue=""
              />
              {errors.asset_name && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              fullWidth
              error={!!errors.description}
              sx={{
                mt: "15px",
              }}
            >
              <InputLabel htmlFor="description">Description</InputLabel>
              <OutlinedInput
                id="description"
                type="text"
                {...register("description")}
                label="Description"
                variant="filled"
                defaultValue=""
              />
              {errors.description && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              fullWidth
              error={!!errors.worthyness}
              sx={{
                mt: "15px",
              }}
            >
              <InputLabel htmlFor="worthyness">Asset worth</InputLabel>
              <OutlinedInput
                id="worthyness"
                type="number"
                {...register("worthyness")}
                label="Asset worth"
                variant="filled"
                defaultValue=""
              />
              {errors.worthyness && <FormHelperText>Required</FormHelperText>}
            </FormControl>
          </>
        )}

        <Box
          sx={{
            mt: "40px",
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" type="reset" onClick={handleModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default GuarantorNominationForm;
