import {
  Badge,
  CircularProgress,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import UserAvatar from "./UseAvatar";
import { logoutUser } from "../../Redux/authSlice";
import { useNavigate } from "react-router-dom";

const Avatar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasOpened, setOpened] = useState(false);
  const [isLoading] = useState(false);

  const avatarMenuOpen = Boolean(anchorEl);

  const onLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpened(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack onClick={handleClick} sx={{ ml: "auto", cursor: "pointer" }}>
        {!hasOpened && (
          <Badge color="secondary" overlap="circular">
            <UserAvatar size={40} />
          </Badge>
        )}
        {hasOpened && <UserAvatar size={40} />}
        {isLoading && (
          <CircularProgress sx={{ position: "absolute" }} color="secondary" />
        )}
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={avatarMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Divider />
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutTwoToneIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Avatar;
