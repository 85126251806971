import React from "react";
import PostHooks from "../../Api/PostHooks";
import { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import FetchHook from "../../Api/FetchHooks";

const FundPoolForm = ({
  handleModal3,
  handleSnackbar,
  setSeverity,
  setFetch,
  fetchUser,
  user,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [poolBasketOpt, setPoolBasketOpt] = useState();
  const [poolBasket, setPoolBasket] = useState();
  const [check, setCheck] = useState(0);

  console.log("poolBasket", poolBasket);

  const [fundPoolLoading, fundPoolResponse, fundPoolRequest] = PostHooks();
  const [loading, response, request] = FetchHook();

  useEffect(() => {
    if (check === 0) {
      request({
        url: `/pool_basket?status=active`,
      });
      setCheck(1);
    }

    if (response && response.data.StatusCode === 0) {
      setPoolBasketOpt(response.data.Data);
    }
  }, [check, response]);

  const fundPool = (e) => {
    let formData = {
      entity_id: user.id,
      amount: e.amount,
      pool_fund_basket_id: poolBasket,
    };

    fundPoolRequest({
      url: "/fund_pool_fund",
      body: formData,
    });
  };

  useEffect(() => {
    if (
      fundPoolResponse &&
      fundPoolResponse.status === 200 &&
      fundPoolResponse.data.StatusCode === 0
    ) {
      handleModal3();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Fund successful!",
      });
      fetchUser();
      setFetch(0);
    } else if (
      fundPoolResponse &&
      fundPoolResponse.status === 200 &&
      fundPoolResponse.data.StatusCode !== 0
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: fundPoolResponse.data.StatusMessage,
      });
    } else if (fundPoolResponse && fundPoolResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: fundPoolResponse.response.data.StatusMessage,
      });
    }
  }, [fundPoolResponse]);

  return (
    <form onSubmit={handleSubmit(fundPool)} style={{ width: "auto" }}>
      <FormControl
        fullWidth
        sx={{
          mt: "20px",
        }}
      >
        <InputLabel id="pool_account">Pool basket</InputLabel>
        <Select
          labelId="pool_account"
          id="pool_account"
          defaultValue=""
          value={poolBasket}
          label="Pool basket"
          onChange={(e) => {
            setPoolBasket(e.target.value);
          }}
        >
          {poolBasketOpt &&
            poolBasketOpt.map((el, i) => (
              <MenuItem key={i} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        error={!!errors.amount}
        sx={{
          mt: "20px",
        }}
      >
        <InputLabel htmlFor="amount">Amount</InputLabel>
        <OutlinedInput
          id="amount"
          type="number"
          {...register("amount", { required: true })}
          label="Username"
        />
        {errors.amount && (
          <FormHelperText>Please enter an amount!</FormHelperText>
        )}
      </FormControl>

      <Box
        sx={{
          pt: "40px",
          display: "flex",
          direction: "row",
          justifyContent: "space-between",
        }}
      >
        <Button type="reset" variant="outlined" onClick={handleModal3}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={fundPoolLoading}
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
};

export default FundPoolForm;
