import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment, { now } from "moment";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import PostHooks from "../../../Api/PostHooks";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../Components/AlertComponent";

const CustomerRegistrationForm = () => {
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState({});
  const [message, setMessage] = useState();
  const msisdn = watch("msisdn");
  const [document_registration_date, set_document_registration_date] = useState(
    new Date()
  );
  const [document_expiry_date, set_document_expiry_date] = useState(new Date());
  const [documentType, setDocumenType] = useState();

  const [userRole, setUserRole] = useState();

  const [loading, response, request] = PostHooks();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = async (e) => {
    if (msisdn && msisdn.length < 13) {
      setSeverity({
        severity: "error",
        message: "Input a valid phone number!",
      });
      handleSnackbar();
    } else {
      let formData = {
        first_name: e.first_name,
        surname: "",
        other_names: e.other_names,
        company_name: "",
        state: "",
        residential_address: "",
        postal_address: "",
        id_number: e.identification_number,
        passport_number: "",
        county: e.county,
        subcounty: "",
        ward: "",
        village: "",
        serial_no: "",
        msisdn: msisdn.replace("+", ""),
        document_registration_date: moment(now).format("YYYY-MM-DD"),
        document_expiry_date: moment(now).format("YYYY-MM-DD"),
        name: "National ID",
        document_number: e.identification_number,
        description: "Customer registration",
        registry_file_number: "",
        role: "customer",
        channel_name: "mobile_number",
        username: e.identification_number,
      };

      let props = {
        url: "/user",
        body: formData,
      };

      request(props);
    }
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({ severity: "success", message: "Registration successful" });
      setOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
        // message: "Something went wrong, try again later!",
      });
      setOpen(true);
    } else if (response && response.status !== 200) {
      setSeverity({
        severity: "error",
        message: response.response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [response]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form">
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.first_name}>
            <InputLabel htmlFor="first_name">First name</InputLabel>
            <OutlinedInput
              id="first_name"
              {...register("first_name", { required: true })}
              label="First name"
              xs={12}
              variant="filled"
            />
            {errors.first_name && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.other_names}>
            <InputLabel htmlFor="other_names">Other name</InputLabel>
            <OutlinedInput
              variant="filled"
              id="other_names"
              {...register("other_names", { required: true })}
              label="Other name"
              xs={12}
            />
            {errors.other_names && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.identification_number}>
            <InputLabel>Id number</InputLabel>
            <OutlinedInput
              variant="filled"
              id="identification_number"
              {...register("identification_number", {
                required: true,
                maxLength: 10,
                minLength: 7,
              })}
              label="Id number"
              xs={12}
              type="number"
            />
            {errors.identification_number && (
              <FormHelperText>Enter a valid id number.</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.county}>
            <InputLabel>County</InputLabel>
            <OutlinedInput
              variant="filled"
              id="county"
              {...register("county", { required: true })}
              label="County"
              xs={12}
            />
            {errors.county && (
              <FormHelperText>Enter your county!</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* 
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth error={!!errors.username}>
            <InputLabel htmlFor="username">Username</InputLabel>
            <OutlinedInput
              variant="filled"
              id="username"
              {...register("username", { required: true })}
              label="Username"
              xs={12}
            />
            {errors.username && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid> */}

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <Controller
            name="msisdn"
            control={control}
            rules={{
              validate: (value) => matchIsValidTel(value),
            }}
            defaultValue={"+254"}
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                fullWidth
                defaultCountry="KE"
                onlyCountries={["KE"]}
                helperText={fieldState.invalid ? "Invalid mobile number!" : ""}
                error={fieldState.error}
                value={field.value || ""}
                disableFormatting
              />
            )}
          />
        </Grid>

        {/* <Grid
          item
          xs={12}
          md={6}
          lg={6}
         
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="user_role">User role</InputLabel>
            <Select
              labelId="user_role"
              id="user_role"
              defaultValue=""
              value={userRole}
              label="Identification type"
              onChange={(e) => setUserRole(e.target.value)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="finance">Finance</MenuItem>
              <MenuItem value="shareholder">Shareholder</MenuItem>
              <MenuItem value="dealer">Dealer</MenuItem>
              <MenuItem value="supplier">Supplier</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        {/* <Grid
          item
          xs={12}
          md={6}
          lg={6}
         
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="document_type">Identification type</InputLabel>
            <Select
              labelId="document_type"
              id="document_type"
              defaultValue=""
              value={documentType}
              label="Identification type"
              onChange={(e) => setDocumenType(e.target.value)}
            >
              <MenuItem value="National ID">National Id</MenuItem>
              <MenuItem value="Passpot ID">Passport</MenuItem>
              <MenuItem value="Company Certificate">
                Company certificate
              </MenuItem>
              <MenuItem value="KRA PIN">KRA Pin</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid
          item
          xs={12}
          sx={{
            py: "10px",
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            type="reset"
            onClick={() => navigate(-1)}
            fullWidth
          >
            Login
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ ml: "10px" }}
            size="large"
            type="submit"
            loading={loading}
            fullWidth
          >
            register
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomerRegistrationForm;
