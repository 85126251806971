import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
  Backdrop,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";
import PostHookAccount from "../../../../Api/PostHookAccount";
import { getUserDetails, stringAvatar } from "../../../../utils/helpers";
import moment from "moment";
import AlertComponent from "../../../../Components/AlertComponent";
import PostHooks from "../../../../Api/PostHooks";
import TableComponent from "../../../../Components/TableComponent";

const UserAccountProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selector = useSelector((state) => state);
  const user = useSelector((state) => state.usersData.userData);
  const LoggedInUser = getUserDetails();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [bankAccNumber, setBankAccNumber] = useState();
  const [amount, setAmount] = useState(0);
  const [props, setProps] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [message, setMessage] = useState();
  const [checkAcc, setCheckAcc] = useState(0);
  const [checkTrans, setCheckTrans] = useState(0);
  const [check, setCheck] = useState(0);
  const [sms_supplier_acc_options, set_sms_supplier_acc_options] = useState([]);
  const [airtime_supplier_acc_options, set_airtime_supplier_acc_options] =
    useState([]);
  const [acc, setAcc] = useState();
  const [airtime_supplier_acc, set_airtime_supplier_acc] = useState();

  const [loading, injectionResponse, injectionRequest] = PostHookAccount();
  const [
    airtimeInjectionLoading,
    airtimeInjectionResponse,
    airtimeInjectionReq,
  ] = PostHooks();
  const [transferLoading, transferResponse, transferRequest] =
    PostHookAccount();
  const [accLoading, accResponse, accRequest] = FetchHook();
  const [transLoading, transResponse, fetchTrans] = FetchHook();
  const [smsAccLoading, smsAccResponse, smsAccRequest] = FetchHook();
  const [airtimeAccLoading, airtimeAccResponse, airtimeAccRequest] =
    FetchHook();

  const [shareholder_acc, set_shareholder_acc] = useState(
    selector.usersData.userAccounts.filter((acc) => {
      return acc.type === "shareholder";
    })
  );

  const [sms_supplier_acc, set_sms_supplier_acc] = useState(
    selector.usersData.userAccounts.filter((acc) => {
      return acc.type === "sms_supplier";
    })
  );

  const [sms_inventory_acc, set_sms_inventory_acc] = useState(
    selector.usersData.userAccounts.filter((acc) => {
      return acc.type === "sms_inventory";
    })
  );

  const handleModal = () => {
    setShow(!show);
  };

  const handleModal2 = () => {
    setShow2(!show2);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const fetchBankAcc = () => {
    accRequest({
      url: `account?entity_id='${location.state.user.id}'`,
    });
  };

  const fetchTransactions = () => {
    fetchTrans({
      url: `transaction?filter_value=${location.state.account.account_number}`,
    });
  };

  const fetchSmsSupplierAccounts = () => {
    smsAccRequest({
      url: `account?type="sms_supplier"`,
    });
  };

  const fetchAirtimeSupplierAccounts = () => {
    airtimeAccRequest({
      url: `account?type="airtime_supplier"`,
    });
  };

  useEffect(() => {
    if (checkTrans === 0) {
      fetchTransactions();
      setCheckTrans(1);
    }
  }, [checkTrans]);

  const columns = [
    {
      headerName: "Transaction id",
      field: "transaction_id",
      width: 150,
    },
    {
      headerName: "Transaction source",
      field: "source_transaction_id",
      width: 150,
    },
    {
      headerName: "Account number",
      field: "account_number",
      width: 150,
    },
    {
      headerName: "Loan id",
      field: "loan_id",
      width: 150,
    },
    {
      headerName: "Dc - Cr pair",
      field: "dr_cr_pair_id",
      width: 150,
    },
    {
      headerName: "Global id",
      field: "global_request_id",
      width: 150,
    },
    {
      headerName: "Debit (money out)",
      field: "debit",
    },
    {
      headerName: "Credit (money in)",
      field: "credit",
    },
    {
      headerName: "Balance before",
      field: "balance_before",
      width: 150,
    },
    {
      headerName: "Balance after",
      field: "balance_after",
    },
    {
      headerName: "Transaction status",
      field: "transaction_status",
      width: 150,
    },
    {
      headerName: "Description",
      field: "description",
      width: 150,
    },
    {
      headerName: "Transaction time",
      field: `transaction_timestamp`,
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("Do-MMM-YY hh:mm:ss a"),
    },
    {
      headerName: "Transaction settlement time",
      field: `transaction_settlement_time`,
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("Do-MMM-YY hh:mm:ss a"),
    },
  ];

  useEffect(() => {
    if (checkAcc === 0) {
      fetchBankAcc();

      if (location.state.account.type === "sms_inventory") {
        fetchSmsSupplierAccounts();
      }

      if (location.state.account.type === "airtime_inventory") {
        fetchAirtimeSupplierAccounts();
      }
      setCheckAcc(1);
    }

    if (accResponse && accResponse.status === 200) {
      const data = accResponse.data.Loan.filter((el) => {
        return el.type === "bank";
      });
      setBankAccNumber(data);

      let shareholderData = accResponse.data.Loan.filter((el) => {
        return el.type === "shareholder";
      });
      set_shareholder_acc(shareholderData);

      let smsSupplierData = accResponse.data.Loan.filter((el) => {
        return el.type === "sms_supplier";
      });
      set_sms_supplier_acc(smsSupplierData);

      let smsInventoryData = accResponse.data.Loan.filter((el) => {
        return el.type === "sms_inventory";
      });
      set_sms_inventory_acc(smsInventoryData);
    }

    if (smsAccResponse && smsAccResponse.status === 200) {
      let smsSupplierData = smsAccResponse.data.Loan.filter((el) => {
        return el.type === "sms_supplier";
      });
      set_sms_supplier_acc_options(smsSupplierData);
    }

    if (airtimeAccResponse && airtimeAccResponse.status === 200) {
      set_airtime_supplier_acc_options(airtimeAccResponse.data.Loan);
    }
  }, [checkAcc, accResponse, location, smsAccResponse, airtimeAccResponse]);

  const injectCapital = (e) => {
    if (location.state.account.type === "bank") {
      if (shareholder_acc[0]?.account_number === undefined) {
        handleSnackbar();
        setSeverity({
          severity: "error",
          message: "Create a shareholders account!",
        });
      } else {
        let formData = {
          shareholder_account_no: shareholder_acc[0].account_number,
          mpesa_bank_accounta_no: bankAccNumber[0].account_number,
          amount: e.amount,
          loan_id: shareholder_acc[0].account_number,
          global_id: shareholder_acc[0].account_number,
        };
        let props = {
          url: "api_5",
          body: formData,
        };

        setProps(props);
        setCheck(1);
      }
    } else if (location.state.account.type === "shareholder") {
      if (bankAccNumber[0]?.account_number === undefined) {
        handleSnackbar();
        setSeverity({
          severity: "error",
          message: "Create a bank account!",
        });
      } else {
        let formData = {
          shareholder_account_no: shareholder_acc[0].account_number,
          mpesa_bank_accounta_no: bankAccNumber[0].account_number,
          amount: e.amount,
          loan_id: bankAccNumber[0].account_number,
          global_id: bankAccNumber[0].account_number,
        };

        let props = {
          url: "api_5",
          body: formData,
        };

        setProps(props);
        setCheck(1);
      }
    } else if (
      location.state.account.type === "sms_supplier" ||
      location.state.account.type === "sms_inventory"
    ) {
      if (sms_inventory_acc[0]?.account_number === undefined) {
        handleSnackbar();
        setSeverity({
          severity: "error",
          message: "Create an sms supplier or inventory account!",
        });
      } else {
        let formData = {
          supplier_account_no: sms_supplier_acc.account_number,
          inventory_account_no: sms_inventory_acc[0].account_number,
          amount: e.amount,
          loan_id: sms_supplier_acc.account_number,
          global_id: sms_inventory_acc[0].account_number,
        };

        let props = {
          url: "api_7",
          body: formData,
        };

        setProps(props);
        setCheck(1);
      }
    } else if (location.state.account.type === "airtime_inventory") {
      let formData = {
        product_onwer_id: LoggedInUser.id,
        supplier_id: airtime_supplier_acc.entity_id,
        amount: e.amount,
      };

      airtimeInjectionReq({
        url: "/purchase_inventory_airtime",
        body: formData,
      });
    } else {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: "Capital injection not allowed!",
      });
    }
  };

  useEffect(() => {
    if (check === 1) {
      injectionRequest(props);
      setCheck(0);
    }

    if (
      injectionResponse &&
      injectionResponse.status === 200 &&
      injectionResponse.data.StatusCode === 0
    ) {
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Capital Injection successful!",
      });
      setCheckTrans(0);
    } else if (
      injectionResponse &&
      injectionResponse.status === 200 &&
      injectionResponse.data.StatusCode !== 0
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: injectionResponse.data.StatusMessage,
      });
    } else if (injectionResponse && injectionResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: injectionResponse.response.data.message,
      });
    }

    if (
      airtimeInjectionResponse &&
      airtimeInjectionResponse.status === 200 &&
      airtimeInjectionResponse.data.StatusCode === 0
    ) {
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Capital Injection successful!",
      });
      setCheckTrans(0);
    } else if (
      airtimeInjectionResponse &&
      airtimeInjectionResponse.status === 200 &&
      airtimeInjectionResponse.data.StatusCode !== 0
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: airtimeInjectionResponse.data.StatusMessage,
      });
    } else if (
      airtimeInjectionResponse &&
      airtimeInjectionResponse.status !== 200
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: airtimeInjectionResponse.response.data.message,
      });
    }
  }, [check, injectionResponse, airtimeInjectionResponse]);

  const fundTransfer = (e) => {
    let formData = {
      customer_account_no_from: location.state.account.account_number,
      customer_account_no_to: acc.account_number,
      amount: e.amount,
      loan_id: "",
      global_id: "",
    };

    transferRequest({
      url: "/api_106",
      body: formData,
    });
  };

  useEffect(() => {
    if (
      transferResponse &&
      transferResponse.status === 200 &&
      transferResponse.data.StatusCode === 0
    ) {
      handleModal2();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Funds transfer successful!",
      });
      setCheckTrans(0);
    } else if (
      transferResponse &&
      transferResponse.status === 200 &&
      transferResponse.data.StatusCode !== 0
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: transferResponse.data.StatusMessage,
      });
    } else if (transferResponse && transferResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: transferResponse.response.data.message,
      });
    }
  }, [check, transferResponse]);

  return (
    <Paper>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={show} onClose={2}>
        <DialogTitle sx={{ background: grey[200] }}>
          Capital Injection
        </DialogTitle>
        <DialogContent
          sx={{
            my: "30px",
            mx: "10px",
            width: "auto",
          }}
        >
          <form
            onSubmit={handleSubmit(injectCapital)}
            style={{ width: "250px" }}
          >
            {location.state.account.type === "sms_inventory" && (
              <Grid>
                <FormControl fullWidth sx={{ mt: "5px" }}>
                  <InputLabel id="select-supplier">Supplier</InputLabel>
                  <Select
                    labelId="select-supplier"
                    id="select-supplier"
                    value={sms_supplier_acc}
                    label="Supplier"
                    onChange={(e) => {
                      set_sms_supplier_acc(e.target.value);
                    }}
                  >
                    {sms_supplier_acc_options.map((el, i) => (
                      <MenuItem value={el} key={i}>
                        {el.account_name} - {el.entity_id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {location.state.account.type === "airtime_inventory" && (
              <Grid>
                <FormControl fullWidth sx={{ mt: "5px" }}>
                  <InputLabel id="select-supplier">Supplier</InputLabel>
                  <Select
                    labelId="select-supplier"
                    id="select-supplier"
                    value={airtime_supplier_acc}
                    label="Supplier"
                    onChange={(e) => {
                      set_airtime_supplier_acc(e.target.value);
                    }}
                  >
                    {airtime_supplier_acc_options.map((el, i) => (
                      <MenuItem value={el} key={i}>
                        {el.account_name} - {el.entity_id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.amount}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <OutlinedInput
                  id="amount"
                  type="number"
                  {...register("amount")}
                  label="Amount"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  fullWidth
                />
                {errors.amount && (
                  <FormHelperText>Enter publisher name</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Box
              sx={{
                paddingTop: "50px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button type="reset" variant="outlined" onClick={handleModal}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={show2} onClose={handleModal2}>
        <DialogTitle sx={{ background: grey[200] }}>
          Funds transfer form
        </DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            mx: "10px",
            width: "auto",
          }}
        >
          <form
            onSubmit={handleSubmit(fundTransfer)}
            style={{ width: "250px" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "10px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="customer_account_no_to">Transfer to</InputLabel>
                <Select
                  labelId="customer_account_no_to"
                  id="customer_account_no_to"
                  value={acc}
                  label="Transfer to"
                  onChange={(e) => {
                    setAcc(e.target.value);
                  }}
                >
                  {selector.usersData.userAccounts.map((el, i) => (
                    <MenuItem value={el} key={i}>
                      {el.type} - {el.account_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.amount}>
                <InputLabel htmlFor="amount">Amount</InputLabel>
                <OutlinedInput
                  id="amount"
                  type="number"
                  {...register("amount")}
                  label="Amount"
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  fullWidth
                />
                {errors.amount && (
                  <FormHelperText>Enter publisher name</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Box
              sx={{
                paddingTop: "50px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button type="reset" variant="outlined" onClick={handleModal2}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      <Grid container columns={12} columnSpacing={3} sx={{ p: "20px" }}>
        <Grid item xs={12} sm={4} md={12}>
          <Card elevation={5}>
            <CardHeader
              title={<Typography variant="h6">Account profile</Typography>}
              action={
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              }
              sx={{ background: grey[200] }}
            />

            <CardContent sx={{}}>
              <Grid container columnSpacing={5}>
                <Grid item>
                  <Avatar
                    {...stringAvatar(`${location.state.account.account_name} `)}
                    sx={{ width: 100, height: 100 }}
                  />
                </Grid>

                <Grid item sx={{ p: "10px" }}>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    Account No:
                    <span style={{ color: green[800], fontWeight: "bold" }}>
                      {location.state.account.account_number}
                    </span>
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    Account Name:
                    <span style={{ color: green[800], fontWeight: "bold" }}>
                      {location.state.account.account_name}
                    </span>
                  </Typography>

                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    Account balance:
                    <span style={{ color: green[800], fontWeight: "bold" }}>
                      {location.state.account.account_balance}
                    </span>
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    Account type:
                    <span style={{ color: green[800], fontWeight: "bold" }}>
                      {location.state.account.type}
                    </span>
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    Account description:
                    <span style={{ color: green[800], fontWeight: "bold" }}>
                      {location.state.account.account_description}
                    </span>
                  </Typography>

                  <Typography variant="h6" sx={{ fontSize: "16px" }}>
                    Start date:
                    <span style={{ color: green[800], fontWeight: "bold" }}>
                      {moment(location.state.account.start_date).format(
                        "Do-MMM-YY, HH:mm:ss a"
                      )}
                    </span>
                  </Typography>
                </Grid>

                <Grid item>
                  {LoggedInUser.role === "admin" &&
                    (location.state.account.type === "bank" ||
                      location.state.account.type === "shareholder" ||
                      location.state.account.type === "sms_supplier" ||
                      location.state.account.type === "sms_inventory" ||
                      location.state.account.type === "airtime_inventory") && (
                      <Button
                        variant="contained"
                        sx={{ fontWeight: "semiBold" }}
                        onClick={handleModal}
                      >
                        Add capital
                      </Button>
                    )}

                  {/* {(location.state.account.type === "in_wallet" ||
                    location.state.account.type === "out_wallet" ||
                    location.state.account.type === "universal_wallet" ||
                    location.state.account.type === "saving_wallet") && (
                    <Button variant="contained" onClick={handleModal2}>
                      Fund transfer
                    </Button>
                  )} */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box container sx={{ p: "20px" }}>
        <Card elevation={5}>
          <CardContent>
            {transResponse && transResponse.status === 200 ? (
              <TableComponent
                columns={columns}
                data={transResponse.data.Data}
                loading={transLoading}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
};

export default UserAccountProfile;
