import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Tab,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PostHooks from "../../../../Api/PostHooks";

const LoanRepaymentTabs = ({
  handleModal,
  loan,
  handleSnackbar,
  setSeverity,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, response, request] = PostHooks();
  const [payOffLoading, payOffResponse, payOffRequest] = PostHooks();

  const payOff = () => {
    let formData = {
      loan_id: loan?.id,
      type: "paid_off",
    };

    payOffRequest({
      url: "/early_repayment",
      body: formData,
    });
  };

  const earlyRepayment = (e) => {
    let formData = {
      loan_id: loan?.id,
      type: "early",
      amount: e.amount,
    };

    request({
      url: "/early_repayment",
      body: formData,
    });
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Loan repayment successful!",
      });
      handleSnackbar();
      handleModal();
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
      handleSnackbar();
    } else if (response && response.status !== 200) {
      setSeverity({
        severity: "error",
        message: response.response.data.message,
      });
      handleSnackbar();
    }
  }, [response]);

  useEffect(() => {
    if (payOffResponse && payOffResponse.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Loan repayment successful!",
      });
      handleSnackbar();
      handleModal();
    } else if (payOffResponse && payOffResponse.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: payOffResponse.data.StatusMessage,
      });
      handleSnackbar();
    } else if (payOffResponse && payOffResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: payOffResponse.payOffResponse.data.message,
      });
      handleSnackbar();
    }
  }, [payOffResponse]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      {/* <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      /> */}

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="user tabs"
            allowScrollButtonsMobile
            variant="scrollable"
            textColor="secondary"
            indicatorColor="secondary"
            scrollButtons="auto"
          >
            <Tab label="Early repayment" value="1" />
            <Tab label="Pay off" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <form onSubmit={handleSubmit(earlyRepayment)}>
            <FormControl
              fullWidth
              error={!!errors.amount}
              sx={{
                mt: "20px",
              }}
            >
              <InputLabel htmlFor="amount">Amount</InputLabel>
              <OutlinedInput
                id="amount"
                type="amount"
                {...register("amount", { required: true })}
                label="Username"
              />
              {errors.amount && (
                <FormHelperText>Please enter an amount!</FormHelperText>
              )}
            </FormControl>

            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ pt: "40px" }}
            >
              <Button variant="outlined" onClick={handleModal} type="button">
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
          </form>
        </TabPanel>

        <TabPanel value="2">
          <Grid
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <LoadingButton
              variant="contained"
              loading={payOffLoading}
              type="submit"
              onClick={payOff}
            >
              Pay off loan
            </LoadingButton>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LoanRepaymentTabs;
