import React, { useEffect, useState } from "react";
import digiApi from "../../Api/digiApi";
import { Box, Grid } from "@mui/material";
import { lightBlue } from "@mui/material/node/colors";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import moment from "moment";
import { Card } from "@mui/material";
import { CardHeader } from "@mui/material";
import { Typography } from "@mui/material";
import { CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerAccounts, userData } from "../../Redux/userSlice";
import PageLoader from "../../Components/PageLoader";
import TableComponent from "../../Components/TableComponent";

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initState = useSelector((state) => state.usersData.customerAccounts);

  const [customers, setCustomers] = useState(initState);
  const [loading, setLoading] = useState(false);

  const fetchCustomers = async () => {
    setLoading(true);
    const data = await digiApi.users
      .getCustomers("customer")
      .then((res) => {
        setCustomers(res.data.Data);
        dispatch(customerAccounts(res.data.Data));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (customers.length <= 0) {
      fetchCustomers();
    }
  }, []);

  const columns = [
    { field: "id", headerName: "Entity Id", minWidth: 200, flex: 1 },
    {
      field: "document_number",
      headerName: "Identification No",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      valueGetter: (params) => {
        return `${params.row?.first_name || ""} ${params.row?.surname || ""} ${
          params.row?.other_names || ""
        }`;
      },
      minWidth: 200,
      flex: 1,
    },
    { field: "msisdn", headerName: "Phone No", minWidth: 200, flex: 1 },
    { field: "role", headerName: "Role", minWidth: 100, flex: 1 },
    { field: "kyc_status", headerName: "Status", minWidth: 100, flex: 1 },
    {
      field: "start_date",
      headerName: "Start date",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "profile",
      headerName: "Profile",
      renderCell: (row) => (
        <Button
          variant="outlined"
          style={{ marginLeft: 16, fontWeight: "normal" }}
          onClick={() => {
            navigate("/dashboard/users/user-profile", {
              state: { user: row.row },
            });
            dispatch(userData(row.row));
          }}
        >
          Profile
        </Button>
      ),
      minWidth: 100,
      flex: 1,
    },
  ];

  return (
    <Box sx={{ margin: "20px" }}>
      <Card>
        <CardHeader
          sx={{
            px: "25px",
          }}
          title={<Typography variant="h6">Customers</Typography>}
        />
        <CardContent>
          {loading ? (
            <PageLoader />
          ) : customers && customers.length > 1 ? (
            <Box>
              <TableComponent
                columns={columns}
                data={customers}
                loading={loading}
              />
            </Box>
          ) : (
            customers &&
            customers.length <= 0 && (
              <Box>
                <Typography align="center" color="#8793A4">
                  No Customers Found!
                </Typography>
              </Box>
            )
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Customers;
