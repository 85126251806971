import React from "react";
import FetchHook from "../../Api/FetchHooks";
import { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import PageLoader from "../../Components/PageLoader";
import TableComponent from "../../Components/TableComponent";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import CreatePoolBasket from "./CreatePoolBasket";
import AlertComponent from "../../Components/AlertComponent";

const PoolBasket = () => {
  const [status, setStatus] = useState("active");
  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const [loading, response, request] = FetchHook();

  const fetchPoolBaskets = () => {
    request({
      url: `/pool_basket?status=${status}`,
    });
  };

  useEffect(() => {
    if (check === 0) {
      fetchPoolBaskets();
      setCheck(1);
    }
  }, [check, response]);

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 150,
    },
    {
      field: "entity_id",
      headerName: "Owner",
      width: 180,
    },
    {
      field: "name",
      headerName: "Pool Name",
      width: 150,
    },
    {
      field: "members",
      headerName: "Members",
      width: 120,
    },
    {
      field: "contributions",
      headerName: "Contributions",
      width: 150,
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 120,
    },
    {
      field: "interest_earned",
      headerName: "Interest Earned",
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "date_created",
      headerName: "Date Created",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "last_updated_on",
      headerName: "Date Updated",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Create Pool Basket</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { sm: "400px" },
          }}
        >
          <CreatePoolBasket
            handleModal={handleModal}
            setSeverity={setSeverity}
            handleSnackbar={handleSnackbar}
            setCheck={setCheck}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          title={<Typography variant="h6">Pool Baskets</Typography>}
          action={
            <Box>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  handleModal();
                }}
              >
                Create Basket
              </Button>
            </Box>
          }
        />

        <CardContent>
          {loading ? (
            <PageLoader />
          ) : response && response.data.StatusCode === 0 ? (
            <TableComponent
              data={response.data.Data}
              columns={columns}
              loading={loading}
            />
          ) : (
            response &&
            response.data.StatusCode !== 0 && (
              <Typography>
                {`Error ${response?.data?.StatusMessage}`}
              </Typography>
            )
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default PoolBasket;
