import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@mui/material";
import React from "react";
import FetchHook from "../../../../Api/FetchHooks";
import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import PageLoader from "../../../../Components/PageLoader";
import TableComponent from "../../../../Components/TableComponent";

const Guarantors = ({ user }) => {
  const [loading, response, request] = FetchHook();

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(0);

  const handleModal = () => {
    setOpen(!open);
  };

  const fetchGuarantors = () => {
    request({ url: `guarantorship?entity_id=${user.id}&type=other_members` });
  };

  useEffect(() => {
    if ((user?.id && response === undefined) || check === 0) {
      fetchGuarantors();
      setCheck(1);
    }

    if (response && response.status === 200 && response.data.StatusCode === 0) {
      setData(response.data.Data);
    }
  }, [response, check]);

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 180,
    },
    // {
    //   field: "asset_name",
    //   headerName: "Asset name",
    //   minWidth: 150,
    //   flex: 1,
    // },
    {
      field: "loan_id",
      headerName: "Loan Id",
      width: 180,
    },
    {
      field: "loan_name",
      headerName: "Loan Name",
      width: 150,
    },
    // {
    //   field: "loan_amount",
    //   headerName: "Loan amount",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "guarantorship_amount",
      headerName: "Requested amount",
    },
    {
      field: "guaranteered_amount",
      headerName: "Guaranteed amount",
    },
    {
      field: "guarantorship_amount_bal",
      headerName: "Guarantorship amount balance",
      width: 150,
    },
    // {
    //   field: "worthyness",
    //   headerName: "Worthyness",
    //   minWidth: 150,
    //   flex: 1,
    // },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        if (params.row.status === 0) {
          return (
            <Chip label="pending" color="warning" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 1) {
          return (
            <Chip label="accepted" color="success" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 3) {
          return (
            <Chip label="rejected" color="error" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 4) {
          return <Chip label="paid_off" color="info" sx={{ color: "white" }} />;
        } else if (params.row.status === 5) {
          return (
            <Chip label="recovered" color="info" sx={{ color: "white" }} />
          );
        }
      },
    },
    {
      field: "date_created",
      headerName: "Date created",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY, hh:mm:ss a"),
    },
    {
      field: "last_updated_on",
      headerName: "Last updated on",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY, hh:mm:ss a"),
    },
  ];

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Typography variant="h5">
              Your have {data.length} guarantors
            </Typography>
          }
        />

        <CardContent>
          {data.length > 0 ? (
            <TableComponent data={data} columns={columns} loading={loading} />
          ) : loading ? (
            <PageLoader />
          ) : (
            <Typography align="center">You have no guarantors!</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Guarantors;
