import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import WarningIcon from "@mui/icons-material/Warning";
import PostHooks from "../../../Api/PostHooks";
import AlertComponent from "../../../Components/AlertComponent";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

const DeleteKinForm = ({
  user,
  handleModal,
  setCheck,
  handleSnackbar,
  setSeverity,
}) => {
  const { handleSubmit } = useForm();

  const [loading, response, request] = PostHooks();

  const deleteKin = () => {
    let formData = {
      next_kin_id: user?.next_kin_id,
    };

    request({
      url: "delete_kin",
      body: formData,
    });
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Kin deleted successfully!",
      });
      setCheck(0);
    } else if (response && response.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
    }
  }, [response]);

  return (
    <form onSubmit={handleSubmit(deleteKin)}>
      <Stack>
        <IconButton aria-label="caution" color={"warning"}>
          <WarningIcon sx={{ fontSize: 150 }} />
        </IconButton>

        <Typography align="center">
          You are about to delete your next of kin!
        </Typography>
      </Stack>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          mt: 5,
          mx: 3,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          type="reset"
          onClick={handleModal}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
        >
          Confirm
        </LoadingButton>
      </Box>
    </form>
  );
};

export default DeleteKinForm;
