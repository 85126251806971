import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Tab } from "@mui/material";
import React from "react";
import UserAccounts from "./Components/UserAccounts/UserAccounts";
import UserLoans from "./Components/UserLoan/UserLoans";
import UserProfileInfo from "./Components/UserProfileInfo";
import LoanRequests from "../Loans/LoanRequests";
import GuarantorNominations from "./Components/Guarantors/GuarantorNominations";
import Shares from "../Shares/Shares";
import Guarantors from "./Components/Guarantors/Guarantors";
import MemberShares from "../Shares/MemberShares";
import UserTransactions from "../Transactions/userTransactions";
import { getUserDetails } from "../../utils/helpers";
import Deposits from "../Transactions/Deposits";
import UserSavings from "../Transactions/UserSavings";
import UserPoolTransactions from "../PoolFund/UserPoolTransactions";

const UserTabs = ({ user, fetchUser }) => {
  const loggedInuser = getUserDetails();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="user tabs"
            allowScrollButtonsMobile
            variant="scrollable"
            textColor="secondary"
            indicatorColor="secondary"
            scrollButtons="auto"
          >
            <Tab label="Profile" value="1" />
            {loggedInuser?.role === "admin" && (
              <Tab label="Accounts" value="2" />
            )}
            <Tab label="Deposits" value="11" />
            <Tab label="Wallet" value="10" />
            <Tab label="Savings" value="12" />
            <Tab label="Pool" value="13" />
            <Tab label="Loan" value="3" />
            <Tab label="Loan Requests" value="4" />
            <Tab label="Guarantees" value="5" />
            <Tab label="Guarantors" value="7" />
            <Tab label="Shares" value="6" />
            {/* <Tab label="Transactions" value="8" /> */}
            {/* <Tab label="Bid Shares" value="9" /> */}
          </TabList>
        </Box>

        <TabPanel value="1">
          <UserProfileInfo user={user} />
        </TabPanel>

        <TabPanel value="2">
          <UserAccounts />
        </TabPanel>

        <TabPanel value="3">
          <UserLoans fetchUser={fetchUser} />
        </TabPanel>
        <TabPanel value="4">
          <LoanRequests user={user} />
        </TabPanel>
        <TabPanel value="5">
          <GuarantorNominations user={user} />
        </TabPanel>
        <TabPanel value="6">
          <MemberShares user={user} fetchUser={fetchUser} />
        </TabPanel>
        <TabPanel value="7">
          <Guarantors user={user} />
        </TabPanel>
        <TabPanel value="8">
          <UserTransactions user={user} />
        </TabPanel>
        <TabPanel value="9">
          <Shares user={user} fetchUser={fetchUser} />
        </TabPanel>
        <TabPanel value="10">
          <UserTransactions user={user} />
        </TabPanel>
        <TabPanel value="11">
          <Deposits user={user} />
        </TabPanel>
        <TabPanel value="12">
          <UserSavings user={user} />
        </TabPanel>
        <TabPanel value="13">
          <UserPoolTransactions user={user} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default UserTabs;
