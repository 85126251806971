import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const AddInventoryForm = ({
  addInventory,
  product_type,
  set_product_type,
  product_options,
  addLoading,
  handleModal,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form
      onSubmit={handleSubmit(addInventory)}
      style={{ width: "20vw", padding: "20px" }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="account_t">Inventory Type</InputLabel>
          <Select
            labelId="account_t"
            id="account_t"
            defaultValue=""
            value={product_type}
            label="Inventory Type"
            onChange={(e) => set_product_type(e.target.value)}
            required
          >
            {product_options.map((account) => (
              <MenuItem value={account.value} key={account.value}>
                {account.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.name}>
          <InputLabel htmlFor="name">Name</InputLabel>
          <OutlinedInput
            id="name"
            type="text"
            {...register("name")}
            label="Name"
            fullWidth
          />
          {errors.name && <FormHelperText>Enter name</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.model}>
          <InputLabel htmlFor="model">Model</InputLabel>
          <OutlinedInput
            id="model"
            type="text"
            {...register("model")}
            label="Model"
            fullWidth
          />
          {errors.model && <FormHelperText>Enter model</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.size}>
          <InputLabel htmlFor="size">size</InputLabel>
          <OutlinedInput
            id="size"
            type="number"
            {...register("size")}
            label="size"
            onWheel={(event) => {
              event.target.blur();
            }}
            fullWidth
          />
          {errors.size && <FormHelperText>Enter size</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.quantity}>
          <InputLabel htmlFor="quantity">Quantity</InputLabel>
          <OutlinedInput
            id="quantity"
            type="number"
            {...register("quantity")}
            label="Quantity"
            onWheel={(event) => {
              event.target.blur();
            }}
            fullWidth
          />
          {errors.quantity && <FormHelperText>Enter quantity</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.amount}>
          <InputLabel htmlFor="amount">Amount</InputLabel>
          <OutlinedInput
            id="amount"
            type="number"
            {...register("amount")}
            label="Amount"
            onWheel={(event) => {
              event.target.blur();
            }}
            fullWidth
          />
          {errors.amount && <FormHelperText>Enter amount</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "50px",
        }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Button variant="outlined" type="reset" onClick={() => handleModal()}>
          Cancel
        </Button>
        <LoadingButton variant="contained" type="submit" loading={addLoading}>
          Submit
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default AddInventoryForm;
