import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PostHooks from "../../../Api/PostHooks";
import { useState } from "react";
import { MuiTelInput } from "mui-tel-input";

const PurchaseAirtimeForm = ({
  user,
  handleModal,
  setSeverity,
  handleSnackbar,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [other, setOther] = useState("");
  const [msisdn, setMsisdn] = useState("");

  const [loading, response, request] = PostHooks();

  const purchase = (e) => {
    let formData = {
      customer_entity_id: user?.id,
      amount: e.amount,
      others: other,
      other_msisdn: other === "YES" ? msisdn.replace("+", "") : "",
    };

    request({
      url: "sell_inventory_airtime",
      body: formData,
    });
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Airtime purchase successfull!",
      });
      handleModal();
      handleSnackbar();
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [response]);

  return (
    <form onSubmit={handleSubmit(purchase)} style={{ width: "auto" }}>
      <FormControl
        fullWidth
        sx={{
          my: "20px",
        }}
      >
        <InputLabel id="who">Who</InputLabel>
        <Select
          labelId="who"
          id="who"
          defaultValue=""
          value={other}
          label="Who"
          onChange={(e) => setOther(e.target.value)}
          required
        >
          <MenuItem value="NO">Buy for My Number </MenuItem>
          <MenuItem value="YES">Buy for Other Number </MenuItem>
        </Select>
      </FormControl>

      {other === "YES" && (
        <>
          <InputLabel
            sx={{
              pb: 1,
            }}
          >
            Other Phone Number
          </InputLabel>
          <FormControl fullWidth>
            <MuiTelInput
              sx={{
                border: "1px solid black",
              }}
              defaultCountry="KE"
              disableFormatting
              value={msisdn}
              onChange={(e) => setMsisdn(e)}
            />
          </FormControl>
        </>
      )}

      <FormControl
        fullWidth
        error={!!errors.amount}
        sx={{
          mt: 3,
        }}
      >
        <InputLabel htmlFor="amount">Amount</InputLabel>
        <OutlinedInput
          id="amount"
          type="number"
          {...register("amount", { required: true })}
          label="Amount"
        />
        {errors.amount && (
          <FormHelperText>Please enter an amount!</FormHelperText>
        )}
      </FormControl>

      <Box
        sx={{
          pt: "40px",
          display: "flex",
          direction: "row",
          justifyContent: "space-between",
        }}
      >
        <Button type="reset" variant="outlined" onClick={handleModal}>
          Cancel
        </Button>
        <LoadingButton variant="contained" type="submit" loading={loading}>
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
};

export default PurchaseAirtimeForm;
