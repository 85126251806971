import moment from "moment";

const getOidcStorageKey = () => {
  const authSettings = {
    auth_server: "digi_sacco",
    client_id: "frontend",
  };
  return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
};

const getOidcInfo = () => {
  const key = getOidcStorageKey();
  const oidc = JSON.parse(localStorage.getItem(key) || "{}");
  return oidc;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.token;
  }
  return null;
};

export const getRefreshToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.refreshtoken;
  }
  return null;
};

export const setToken = (token) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.token = token;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const setRefreshToken = (refreshtoken) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.refreshtoken = refreshtoken;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const logout = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.token = undefined;
    oidc.refreshtoken = undefined;
    oidc.user = undefined;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const getUserDetails = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.user;
  }
  return null;
};

export const setUserDetails = (userDetails) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.user = userDetails;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const setThemeName = (themeName) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.themeName = themeName;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const getThemeName = () => {
  const oidc = getOidcInfo();
  return oidc?.themeName || "default";
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      // bgcolor: stringToColor(name),
      width: 100,
      height: 100,
    },
    children: `${name.split(" ")[0][0]}`,
  };
};

export const checkLogin = () => {
  const isLoggedIn = getToken();

  return isLoggedIn;
};

export const ranges = (startDate, endDate) => ({
  "Today Only": [moment(startDate), moment(endDate)],
  Yesterday: [moment(startDate).subtract(1, "days"), moment(endDate)],
  "3 Days": [moment(startDate).subtract(3, "days"), moment(endDate)],
  "5 Days": [moment(startDate).subtract(5, "days"), moment(endDate)],
  "1 Week": [moment(startDate).subtract(7, "days"), moment(endDate)],
  "2 Weeks": [moment(startDate).subtract(14, "days"), moment(endDate)],
  "1 Month": [moment(startDate).subtract(1, "months"), moment(endDate)],
  "1 Year": [moment(startDate).subtract(1, "years"), moment(endDate)],
});

export let local = {
  format: "DD-MM-YYYY",
  sundayFirst: false,
};
