import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FetchHook from "../../Api/FetchHooks";
import TableComponent from "../../Components/TableComponent";
import moment from "moment";
import PageLoader from "../../Components/PageLoader";
import { LoadingButton } from "@mui/lab";
import PutHooks from "../../Api/PutHook";
import { useForm } from "react-hook-form";
import AlertComponent from "../../Components/AlertComponent";

const WithdrawRequests = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, response, request] = FetchHook();
  const [approvalLoading, approvalResponse, approvalRequest] = PutHooks();

  const [fetch, setFetch] = useState(0);
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [poolRequest, setPoolRequest] = useState();
  const [approval, setApproval] = useState("");
  const [severity, setSeverity] = useState({});

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackBar(!openSnackBar);
  };

  useEffect(() => {
    if (fetch === 0) {
      request({
        url: `/pool_fund_request?status=${status}`,
      });
      setFetch(1);
    }

    if (response && response.data.StatusCode === 0) {
      setData(response.data.PoolFund);
    }
  }, [fetch, response]);

  useEffect(() => {
    if (approvalResponse && approvalResponse.data.StatusCode === 0) {
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: approvalResponse.data.StatusMessage,
      });
      setFetch(0);
    } else if (approvalResponse && approvalResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: approvalResponse.data.StatusMessage,
      });
    }
  }, [approvalResponse]);

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "entity_id",
      headerName: "Entity id",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "active_status",
      headerName: "Active Status",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "start_date",
      headerName: "Start date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "Action",
      headerName: "",
      renderCell: (row) => (
        <>
          {row.row.active_status === "pending" && (
            <Button
              variant="outlined"
              onClick={() => {
                handleModal();
                setPoolRequest(row.row);
              }}
            >
              Action
            </Button>
          )}
        </>
      ),
    },
  ];

  const requestApproval = () => {
    let formData = {
      request_id: poolRequest?.id,
      entity_id: poolRequest?.entity_id,
      amount: poolRequest?.amount,
      status: approval,
    };

    approvalRequest({
      url: "pool_fund_request",
      body: formData,
    });
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <AlertComponent
        open={openSnackBar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Pool withdraw approval</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { lg: "400px" },
          }}
        >
          <form onSubmit={handleSubmit(requestApproval)}>
            <FormControl
              fullWidth
              sx={{
                my: "20px",
              }}
            >
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                defaultValue=""
                value={approval}
                label="Status"
                onChange={(e) => setApproval(e.target.value)}
                required
              >
                <MenuItem value="ACCEPTED">Accept </MenuItem>
                <MenuItem value="DECLINED">Decline </MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{
                pt: "40px",
                display: "flex",
                direction: "row",
                justifyContent: "space-between",
              }}
            >
              <Button type="reset" variant="outlined" onClick={handleModal}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={approvalLoading}
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Card>
        <CardHeader
          title={<Typography variant="h6">Pool withdraw requests</Typography>}
          action={
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="status" size="small">
                Status
              </InputLabel>

              <Select
                labelId="status"
                id="status"
                value={status}
                label="status"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setFetch(0);
                }}
                size="small"
              >
                <MenuItem value="accepted">Accepted</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
              </Select>
            </FormControl>
          }
        />

        <CardContent>
          {data.length > 0 ? (
            <>
              <TableComponent data={data} columns={columns} loading={loading} />
            </>
          ) : loading ? (
            <PageLoader />
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default WithdrawRequests;
