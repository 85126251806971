import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PostHooks from "../../../Api/PostHooks";

const BoostShares = ({
  setSeverity,
  handleModal,
  handleSnackbar,
  user,
  fetchUser,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [buyLoading, buyResponse, buyRequest] = PostHooks();

  const addShares = (e) => {
    let formData = {
      type: "buy",
      transafer_type:
        user.shares === 0 ? "first_sacco_sell" : "normal_sacco_sell",
      no_ofshares: e.no_of_shares,
      customer_entity_id: user.id,
    };

    buyRequest({
      url: "/shares",
      body: formData,
    });
  };

  useEffect(() => {
    if (buyResponse && buyResponse.data.StatusCode === 0) {
      fetchUser();
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Share purchase successful",
      });
    } else if (buyResponse && buyResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: buyResponse.data.StatusMessage,
      });
    } else if (buyResponse && buyResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: buyResponse.response.data.StatusMessage,
      });
    }
  }, [buyResponse]);

  return (
    <div>
      <form onSubmit={handleSubmit(addShares)}>
        <FormControl
          fullWidth
          error={!!errors.no_of_shares}
          sx={{
            my: "20px",
          }}
        >
          <InputLabel htmlFor="no_of_shares">Number of shares</InputLabel>
          <OutlinedInput
            id="no_of_shares"
            type="number"
            {...register("no_of_shares", { required: true })}
            label="Number of shares"
            variant="filled"
          />
          {errors.no_of_shares && <FormHelperText>Required</FormHelperText>}
        </FormControl>

        <Box
          sx={{
            my: "15px",
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" type="reset" onClick={handleModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={buyLoading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default BoostShares;
