import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails, logout, setUserDetails } from "../utils/helpers";

const initialState = { products: "{}" };

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    products: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { products } = productSlice.actions;

export default productSlice.reducer;
