import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserDetails, stringAvatar } from "../../utils/helpers";
import { grey } from "@mui/material/node/colors";
import PutHooks from "../../Api/PutHook";
import { useEffect } from "react";
import AlertComponent from "../../Components/AlertComponent";
import GuarantorNominationForm from "../Users/Components/Guarantors/GuarantorNominationForm";
import { LoadingButton } from "@mui/lab";

const LoanRequestProfile = () => {
  const loggedInUser = getUserDetails();
  const location = useLocation();
  const navigate = useNavigate();
  const [fixLoading, fixResponse, fixRequest] = PutHooks();
  const [cancelLoading, cancelResponse, cancelRequest] = PutHooks();

  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [loanRequest, setLoanRequest] = useState(location.state);
  const loanRequestKeys = Object.entries(loanRequest);

  const data = loanRequestKeys.slice(0, loanRequestKeys.length / 2);
  const data2 = loanRequestKeys.slice(loanRequestKeys.length / 2);

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const fixLoanRequest = () => {
    let formData = {
      loan_request_id: loanRequest.id,
    };

    let props = {
      url: "loan_request",
      body: formData,
    };

    fixRequest(props);
  };

  useEffect(() => {
    if (fixResponse && fixResponse.status === 200) {
      setSeverity({
        severity: "success",
        message: fixResponse.data.StatusMessage,
      });
      handleSnackbar();
      navigate(-1);
    } else if (fixResponse && fixResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: fixResponse.response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [fixResponse]);

  const cancelLoan = () => {
    let formData = {
      loan_request_id: loanRequest.id,
    };

    cancelRequest({
      url: "cancel_loan",
      body: formData,
    });
  };

  useEffect(() => {
    if (cancelResponse && cancelResponse.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Loan cancelled successfuly!",
      });
      handleSnackbar();
    } else if (cancelResponse && cancelResponse.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: cancelResponse.data.StatusMessage,
      });
      handleSnackbar();
    } else if (cancelResponse && cancelResponse.status !== 200) {
      setSeverity({
        severity: "error",
        message: cancelResponse.response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [cancelResponse]);

  console.log(loggedInUser);

  return (
    <Box>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Guarantor nomination form</DialogTitle>
        <DialogContent
          sx={{
            my: "20px",
            width: { lg: "400px" },
          }}
        >
          <GuarantorNominationForm
            data={loanRequest}
            handleModal={handleModal}
            setSeverity={setSeverity}
            handleSnackbar={handleSnackbar}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          sx={{
            px: "30px",
            background: grey[300],
          }}
          title={
            <Typography variant="h6">
              Loan request profile for {loanRequest?.id}
            </Typography>
          }
          action={
            <Button
              variant="outlined"
              color="error"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          }
        />
        <CardActions
          sx={{
            my: "20px",
            mx: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {loanRequest?.active_status === "pending" && (
            <LoadingButton
              loading={cancelLoading}
              variant="contained"
              onClick={cancelLoan}
            >
              Cancel loan
            </LoadingButton>
          )}

          <Button variant="contained">View guarantors</Button>

          <Button variant="contained" onClick={handleModal}>
            Add guarantor
          </Button>

          {loggedInUser?.role === "admin" && (
            <Button
              variant="contained"
              style={{ marginLeft: 16, fontWeight: "bold" }}
              disabled={loanRequest.status === 1 ? false : true}
              onClick={() => {
                fixLoanRequest();
              }}
            >
              {fixLoading ? "Fixing..." : "Fix request"}
            </Button>
          )}
        </CardActions>

        <CardContent>
          {loggedInUser?.role === "admin" && (
            <>
              <Grid container display={"flex"} spacing={{ md: 10 }}>
                <Grid item md={6}>
                  {data.map((el, i) => (
                    <Table key={i}>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="subtitle1">{el[0]}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">{el[1]}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                </Grid>
                <Grid item md={6}>
                  {data2.map((el, i) => (
                    <Table key={i}>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="subtitle1">{el[0]}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body1">{el[1]}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoanRequestProfile;
