import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PostHooks from "../../../../Api/PostHooks";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import AlertComponent from "../../../../Components/AlertComponent";

const GuarantorApprovalForm = ({ data, setCheck, handleModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, response, request] = PostHooks();

  console.log("data", data);

  const [status, setStatus] = useState();
  const [severity, setSeverity] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const onSubmit = (e) => {
    let formData = {
      id: data.id,
      amount: e.amount,
      description: status,
      status: status,
    };

    request({
      url: "/approve_gurantorship_req",
      body: formData,
    });
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({
        severity: "success",
        message: "Gurantor Request Approved Successfully!",
      });
      handleSnackbar();
      handleModal();
      setCheck(0);
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
      handleSnackbar();
    } else if (response && response.status !== 202) {
      setSeverity({
        severity: "error",
        message: response.response.data.message,
      });
      handleSnackbar();
    }
  }, [response]);

  console.log("response", response);

  return (
    <div>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          fullWidth
          sx={{
            my: "20px",
          }}
        >
          <InputLabel id="status">Status</InputLabel>
          <Select
            labelId="status"
            id="status"
            defaultValue=""
            value={status}
            label="Status"
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <MenuItem value="accept">Accept </MenuItem>
            <MenuItem value="decline">Decline </MenuItem>
          </Select>
        </FormControl>

        {status === "accept" && (
          <FormControl
            fullWidth
            sx={{
              my: "30px",
            }}
            error={!!errors.maximum_guarantor_percentage}
          >
            <InputLabel htmlFor="amount">Amount</InputLabel>
            <OutlinedInput
              id="amount"
              type="number"
              label="Amount"
              {...register("amount")}
              defaultValue="0"
              onWheel={(event) => {
                event.target.blur();
              }}
            />
            {errors.amount && <FormHelperText>Enter an amount!</FormHelperText>}
          </FormControl>
        )}

        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button variant="outlined" type="reset" onClick={handleModal}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Submit
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default GuarantorApprovalForm;
