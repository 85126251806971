import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import MyThemeProvider from "./Theme/MyThemeProvider";
import { RouterProvider } from "react-router-dom";
import Router from "./router";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Provider } from "react-redux";
import store from "./Redux/store";
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MyThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <RouterProvider router={Router} />
        </LocalizationProvider>
      </MyThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
