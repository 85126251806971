import { get, post } from "./base";

const digiApi = {
  authentication: {
    login: (body) => {
      post("/login", body);
    },
  },
  users: {
    getAll: () => {
      return get("/entity");
    },
    getCustomers: (data) => {
      return get(`/entity?type=${data}`);
    },
  },
};

export default digiApi;
