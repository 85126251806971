import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

const AddAccountForm = ({
  account_t,
  AccountTypeID,
  account_type,
  handleModal,
  accLoading,
  addAccount,
}) => {
  const { register, handleSubmit } = useForm();
  return (
    <form
      onSubmit={handleSubmit(addAccount)}
      style={{ width: "250px", padding: "20px" }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="account_t">Account type</InputLabel>
          <Select
            labelId="account_t"
            id="account_t"
            defaultValue=""
            value={account_t}
            label="Account type"
            onChange={(e) => AccountTypeID(e.target.value)}
            required
          >
            {account_type.map((account) => (
              <MenuItem value={account.value} key={account.value}>
                {account.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "50px",
        }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Button variant="outlined" type="reset" onClick={() => handleModal()}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={accLoading}
        >
          Submit
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default AddAccountForm;
