import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FetchHook from "../../Api/FetchHooks";
import PageLoader from "../../Components/PageLoader";
import TableComponent from "../../Components/TableComponent";

const Loans = () => {
  const dispatch = useDispatch();
  // const loans = useSelector((state) => console.log("redux", state));
  const [check, setCheck] = useState(0);
  const [status, setStatus] = useState("active");

  const [loading, response, request] = FetchHook();

  useEffect(() => {
    if (check === 0) {
      request({ url: `loan?status=${status}` });
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "id",
      headerName: "Loan Id",
      width: 150,
    },
    {
      field: "entity_id",
      headerName: "Entity Id",
      width: 150,
    },
    {
      field: "product_id",
      headerName: "Product Id",
      width: 150,
    },
    {
      field: "inventory_id",
      headerName: "Inventory Id",
      width: 150,
    },
    {
      field: "supplier_id",
      headerName: "Supplier Id",
      width: 150,
    },
    {
      field: "request_id",
      headerName: "Request Id",
      width: 150,
    },
    {
      field: "initial_payment",
      headerName: "Initial payment",
      width: 150,
    },
    {
      field: "instalment_count",
      headerName: "Installment count",
      width: 150,
    },
    {
      field: "amount_disburse",
      headerName: "Amount disbursed",
      width: 150,
    },
    {
      field: "loan_principal",
      headerName: "Loan principal",
      width: 150,
    },
    {
      field: "principal_balance",
      headerName: "Principal balance",
      width: 150,
    },
    {
      field: "principal_instalment_balance",
      headerName: "Principal instalment balance",
      width: 150,
    },
    {
      field: "application_fee_amount",
      headerName: "Application fee amount",
      width: 150,
    },
    {
      field: "application_fee_bal",
      headerName: "Application fee balance",
      width: 150,
    },
    {
      field: "application_instalment_fee_bal",
      headerName: "Application instalment fee balance",
      width: 150,
    },
    {
      field: "insurance_amount",
      headerName: "Insurance amount",
      width: 150,
    },
    {
      field: "insurance_bal",
      headerName: "Insurance balance",
      width: 150,
    },
    {
      field: "insurance_instalment_bal",
      headerName: "Insurance instalment balance",
      width: 150,
    },
    {
      field: "other_charges",
      headerName: "Other charges",
      width: 150,
    },
    {
      field: "other_charges_bal",
      headerName: "Other charges balance",
      width: 150,
    },
    {
      field: "other_instalment_charges_bal",
      headerName: "Other instalment charges balance",
      width: 150,
    },
    {
      field: "loan_interest",
      headerName: "Loan interest",
      width: 150,
    },
    {
      field: "loan_interest_bal",
      headerName: "Loan interest balance",
      width: 150,
    },
    {
      field: "interest_last_date",
      headerName: "Last interest date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "rollover",
      headerName: "Rollover",
      width: 150,
    },
    {
      field: "rollover_interest",
      headerName: "Rollover interest",
      width: 150,
    },
    {
      field: "rollover_interest_bal",
      headerName: "Rollover interest balance",
      width: 150,
    },
    {
      field: "rollover_times",
      headerName: "Rollover times",
      width: 150,
    },
    {
      field: "rollover_start_date",
      headerName: "Rollover start date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "rollover_end_date",
      headerName: "Rollover end date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "rollover_next_date",
      headerName: "Rollover next date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "debt_collection",
      headerName: "Debt collection",
      width: 150,
    },
    {
      field: "debt_collection_bal",
      headerName: "Debt collection balance",
      width: 150,
    },
    {
      field: "debt_collection_start_date",
      headerName: "Debt colection start date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "debt_collection_end_date",
      headerName: "Debt collection end date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "last_contractual_instal_date",
      headerName: "last contractual installment date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "next_contractual_instal_date",
      headerName: "Next contractual installment date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "next_actual_instal_date",
      headerName: "Next actual installment date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "net_actual_instal_date",
      headerName: "Net actual installment date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "loan_start_date",
      headerName: "Loan start date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "loan_end_date",
      headerName: "Loan end date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "start_date",
      headerName: "Start date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "end_date",
      headerName: "End date",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
    },
    {
      field: "active_status",
      headerName: "Active status",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
  ];

  return (
    <Box sx={{ margin: "20px" }}>
      <Card>
        <CardHeader
          sx={{
            px: "25px",
          }}
          title={<Typography variant="h5">Loans</Typography>}
          action={
            <FormControl>
              <InputLabel id="status">Status</InputLabel>

              <Select
                labelId="loan_status"
                id="loan_status"
                value={status}
                label="status"
                onChange={(e) => {
                  setStatus(e.target.value);
                  setCheck(0);
                }}
                size="small"
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
                <MenuItem value="domant">Domant</MenuItem>
                <MenuItem value="suspended">Suspended</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
                <MenuItem value="incomplete">Incomplete</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <TableComponent
              data={response.data.Loan}
              columns={columns}
              loading={loading}
            />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Loans;
