import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersData: [],
  userData: [],
  userAccounts: [],
  userAccount: [],
  customerAccounts: [],
};

export const userSlice = createSlice({
  name: "user-data",
  initialState,
  reducers: {
    userData: (state, action) => {
      state.userData = action.payload;
    },
    usersData: (state, action) => {
      state.usersData = action.payload;
    },
    userAccounts: (state, action) => {
      state.userAccounts = action.payload;
    },
    userAccount: (state, action) => {
      state.userAccount = action.payload;
    },
    customerAccounts: (state, action) => {
      state.customerAccounts = action.payload;
    },
  },
});

export const {
  userData,
  usersData,
  userAccounts,
  userAccount,
  customerAccounts,
} = userSlice.actions;

export default userSlice.reducer;
