import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";

const UserLoanLogs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [check, setCheck] = useState(0);

  const [loading, response, request] = FetchHook();

  useEffect(() => {
    if (check === 0) {
      request({ url: `loan_log?loan_id='${location.state.id}'` });
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 150,
    },
    {
      field: "loan_id",
      headerName: "Loan Id",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "narrative",
      headerName: "Amount",
      width: 300,
    },
    {
      field: "date_created",
      headerName: "Date created",
      width: 300,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      <Card>
        <CardHeader
          sx={{
            px: "30px",
          }}
          title={
            <Typography variant="h6">
              Loan log(Loan_id: {location.state.id})
            </Typography>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <DataGrid
              autoHeight
              rows={response.data.LoanLog}
              columns={columns}
            />
          ) : (
            <Grid>
              <CircularProgress />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserLoanLogs;
