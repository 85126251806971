import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { stringAvatar } from "../../../../utils/helpers";
import PostHooks from "../../../../Api/PostHooks";
import { useEffect } from "react";
import { useState } from "react";
import AlertComponent from "../../../../Components/AlertComponent";
import { LoadingButton } from "@mui/lab";
import LoanRepaymentTabs from "./LoanRepaymentTabs";
import LoanProfileTabs from "./LoanProfileTabs";

const UserLoanProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state.loan;
  const _data = Object.entries(data);

  console.log("data", data);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});

  const [loading, response, request] = PostHooks();

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Request successful!",
      });
    } else if (response && response.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
    } else if (response && response.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.response.data.StatusMessage,
      });
    }
  }, [response]);

  return (
    <Box sx={{ p: { sm: "20px" } }}>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Card elevation={5}>
        <CardHeader
          title={<Typography variant="h5">Loan profile ({data.id})</Typography>}
          action={
            <Button
              variant="outlined"
              color="error"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          }
          sx={{ background: grey[200] }}
        />

        <CardContent sx={{}}>
          <LoanProfileTabs
            data={data}
            user={location.state.user}
            handleSnackbar={handleSnackbar}
            severity={severity}
            setSeverity={setSeverity}
          />
        </CardContent>
      </Card>

      {/* {_data.map((el, i) => (
        <Table key={i} sx={{ width: "600px" }}>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle1">{el[0]}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">{el[1]}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ))} */}
    </Box>
  );
};

export default UserLoanProfile;
