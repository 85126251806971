import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PostHooks from "../../../Api/PostHooks";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../Components/AlertComponent";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [open, setOpen] = useState();
  const [severity, setSeverity] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, response, request] = PostHooks();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = (data) => {
    let formData = {
      username: data.username,
      password: data.password,
      channel_name: "mobile_number",
    };

    let props = {
      url: "/check_login",
      body: formData,
    };

    request(props);
    // dispatch(userLogins(formData));
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Redirecting...",
      });

      let user = {
        username: username,
        channel_name: "mobile_number",
        password: password,
      };

      navigate("/user-accounts", {
        state: { userAccounts: response.data.User, userCredentials: user },
      });
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({ severity: "error", message: response.data.StatusMessage });
      handleSnackbar();
    } else if (response && response.status !== 200) {
      setSeverity({
        severity: "error",
        message: response.response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [response]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.username}>
          {/* <MuiTelInput
            defaultCountry="KE"
            value={msisdn}
            disableFormatting
            onChange={(e) => setMsisdn(e)}
            required
          /> */}
          <InputLabel htmlFor="username">Id Number</InputLabel>
          <OutlinedInput
            id="username"
            type="number"
            {...register("username", {
              required: true,
              maxLength: 11,
              minLength: 7,
            })}
            label="Id Number"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {errors.username && (
            <FormHelperText>Please enter a valid id number!</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.password}>
          <InputLabel htmlFor="password">Pin</InputLabel>
          <OutlinedInput
            id="password"
            label="Pin"
            {...register("password")}
            value={password}
            required
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && (
            <FormHelperText>Enter your password</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pt: "10px",
        }}
      >
        <Button
          variant="text"
          type="button"
          onClick={() => {
            navigate("/forgot-password");
          }}
        >
          Forgot password?
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          py: "10px",
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          onClick={() => navigate("/customer-registration")}
        >
          Register
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
          fullWidth
        >
          Login
        </LoadingButton>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
          Don’t have an account?{" "}
          <Link
            variant="subtitle2"
            component={""}
            to={"/customer-registration"}
          >
            Get started
          </Link>
        </Typography>
      </Grid> */}
    </form>
  );
};

export default LoginForm;
