import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { MuiTelInput } from "mui-tel-input";
import PostHooks from "../../../Api/PostHooks";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../Components/AlertComponent";

const RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState({});
  const [msisdn, setMsisdn] = useState();
  const [document_registration_date] = useState(new Date());
  const [document_expiry_date] = useState(new Date());
  const [documentType] = useState();

  const [userRole, setUserRole] = useState();

  const [loading, response, request] = PostHooks();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = async (e) => {
    let formData = {
      first_name: e.first_name,
      surname: e.surname,
      other_names: e.other_names,
      company_name: "",
      state: "",
      residential_address: "",
      postal_address: "",
      id_number: e.identification_number,
      passport_number: e.identification_number,
      county: "",
      subcounty: "",
      ward: " ",
      village: "",
      serial_no: "",
      msisdn: msisdn.replace("+", ""),
      document_registration_date: moment(document_registration_date).format(
        "YYYY-MM-DD"
      ),
      document_expiry_date: moment(document_expiry_date).format("YYYY-MM-DD"),
      name: "National ID",
      document_number: parseInt(e.identification_number),
      description: documentType,
      registry_file_number: "",
      role: userRole,
    };

    let props = {
      url: "/user",
      body: formData,
    };

    request(props);
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({ severity: "success", message: "Registration successful" });
      setOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({ severity: "error", message: response.data.StatusMessage });
      setOpen(true);
    }
  }, [response]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form">
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ padding: "20px" }}
      >
        <Grid
          item
          sm={4}
          xs={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="first_name">First name</InputLabel>
            <OutlinedInput
              id="first_name"
              {...register("first_name", { required: true })}
              label="First name"
              xs={12}
              variant="filled"
            />
            {errors.first_name && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="surname">Surname</InputLabel>
            <OutlinedInput
              variant="filled"
              id="surname"
              {...register("surname", { required: true })}
              label="Surname"
              xs={12}
            />
            {errors.surname && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="other_names">Other name</InputLabel>
            <OutlinedInput
              variant="filled"
              id="other_names"
              {...register("other_names", { required: true })}
              label="Other name"
              xs={12}
            />
            {errors.other_names && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            {/* <InputLabel htmlFor="msisdn">Phone number</InputLabel> */}
            <MuiTelInput
              defaultCountry="KE"
              value={msisdn}
              onChange={(val) => setMsisdn(val)}
              disableFormatting
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="user_role">User role</InputLabel>
            <Select
              labelId="user_role"
              id="user_role"
              defaultValue=""
              value={userRole}
              label="Identification type"
              onChange={(e) => setUserRole(e.target.value)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="finance">Finance</MenuItem>
              <MenuItem value="ccr">Ccr</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* 
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="document_type">Identification type</InputLabel>
            <Select
              labelId="document_type"
              id="document_type"
              defaultValue=""
              value={documentType}
              label="Identification type"
              onChange={(e) => setDocumenType(e.target.value)}
            >
              <MenuItem value="National ID">National Id</MenuItem>
              <MenuItem value="Passpot ID">Passport</MenuItem>
              <MenuItem value="Company Certificate">
                Company certificate
              </MenuItem>
              <MenuItem value="KRA PIN">KRA Pin</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Identification number</InputLabel>
            <OutlinedInput
              variant="filled"
              required
              id="identification_number"
              {...register("identification_number", { required: true })}
              label="Identification number"
              xs={12}
            />
            {errors.identification_number && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="company_name">Company name</InputLabel>
            <OutlinedInput
              variant="filled"
              id="company_name"
              {...register("company_name", { required: true })}
              label="Company name"
              xs={12}
            />
            {errors.company_name && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid> */}
        {/* 
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="state">State</InputLabel>
            <OutlinedInput
              id="state"
              {...register("state", { required: true })}
              label="State"
              xs={12}
            />
            {errors.state && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="Residential address">
              Residential address
            </InputLabel>
            <OutlinedInput
              id="residential_address"
              {...register("residential_address", { required: true })}
              label="Residential address"
              xs={12}
            />
            {errors.residential_address && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="postal_address">Postal address</InputLabel>
            <OutlinedInput
              id="postal_address"
              {...register("postal_address", { required: true })}
              label="Postal address"
              xs={12}
            />
            {errors.postal_address && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="county">County</InputLabel>
            <OutlinedInput
              id="county"
              {...register("county", { required: true })}
              label="County"
              xs={12}
            />
            {errors.county && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="sub_county">Sub County</InputLabel>
            <OutlinedInput
              id="sub_county"
              {...register("sub_county", { required: true })}
              label="Sub county"
              xs={12}
            />
            {errors.sub_county && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="ward">Ward</InputLabel>
            <OutlinedInput
              id="ward"
              {...register("ward", { required: true })}
              label="ward"
              xs={12}
            />
            {errors.ward && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="village">Village</InputLabel>
            <OutlinedInput
              id="village"
              {...register("village", { required: true })}
              label="village"
              xs={12}
            />
            {errors.village && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="serial">Serial number</InputLabel>
            <OutlinedInput
              variant="filled"
              required
              id="serial"
              {...register("serial", { required: true })}
              label="Registry file number"
              xs={12}
            />
            {errors.serial && <FormHelperText>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="registry_file_number">
              Registry file number
            </InputLabel>
            <OutlinedInput
              variant="filled"
              required
              id="registry_file_number"
              {...register("registry_file_number", { required: true })}
              label="Registry file number"
              xs={12}
            />
            {errors.registry_file_number && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid> 

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <DesktopDatePicker
              label="Document registration date"
              inputFormat="MM/DD/YYYY"
              value={document_registration_date}
              onChange={(e) => set_document_registration_date(e)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.document_registration_date && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <DesktopDatePicker
              label="Document expiry date"
              inputFormat="MM/DD/YYYY"
              value={document_expiry_date}
              onChange={(e) => set_document_expiry_date(e)}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.identification_number && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        <Grid
          item
          sx={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            type="reset"
            onClick={() => navigate(-1)}
          >
            cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ ml: "10px" }}
            size="large"
            type="submit"
            loading={loading}
          >
            register
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegistrationForm;
