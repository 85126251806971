import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FetchHook from "../../Api/FetchHooks";
import PostHooks from "../../Api/PostHooks";
import { inventories } from "../../Redux/inventorySlice";
import AddInventoryForm from "./Components/AddInventoryForm";
import { useLocation } from "react-router-dom";
import moment from "moment";

const Inventory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  const [loading, response, request] = FetchHook();
  const [addLoading, addResponse, addRequest] = PostHooks();

  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [product_type, set_product_type] = useState();

  const product_options = [
    {
      label: "Tv",
      value: "tv",
    },
    {
      label: "Fridge",
      value: "fridge",
    },
    {
      label: "Sms",
      value: "sms",
    },
  ];

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const fetchInventory = () => {
    request({ url: `supply_item` });
  };

  useEffect(() => {
    if (check === 0) {
      fetchInventory();
      setCheck(1);
    }

    if (response && response.status === 200) {
      dispatch(inventories(response.data.Data));
    }
  }, [check, response]);

  const getFullName = (params) => {
    return `${params.row.first_name || ""} ${params.row.other_names || ""}`;
  };

  const columns = [
    {
      field: "id",
      headerName: "Inventory id",
      width: 160,
    },
    {
      field: "supplier_id",
      headerName: "Supplier id",
      width: 150,
    },
    {
      field: "full_name",
      headerName: "Supplier name",
      valueGetter: getFullName,
      width: 150,
    },
    {
      field: "type",
      headerName: "Inventory type",
      width: 120,
    },
    {
      field: "name",
      headerName: "Name",
      width: 120,
    },
    {
      field: "model",
      headerName: "Model",
      width: 120,
    },
    {
      field: "size",
      headerName: "Size",
      width: 100,
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
    },
    {
      field: "remain",
      headerName: "Remain",
      width: 100,
    },
    {
      field: "date_created",
      headerName: "Date created",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "last_updated_on",
      headerName: "Updated on",
      width: 150,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  const addInventory = (e) => {
    let formData = {
      platform_onwer_id: user.id,
      supplier_entity_id: user.id,
      name: e.name,
      type: product_type,
      quantity: e.quantity,
      price: e.amount,
      model: e.model,
      size: e.size,
    };

    let props = {
      url: "supply_item",
      body: formData,
    };

    addRequest(props);
  };

  useEffect(() => {
    if (addResponse && addResponse.status === 200) {
      handleSnackbar();
      handleModal();
      setCheck(0);
      setSeverity("success");
      setMessage("Inventory added successfuly");
    } else if (addResponse && addResponse.status !== 200) {
      handleSnackbar();
      setSeverity("error");
      setMessage(addResponse.response.data.StatusMessage);
    }
  }, [addResponse]);

  return (
    <Box sx={{ padding: "20px", height: "100vh" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbar}
      >
        <Alert
          onClose={handleSnackbar}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle sx={{ background: grey[200] }}>
          Add Inventory Form
        </DialogTitle>
        <DialogContent>
          <AddInventoryForm
            addInventory={addInventory}
            handleModal={handleModal}
            product_type={product_type}
            set_product_type={set_product_type}
            product_options={product_options}
            addLoading={addLoading}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          title={<Typography variant="h5">All inventory</Typography>}
          action={
            <Button
              aria-label="add"
              variant="contained"
              onClick={() => handleModal()}
              disabled={user?.role !== "admin"}
            >
              Add inventory
            </Button>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <Grid>
              <DataGrid
                autoHeight
                columns={columns}
                rows={response.data.Data}
                loading={loading}
              />
            </Grid>
          ) : (
            <Grid display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Inventory;
