import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";
import TableComponent from "../../../../Components/TableComponent";
import PageLoader from "../../../../Components/PageLoader";

const UserLoanTransactions = ({ loan, user }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [check, setCheck] = useState(0);
  const [_loan, _setLoan] = useState(loan);
  const [_user, _setUser] = useState(user);

  console.log("_loan", _loan);

  useEffect(() => {
    if (user === undefined && loan === undefined) {
      _setLoan(location.state.loan);
      _setUser(location.state.user);
    }
  }, [loan, user]);

  const [loading, response, request] = FetchHook();

  useEffect(() => {
    if (check === 0 && _user?.id !== undefined) {
      request({
        url: `transaction?filter_value=${_user.id}&loan_id=${_loan.id}&loan_request_id=${_loan.request_id}&role=customer`,
      });
      setCheck(1);
    }
  }, [check, _user, _loan]);

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction Id",
      width: 150,
    },
    {
      field: "account_number",
      headerName: "Account Number",
      width: 150,
    },
    // {
    //   field: "source_transaction_id",
    //   headerName: "Transaction Id",
    //   width: 150,
    // },
    // {
    //   field: "dr_cr_pair_id",
    //   headerName: "dr_cr_pair_id",
    //   width: 150,
    // },
    // {
    //   field: "loan_id",
    //   headerName: "Loan Id",
    //   width: 150,
    // },
    // {
    //   field: "global_request_id",
    //   headerName: "Global request Id",
    //   width: 150,
    // },
    {
      field: "balance_before",
      headerName: "Balance before",
      width: 150,
    },
    {
      field: "debit",
      headerName: "Debit (money out)",
      width: 150,
    },
    {
      field: "credit",
      headerName: "Credit (money in)",
      width: 150,
    },
    {
      field: "balance_after",
      headerName: "Balance after",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "transaction_status",
      headerName: "Transaction status",
      width: 300,
    },
    // {
    //   field: "transaction_timestamp",
    //   headerName: "Transaction Timestamp",
    //   width: 300,
    //   valueFormatter: (params) =>
    //     moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    // },
    {
      field: "transaction_settlement_time",
      headerName: "Transaction Settlement Time",
      width: 300,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  console.log("response", response);

  return (
    <Box>
      <Card>
        <CardHeader
          // sx={{
          //   px: "30px",
          // }}
          title={
            <Typography variant="h6">
              User statements (Loan_id: {location.state.loan.id})
            </Typography>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <TableComponent
              data={response.data.Data}
              columns={columns}
              loading={loading}
            />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserLoanTransactions;
