import axios from "axios";
import {
  getToken,
  setToken,
  getUserDetails,
  getRefreshToken,
} from "../../utils/helpers";

const user = getUserDetails();

export const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type",
  },
});

const refreshToken = async () => {
  try {
    const resp = await axiosInstance.post("/refresh-token", {
      entity_id: user?.id,
      refresh_token: getRefreshToken(),
    });
    console.log("refresh token", resp.data);
    return resp.data;
  } catch (e) {
    console.log("Error", e);
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      config.headers["x-access-token"] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;

        const resp = await refreshToken();

        const access_token = resp.Token;

        setToken(access_token);

        originalRequest.headers["x-access-token"] = access_token;

        // Retry the original request
        return axios(originalRequest);
      } catch (error) {
        console.log("Failed to Refresh Token", error);
        throw error;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
