import React from "react";
import { useEffect } from "react";
import FetchHook from "../../../../Api/FetchHooks";
import { Box, Button, Chip } from "@mui/material";
import PageLoader from "../../../../Components/PageLoader";
import TableComponent from "../../../../Components/TableComponent";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UserLoanGuarantors = ({ user, loan }) => {
  const navigate = useNavigate();
  const [loading, response, request] = FetchHook();

  const fetchGuarantors = () => {
    request({
      url: `guarantors?loan_id=${loan?.request_id}&type=other_members&entity_id=${user?.id}`,
    });
  };

  useEffect(() => {
    fetchGuarantors();
  }, []);

  const columns = [
    { field: "id", headerName: "#", flex: 1, minWidth: 200 },
    { field: "loan_id", headerName: "Loan Id", flex: 1, minWidth: 200 },
    { field: "loan_name", headerName: "Loan Name", flex: 1, minWidth: 200 },
    // { field: "loan_amount", headerName: "Loan amount",flex: 1, minWidth: 200 },
    // { field: "saving_amount", headerName: "Savings amount",flex: 1, minWidth: 200 },
    {
      field: "guarantorship_amount",
      headerName: "Requested amount",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "guaranteered_amount",
      headerName: "Guaranteed amount",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "guarantorship_amount_bal",
    //   headerName: "Guarantorship amount balance",

    // },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        if (params.row.status === 0) {
          return (
            <Chip label="pending" color="warning" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 1) {
          return (
            <Chip label="accepted" color="success" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 3) {
          return (
            <Chip label="rejected" color="error" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 4) {
          return <Chip label="paid off" color="info" sx={{ color: "white" }} />;
        } else if (params.row.status === 5) {
          return (
            <Chip label="recovered" color="info" sx={{ color: "white" }} />
          );
        }
      },
      flex: 1,
      minWidth: 200,
    },
    {
      field: "date_created",
      headerName: "Date created",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY, hh:mm:ss a"),
    },
    {
      field: "last_updated_on",
      headerName: "last updated",
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY, hh:mm:ss a"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/dashboard/users/user-profile/loan-transactions", {
                state: { user: user, loan: loan },
              });
            }}
          >
            Statements
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      {loading ? (
        <PageLoader />
      ) : (
        response &&
        response.data.StatusCode === 0 && (
          <TableComponent
            data={response.data.Data}
            columns={columns}
            loading={loading}
          />
        )
      )}
    </Box>
  );
};

export default UserLoanGuarantors;
