import React from "react";
import { useForm } from "react-hook-form";
import AlertComponent from "../../../Components/AlertComponent";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { LoadingButton } from "@mui/lab";
import PostHooks from "../../../Api/PostHooks";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { getUserDetails } from "../../../utils/helpers";

const EditKinForm = ({
  user,
  handleModal,
  setSeverity,
  handleSnackbar,
  setCheck,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, response, request] = PostHooks();

  const onSubmit = (e) => {
    let formData = {
      entity_id: user.user?.id,
      next_kin_id: user.kin?.next_kin_id,
      allocation: e.allocation,
    };

    let props = {
      url: "/next_kin_allocation",
      body: formData,
    };

    request(props);
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      setSeverity({ severity: "success", message: "Update successful" });
      handleModal();
      handleSnackbar();
      setCheck(0);
    } else if (response && response.data.StatusCode !== 0) {
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [response]);

  console.log(user);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="form">
        <FormControl fullWidth error={!!errors.allocation}>
          <InputLabel htmlFor="allocation">Allocation</InputLabel>
          <OutlinedInput
            variant="filled"
            id="allocation"
            {...register("allocation", { required: true })}
            label="Allocation"
            type="number"
            defaultValue={user?.allocation}
          />
          {errors.allocation && <FormHelperText>Required</FormHelperText>}
        </FormControl>

        <Box
          sx={{
            pt: "20px",
            gap: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button size="large" type="reset" onClick={() => handleModal()}>
            cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ ml: "10px" }}
            size="large"
            type="submit"
            loading={loading}
          >
            Update
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default EditKinForm;
