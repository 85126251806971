import { useEffect, useState } from "react";

import { logout } from "../utils/helpers";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AutoLogout = ({ children }) => {
  let timer;
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1000000); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    handleModal();

    setTimeout(() => {
      logout();
      window.location.href = "/login";
    }, [100000]);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleModal}>
        <DialogContent
          sx={{
            my: "20px",
            width: "400px",
          }}
        >
          <Stack justifyContent={"center"} alignItems={"center"}>
            <ReportProblemRoundedIcon
              color="error"
              sx={{ fontSize: "300px" }}
            />
            <Typography variant="h6">
              You will be logged out due to inactivity!
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>

      {children}
    </div>
  );
};

export default AutoLogout;
