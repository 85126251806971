import axios from "axios";
import { getToken } from "../../utils/helpers";

export const baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_ENTITY
    : process.env.REACT_APP_BASE_URL_ENTITY;

const accInstance = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type",
  },
});

accInstance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      config.headers["x-access-token"] = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default accInstance;
