import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import FetchHook from "../../Api/FetchHooks";
import moment from "moment";
import PageLoader from "../../Components/PageLoader";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { green, grey, red } from "@mui/material/colors";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddDisabledOutlinedIcon from "@mui/icons-material/PersonAddDisabledOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import { DatePicker } from "@mui/x-date-pickers";

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [check, setCheck] = useState(0);

  const [loading, response, request] = FetchHook();

  const fetchData = () => {
    request({
      url: `/dashreport?start_date='${
        moment(startDate).format("YYYY-MM-DD") ??
        moment(new Date()).format("YYYY-MM-DD")
      }' &end_date='${
        moment(endDate).format("YYYY-MM-DD") ??
        moment(new Date()).format("YYYY-MM-DD")
      }'`,
    });
  };

  useEffect(() => {
    if (check === 0) {
      fetchData();
      setCheck(1);
    }
  }, [check, response]);

  return (
    <Box sx={{ p: "20px" }}>
      <Card elevation={4}>
        <CardHeader
          title={
            <Typography variant="h3" align="center">
              Admin Dashboard
            </Typography>
          }
        />
        <CardContent>
          <Grid
            container
            gap={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => {
                return (
                  <TextField {...params} label="Start Date" size="small" />
                );
              }}
            />{" "}
            ----{" "}
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => {
                return <TextField {...params} label="End Date" size="small" />;
              }}
            />
            {startDate !== undefined && endDate !== undefined && (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setCheck(0);
                }}
              >
                search
              </Button>
            )}
          </Grid>

          <Grid container>
            {loading ? (
              <PageLoader />
            ) : response &&
              response.status === 200 &&
              response.data.StatusCode === 0 ? (
              <Grid
                container
                spacing={4}
                sx={{
                  m: "20px",
                }}
              >
                {response.data.Dashboard.map((el, i) => (
                  <Grid item key={i} xs={6} md={6} lg={3}>
                    {el.label === "total_members" ? (
                      <Card
                        variant="outlined"
                        sx={{
                          background: green[400],
                          color: "white",
                        }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <PeopleAltOutlinedIcon sx={{ fontSize: 40 }} />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              All Members
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "fully_registered" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#ED7D3A", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <PersonOutlineOutlinedIcon
                                sx={{ fontSize: 40 }}
                              />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Fully Registered members
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "blocked_members" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#822E81", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <PersonOffOutlinedIcon sx={{ fontSize: 40 }} />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Blocked Numbers
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "exit_members" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#EA2B1F", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <PersonRemoveOutlinedIcon sx={{ fontSize: 40 }} />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Total Member Exits
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "suspended_members" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#886F68", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <PersonAddDisabledOutlinedIcon
                                sx={{ fontSize: 40 }}
                              />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Suspended Members
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "pending_members" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#31AFD4", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <PersonAddAltOutlinedIcon sx={{ fontSize: 40 }} />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Pending Members
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "loan_taken" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#39566A", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <CreditScoreOutlinedIcon sx={{ fontSize: 40 }} />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Total Loans Taken
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : el.label === "loan_repaid" ? (
                      <Card
                        variant="outlined"
                        sx={{ background: "#72A276", color: "white" }}
                      >
                        <CardHeader
                          avatar={
                            <Box
                              sx={{
                                background: grey[300],
                                p: "10px",
                                borderRadius: "6px",
                                color: "black",
                              }}
                            >
                              <AddCardOutlinedIcon sx={{ fontSize: 40 }} />
                            </Box>
                          }
                          title={
                            <Typography variant="subtitle2">
                              Total Loans Repaid
                            </Typography>
                          }
                          subheader={
                            <Typography variant="h5">{el.count}</Typography>
                          }
                        />
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              response &&
              response.status !== 200 && (
                <Grid
                  container
                  display={"flex"}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ m: "30px" }}
                >
                  <Icon sx={{ fontSize: 100 }}>
                    <CancelOutlinedIcon
                      sx={{ fontSize: 60, color: red[500] }}
                    />
                  </Icon>

                  <Typography align="center">
                    {response.response.data.StatusMessage}
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdminDashboard;
