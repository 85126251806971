import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LoginForm from "./components/LoginForm";
import { grey } from "@mui/material/colors";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { styled } from "@mui/material";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ForgotPassword = () => {
  const [show, setShow] = useState(false);

  const handleModal = () => {
    setShow(!show);
  };

  return (
    <Box title="Login">
      <Dialog open={show} onClose={handleModal}>
        <DialogTitle sx={{ background: grey[200] }}>
          <Typography variant="h6" align="left">
            Forgot password
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ m: "20px" }}>
          <ForgotPasswordForm handleModal={handleModal} />
        </DialogContent>
      </Dialog>

      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="column" alignItems="center" spacing={6}>
              <Box>
                <img
                  src={"/images/logo/dSacco.png"}
                  alt="logo"
                  width={150}
                  height={120}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                  Reset your password.
                </Typography>
                <Typography sx={{ color: "text.secondary" }} align="center">
                  Enter your details below.
                </Typography>
              </Box>
            </Stack>

            <ForgotPasswordForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Box>
  );
};

export default ForgotPassword;
