import React, { useCallback, useState } from "react";
import axiosInstance from "./base/axios";

const PostHooks = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState();

  const callApi = useCallback((pro) => {
    setLoading(true);
    axiosInstance
      .post(pro.url, pro.body)
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setData(err);

        console.log(err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return [isLoading, data, callApi];
};

export default PostHooks;
