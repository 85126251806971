import React from "react";
import FetchHook from "../../Api/FetchHooks";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useForm } from "react-hook-form";
import PostHooks from "../../Api/PostHooks";
import { LoadingButton } from "@mui/lab";
import AlertComponent from "../../Components/AlertComponent";
import moment from "moment";
import BoostShares from "./component/BoostShares";

const MemberShares = ({ user, fetchUser }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [loading, response, request] = FetchHook();
  const [buyLoading, buyResponse, buyRequest] = PostHooks();
  const [bidLoading, bidResponse, bidRequest] = PostHooks();
  const [sellLoading, sellResponse, sellRequest] = PostHooks();

  const [data, setData] = useState();
  const [check, setCheck] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [share, setShare] = useState({});

  const handleModal = () => {
    setOpen(!open);
  };

  const handleModal2 = () => {
    setOpen2(!open2);
  };

  const handleModal3 = () => {
    setOpen3(!open3);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const fetchShares = () => {
    request({
      url: `/member_shares?entity_id=${user.id}`,
    });
  };

  useEffect(() => {
    if (check === 0) {
      fetchShares();
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "id",
      headerName: "#",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "shares",
      headerName: "Shares",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "rate_per_share",
      headerName: "Rate per share",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "transactional_value",
      headerName: "Transactional value",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "transfer_id",
      headerName: "Transfer id",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "debit",
      headerName: "Debit (money out)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "credit",
      headerName: "Credit (money in)",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "balance_before",
      headerName: "Balance before",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "balance",
      headerName: "Balance",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "date_created",
      headerName: "Start date",
      minWidth: 170,
      flex: 1,
      valueFormatter: (param) =>
        moment(param?.value).format("DD/MM/YYYY hh:mm a"),
    },

    {
      field: "last_updated_on",
      headerName: "Last updated",
      minWidth: 170,
      flex: 1,
      valueFormatter: (param) =>
        moment(param?.value).format("DD/MM/YYYY hh:mm a"),
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   minWidth: 100,
    //   flex: 1,
    //   renderCell: (row) => (
    //     <Box>
    //       <Button
    //         variant="outlined"
    //         onClick={() => {
    //           handleModal2();
    //           setShare(row.row);
    //         }}
    //       >
    //         More
    //       </Button>
    //     </Box>
    //   ),
    // },
  ];

  const addShares = (e) => {
    let formData = {
      type: "buy",
      transafer_type:
        user.shares === 0 ? "first_sacco_sell" : "normal_sacco_sell",
      no_ofshares: e.no_of_shares,
      customer_entity_id: user.id,
    };

    buyRequest({
      url: "/shares",
      body: formData,
    });
  };

  useEffect(() => {
    if (buyResponse && buyResponse.data.StatusCode === 0) {
      fetchUser();
      setCheck(0);
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Share purchase successful",
      });
    } else if (buyResponse && buyResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: buyResponse.data.StatusMessage,
      });
    } else if (buyResponse && buyResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: buyResponse.response.data.StatusMessage,
      });
    }
  }, [buyResponse]);

  const sellShares = (e) => {
    let formData = {
      entity_id: user.id,
      rate: e.rate,
      no_ofshares: e.no_ofshares,
    };

    sellRequest({
      url: "/publish_shares",
      body: formData,
    });
  };

  useEffect(() => {
    if (sellResponse && sellResponse.data.StatusCode === 0) {
      setCheck(0);
      handleModal3();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Share sell successful",
      });
    } else if (sellResponse && sellResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: sellResponse.data.StatusMessage,
      });
    } else if (sellResponse && sellResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: sellResponse.data.StatusMessage,
      });
    }
  }, [sellResponse]);

  return (
    <>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Boost shares (KSH 10 per share)</DialogTitle>

        <DialogContent
          sx={{
            my: "20px",
            width: { lg: "400px" },
          }}
        >
          <BoostShares
            user={user}
            fetchUser={fetchUser}
            setSeverity={setSeverity}
            handleModal={handleModal}
            handleSnackbar={handleSnackbar}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={open3} onClose={handleModal3}>
        <DialogTitle>
          <Typography>Sell shares form</Typography>
        </DialogTitle>

        <DialogContent sx={{ width: { md: "300px" } }}>
          <form onSubmit={handleSubmit(sellShares)}>
            <FormControl
              fullWidth
              error={!!errors.no_ofshares}
              sx={{
                my: "20px",
              }}
            >
              <InputLabel htmlFor="no_ofshares">Number of shares</InputLabel>
              <OutlinedInput
                id="no_ofshares"
                type="number"
                {...register("no_ofshares", { required: true })}
                label="Number of shares"
                variant="filled"
              />
              {errors.no_ofshares && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <FormControl
              fullWidth
              error={!!errors.no_ofshares}
              sx={{
                my: "20px",
              }}
            >
              <InputLabel htmlFor="rate">Rate per share</InputLabel>
              <OutlinedInput
                id="rate"
                type="number"
                {...register("rate")}
                label="Rate per share"
                variant="filled"
              />
              {errors.rate && <FormHelperText>Required</FormHelperText>}
            </FormControl>

            <Box
              sx={{
                my: "15px",
                display: "flex",
                direction: "row",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" type="reset" onClick={handleModal3}>
                Cancel
              </Button>
              <LoadingButton
                loading={sellLoading}
                color="primary"
                variant="contained"
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          title={<Typography variant="h5">Member shares</Typography>}
          action={
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleModal}
              >
                Top up shares
              </Button>
              &nbsp;&nbsp;
              {/* <Button
                variant="contained"
                color="primary"
                startIcon={<RemoveIcon />}
                onClick={handleModal3}
              >
                Sell shares
              </Button> */}
            </Box>
          }
        />
        {loading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : response &&
          response.status === 200 &&
          response.data.StatusCode === 0 ? (
          <Box>
            {response.data.Data.length === 0 ? (
              <Typography variant="h6" align="center">
                No Shares to display, kindly top up!
              </Typography>
            ) : (
              <DataGrid
                autoHeight
                columns={columns}
                rows={response.data.Data}
              />
            )}
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" align="center">
              An error occured! Kindly reload.
            </Typography>
          </Box>
        )}
      </Card>
    </>
  );
};

export default MemberShares;
