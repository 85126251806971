import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridPagination,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FetchHook from "../../Api/FetchHooks";
import PutHooks from "../../Api/PutHook";
import TableComponent from "../../Components/TableComponent";
import PageLoader from "../../Components/PageLoader";
import PostHooks from "../../Api/PostHooks";
import AlertComponent from "../../Components/AlertComponent";
import { LoadingButton } from "@mui/lab";

const Transactions = () => {
  const [loan_approval, set_loan_approval] = useState("engine");
  const [status, setStatus] = useState("active");
  const [check, setCheck] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState({});
  const [transaction, setTransaction] = useState();

  const [loading, response, request] = FetchHook();
  const [reverseLoading, reverseResponse, reverseRequest] = PostHooks();

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  useEffect(() => {
    if (check === 0) {
      request({
        url: `/transaction?status='${status}'`,
      });
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transaction id",
      minWidth: 200,
      flex: 1,
    },
    // {
    //   field: "source_transaction_id",
    //   headerName: "Source transaction id",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "account_number",
      headerName: "Account number",
      minWidth: 200,
      flex: 1,
    },
    // {
    //   field: "loan_id",
    //   headerName: "Loan id",
    //   minWidth: 200,
    //   flex: 1,
    // },
    // {
    //   field: "global_request_id",
    //   headerName: "Global request id",
    //   minWidth: 200,
    //   flex: 1,
    // },
    // {
    //   field: "dr_cr_pair_id",
    //   headerName: "Dr Cr pair",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "debit",
      headerName: "Debit (money out)",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "credit",
      headerName: "Credit (money in)",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "balance_before",
      headerName: "Balance before",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "balance_after",
      headerName: "Balance after",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "transaction_status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "transaction_settlement_time",
      headerName: "Transaction settlement time",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "transaction_timestamp",
      headerName: "Transaction time",
      minWidth: 200,
      flex: 1,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (row) => (
        <LoadingButton
          variant="outlined"
          color="primary"
          loading={
            reverseLoading &&
            transaction?.transaction_id === row.row.transaction_id
          }
          onClick={() => {
            reverseTransaction(row.row);
            setTransaction(row.row);
          }}
        >
          Reverse
        </LoadingButton>
      ),
      width: 200,
    },
  ];

  const reverseTransaction = (data) => {
    let formData = {
      entity_id: data?.entity_id,
      transaction_id: data?.transaction_id,
    };

    reverseRequest({
      url: "/request_transaction",
      body: formData,
    });
  };

  useEffect(() => {
    if (reverseResponse && reverseResponse.data.StatusCode === 0) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Reversal initiated!",
      });
    } else if (reverseResponse && reverseResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: reverseResponse.data.StatusMessage,
      });
    } else if (reverseResponse && reverseResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: reverseResponse.response.data.StatusMessage,
      });
    }
  }, [reverseResponse]);

  return (
    <Box sx={{ margin: "20px" }}>
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">All Transactions</Typography>

              <FormControl>
                <InputLabel id="status">Status</InputLabel>

                <Select
                  labelId="loan_status"
                  id="loan_status"
                  value={status}
                  label="status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setCheck(0);
                  }}
                  size="small"
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="domant">Domant</MenuItem>
                  <MenuItem value="suspended">Suspended</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <TableComponent
              data={response.data.Data}
              columns={columns}
              loading={loading}
            />
          ) : (
            <PageLoader />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Transactions;
