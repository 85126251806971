import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FetchHook from "../../../../Api/FetchHooks";

const UserLoanInstalmentDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [check, setCheck] = useState(0);

  const [loading, response, request] = FetchHook();

  useEffect(() => {
    if (check === 0) {
      request({
        url: `instalment_detail?loan_instalment_id='${location.state.id}'`,
      });
      setCheck(1);
    }
  }, [check]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 180,
    },
    {
      field: "loan_instalment_id",
      headerName: "Loan Instalment id",
      width: 180,
    },
    {
      field: "instal_type",
      headerName: "Instalment type",
      width: 150,
    },
    {
      field: "expected_collection_amount",
      headerName: "Expected collection amount",
      width: 200,
    },
    {
      field: "actual_paid_amount",
      headerName: "Actual paid amount",
      width: 150,
    },
    {
      field: "balance_amount_payable",
      headerName: "Balance amount payable",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },

    {
      field: "date_created",
      headerName: "Date created",
      width: 300,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "receipt",
      headerName: "Receipt",
      width: 200,
      renderCell: (row) => (
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              "/dashboard/users/user-profile/loan-instalments/loan-instalment-details/receipt",
              {
                state: row.row,
              }
            )
          }
        >
          Loan Receipt
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      <Card>
        <CardHeader
          sx={{
            px: "30px",
          }}
          title={
            <Typography variant="h6">
              Loan instalments Details (instalment_id: {location.state.id})
            </Typography>
          }
          action={
            <Box>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Box>
          }
        />
        <CardContent>
          {response && response.status === 200 ? (
            <DataGrid
              autoHeight
              rows={response.data.InstalmentDetail}
              columns={columns}
            />
          ) : (
            <Grid>
              <CircularProgress />
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserLoanInstalmentDetails;
