import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import { useState } from "react";
import UserLoanDetails from "./UserLoanDetails";
import UserLoanGuarantors from "./UserLoanGuarantors";
import UserLoanTransactions from "./UserLoanTransactions";
import UserLoanInstalment from "./UserLoanInstalments";

const LoanProfileTabs = ({
  data,
  handleSnackbar,
  severity,
  setSeverity,
  user,
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="user tabs"
            allowScrollButtonsMobile
            variant="scrollable"
            textColor="secondary"
            indicatorColor="secondary"
            scrollButtons="auto"
          >
            <Tab label="Details" value="1" />
            <Tab label="Guarantors" value="2" />
            <Tab label="Loan Statements" value="3" />
            <Tab label="Loan Instalments" value="4" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <UserLoanDetails
            data={data}
            handleSnackbar={handleSnackbar}
            severity={severity}
            setSeverity={setSeverity}
          />
        </TabPanel>

        <TabPanel value="2">
          <UserLoanGuarantors loan={data} user={user} />
        </TabPanel>

        <TabPanel value="3">
          <UserLoanTransactions loan={data} user={user} />
        </TabPanel>

        <TabPanel value="4">
          <UserLoanInstalment loan={data} user={user} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LoanProfileTabs;
