import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { createContext, useState } from "react";
import { getThemeName, setThemeName } from "../utils/helpers";
import getThemeByName from "./base";
import DefaultTheme from "./defaultTheme";

const MyThemeContext = createContext(() => {});

const MyThemeProvider = ({ children }) => {
  const [themeName, _setThemeName] = useState(getThemeName());
  const theme = getThemeByName(themeName);

  const setTheme = (val) => {
    _setThemeName(val);
    setThemeName(val);
  };

  return (
    <MyThemeContext.Provider value={() => ({ setTheme, themeName })}>
      <ThemeProvider theme={theme}>
        <>
          {/* <CssBaseline /> */}
          {children}
        </>
      </ThemeProvider>
    </MyThemeContext.Provider>
  );
};

export default MyThemeProvider;
