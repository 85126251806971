import { Alert, Snackbar } from "@mui/material";
import React from "react";

const AlertComponent = ({ open, handleSnackbar, severity }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleSnackbar}
        severity={severity.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {severity.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;
