import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PostHooks from "../../../Api/PostHooks";
import AlertComponent from "../../../Components/AlertComponent";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [loading, response, request] = PostHooks();
  const [resetLoading, resetResponse, resetRequest] = PostHooks();

  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState({});
  const msisdn = watch("msisdn");

  const handleSnackbar = () => {
    setShow(!show);
  };

  const onSubmit = () => {
    if (msisdn === undefined || msisdn.length < 13) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: "Please enter a valid phone number",
      });
    } else {
      let formData = {
        msisdn: msisdn.replace("+", ""),
      };

      request({
        url: "/forgot_password",
        body: formData,
      });
    }
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Otp sent, check phone!",
      });
    } else if (response && response.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
    }
  }, [response]);

  const onSubmitOtp = (e) => {
    let formData = {
      msisdn: msisdn.replace("+", ""),
      otp: e.otp,
      pin: e.pin,
    };

    resetRequest({
      url: "/reset_password",
      body: formData,
    });
  };

  useEffect(() => {
    if (resetResponse && resetResponse.data.StatusCode === 0) {
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Pin reset successful.Redirecting...",
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (resetResponse && resetResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: resetResponse.data.StatusMessage,
      });
    } else if (response && response.status !== 200) {
      setSeverity({
        severity: "error",
        message: response.response.data.StatusMessage,
      });
      handleSnackbar();
    }
  }, [resetResponse]);

  return (
    <>
      <AlertComponent
        open={show}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      {response === undefined ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "20px",
            }}
          >
            <Controller
              name="msisdn"
              control={control}
              rules={{
                validate: (value) => matchIsValidTel(value),
              }}
              defaultValue={"+254"}
              render={({ field, fieldState }) => (
                <MuiTelInput
                  {...field}
                  fullWidth
                  defaultCountry="KE"
                  onlyCountries={["KE"]}
                  helperText={
                    fieldState.invalid ? "Invalid mobile number!" : ""
                  }
                  error={fieldState.error}
                  value={field.value || ""}
                  disableFormatting
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "30px",
              gap: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button variant="outlined" fullWidth onClick={() => navigate(-1)}>
              Back
            </Button>
            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              loading={loading}
              fullWidth
            >
              Reset
            </LoadingButton>
          </Grid>
        </form>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmitOtp)}>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.otp}>
                <InputLabel htmlFor="otp">Otp</InputLabel>
                <OutlinedInput
                  id="otp"
                  type="number"
                  {...register("otp", {
                    required: true,
                    maxLength: 4,
                    minLength: 4,
                  })}
                  label="Otp"
                />
                {errors.otp && (
                  <FormHelperText>Please enter a valid otp!</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "20px",
              }}
            >
              <FormControl fullWidth error={!!errors.pin}>
                <InputLabel htmlFor="pin">New pin</InputLabel>
                <OutlinedInput
                  id="pin"
                  {...register("pin", {
                    required: true,
                  })}
                  label="New pin"
                />
                {errors.pin && (
                  <FormHelperText>Please enter your pin!</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                paddingTop: "30px",
                gap: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="outlined" fullWidth onClick={() => navigate(-1)}>
                Back
              </Button>
              <LoadingButton
                variant="contained"
                size="large"
                type="submit"
                loading={resetLoading}
                fullWidth
              >
                Reset
              </LoadingButton>
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default ForgotPasswordForm;
