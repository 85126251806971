import React, { useEffect } from "react";
import FetchHook from "../../../../Api/FetchHooks";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import GuarantorApprovalForm from "./GuarantorApprovalForm";
import TableComponent from "../../../../Components/TableComponent";
import PageLoader from "../../../../Components/PageLoader";

const GuarantorNominations = ({ user }) => {
  const [loading, response, request] = FetchHook();

  const [data, setData] = useState([]);
  const [nomination, setNomination] = useState();
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(0);

  const handleModal = () => {
    setOpen(!open);
  };

  const fetchNominations = () => {
    request({ url: `guarantors?type=other_members&entity_id=${user.id}` });
  };

  useEffect(() => {
    if ((user?.id && response === undefined) || check === 0) {
      fetchNominations();
      setCheck(1);
    }

    if (response && response.status === 200 && response.data.StatusCode === 0) {
      setData(response.data.Data);
    }
  }, [response, check]);

  const columns = [
    { field: "id", headerName: "#", width: 150 },
    { field: "loan_id", headerName: "Loan Id", width: 160 },
    { field: "loan_name", headerName: "Loan Name", width: 150 },
    // { field: "loan_amount", headerName: "Loan amount",flex: 1, minWidth: 200 },
    // { field: "saving_amount", headerName: "Savings amount",flex: 1, minWidth: 200 },
    {
      field: "guarantorship_amount",
      headerName: "Requested amount",
    },
    {
      field: "guaranteered_amount",
      headerName: "Guaranteed amount",
    },
    // {
    //   field: "guarantorship_amount_bal",
    //   headerName: "Guarantorship amount balance",

    // },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        if (params.row.status === 0) {
          return (
            <Chip label="pending" color="warning" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 1) {
          return (
            <Chip label="accepted" color="success" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 3) {
          return (
            <Chip label="rejected" color="error" sx={{ color: "white" }} />
          );
        } else if (params.row.status === 4) {
          return <Chip label="paid off" color="info" sx={{ color: "white" }} />;
        } else if (params.row.status === 5) {
          return (
            <Chip label="recovered" color="info" sx={{ color: "white" }} />
          );
        }
      },
    },
    {
      field: "date_created",
      headerName: "Date created",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY, hh:mm:ss a"),
    },
    {
      field: "last_updated_on",
      headerName: "last updated",
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY, hh:mm:ss a"),
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          params.row.status === 0 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleModal();
                setNomination(params.row);
              }}
            >
              Approve
            </Button>
          )
        );
      },
    },
  ];

  return (
    <div>
      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>
          <Typography variant="h6">Guarantor approval form</Typography>
        </DialogTitle>

        <DialogContent
          sx={{
            m: "20px",
            width: "300px",
          }}
        >
          <GuarantorApprovalForm
            data={nomination}
            setCheck={setCheck}
            handleModal={handleModal}
          />
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader
          title={
            <Typography variant="h5">
              You have {data.length} guarantor nominations.
            </Typography>
          }
        />

        <CardContent>
          {data.length > 0 ? (
            <TableComponent data={data} columns={columns} loading={loading} />
          ) : loading ? (
            <PageLoader />
          ) : (
            <Typography align="center">
              You have no guarantor nominations!
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default GuarantorNominations;
