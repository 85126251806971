import React from "react";
import { Avatar } from "@mui/material";

function UserAvatar({ size }) {
  const clientName = "Onfon";

  return (
    <Avatar sx={{ width: size, height: size }} alt="profile pic">
      {clientName.charAt(0)}
    </Avatar>
  );
}

export default UserAvatar;
