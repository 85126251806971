import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import PostHooks from "../../Api/PostHooks";
import { LoadingButton } from "@mui/lab";
import { getUserDetails } from "../../utils/helpers";
import { useEffect } from "react";

const CreatePoolBasket = ({
  handleModal,
  handleSnackbar,
  setSeverity,
  setCheck,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const LoggedInUser = getUserDetails();

  const [loading, response, request] = PostHooks();

  const onSubmit = (e) => {
    let formData = {
      entity_id: LoggedInUser?.id,
      name: e.name,
    };

    request({
      url: "pool_fund_basket",
      body: formData,
    });
  };

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      handleModal();
      handleSnackbar();
      setSeverity({
        severity: "success",
        message: "Pool basket created successfully!",
      });
      setCheck(0);
    } else if (response && response.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
    }
  }, [response]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        fullWidth
        error={!!errors.name}
        sx={{
          mt: "5px",
        }}
      >
        <InputLabel htmlFor="name">Name</InputLabel>
        <OutlinedInput
          id="name"
          {...register("name", { required: true })}
          label="Name"
          xs={12}
          variant="filled"
        />
        {errors.first_name && <FormHelperText>Required</FormHelperText>}
      </FormControl>

      <Box
        sx={{
          pt: "20px",
          gap: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          type="reset"
          onClick={() => handleModal()}
        >
          cancel
        </Button>

        <LoadingButton
          variant="contained"
          sx={{ ml: "10px" }}
          size="large"
          type="submit"
          loading={loading}
        >
          Create
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CreatePoolBasket;
