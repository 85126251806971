import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AlertComponent from "../../Components/AlertComponent";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FetchHook from "../../Api/FetchHooks";
import { MuiTelInput } from "mui-tel-input";

const FindCustomer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState();
  const [msisdn, setMsisdn] = useState();
  const [severity, setSeverity] = useState({});

  const [loading, response, request] = FetchHook();

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const onSubmit = () => {
    if (
      (msisdn && msisdn.length < 13) ||
      (msisdn && msisdn.length > 13) ||
      msisdn === undefined
    ) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: "Enter a correct number",
      });
    } else {
      request({
        url: `entity?type=customer&filter_value=${msisdn.replaceAll("+", "")}`,
      });
    }
  };

  console.log("response", response);

  useEffect(() => {
    if (response && response.data.StatusCode === 0) {
      navigate("/dashboard/users/user-profile", {
        state: { user: response.data.Data[0] },
      });
    } else if (response && response.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: response.data.StatusMessage,
      });
    }
  }, [response]);

  return (
    <Box
      sx={{
        p: "20px",
        width: { md: "500px", sm: "fit-content" },
      }}
    >
      <AlertComponent
        open={open}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Typography gutterBottom variant="h5" component="div">
        Member search
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <FormControl fullWidth>
            <MuiTelInput
              defaultCountry="KE"
              value={msisdn}
              disableFormatting
              onChange={(e) => setMsisdn(e)}
              required
              fullWidth
            />
            {errors.msisdn && (
              <FormHelperText>Phone number required!</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="contained"
            type="submit"
            size="large"
            sx={{
              my: "20px",
            }}
          >
            search
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default FindCustomer;
