import { useCallback, useState } from "react";
import axiosInstance from "./base/axios";

const FetchHook = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState();

  const fetchCallback = useCallback((pro) => {
    setLoading(true);
    axiosInstance
      .get(pro.url)
      .then((res) => {
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setData(err);
        setLoading(false);
        console.log("err", err);
      });
  });

  return [isLoading, data, fetchCallback];
};

export default FetchHook;
