import {
  Box,
  Stack,
  Container,
  Typography,
  Button,
  alpha,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PostHooks from "../../Api/PostHooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { login } from "../../Redux/authSlice";
import { userData } from "../../Redux/userSlice";
import { setToken } from "../../utils/helpers";
import { useEffect } from "react";
import AlertComponent from "../../Components/AlertComponent";
import AddKinForm from "../Users/Components/AddKinForm";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const AccountsPage = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState(location.state.userCredentials);
  const [accounts] = useState(location.state.userAccounts);
  const [account, setAccount] = useState();
  const [severity, setSeverity] = useState({});
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [check, setCheck] = useState(0);

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  // custom request hooks
  const [loading, response, request] = PostHooks();

  const logInUser = (acc) => {
    let formData = {
      ...user,
      msisdn: acc?.msisdn,
    };

    request({
      url: "/login",
      body: formData,
    });
  };

  useEffect(() => {
    if (check === 1 && account?.msisdn) {
      logInUser(account);
      setCheck(0);
    }

    if (response && response.data.StatusCode === 0) {
      dispatch(login(response.data));
      dispatch(userData(response.data.PayLoad));
      setSeverity({
        severity: "success",
        message: "Login successful, redirecting...",
      });

      setTimeout(() => {
        navigate("/dashboard", { state: { user: response.data.PayLoad } });
      }, 2500);
      handleSnackbar();
    } else if (response && response.data.StatusCode === 2) {
      setSeverity({ severity: "error", message: response.data.StatusMessage });
      handleSnackbar();
      setTimeout(() => {
        navigate(-1);
      }, 2500);
    } else if (response && response.data.StatusCode === 3) {
      dispatch(login(response.data));
      setSeverity({ severity: "error", message: response.data.StatusMessage });
      handleSnackbar();
      handleModal();
      setUser({
        ...user,
        id: response.data.Entity,
      });
    } else if (response && response.data.StatusCode === 6) {
      setSeverity({ severity: "error", message: response.data.StatusMessage });
      handleSnackbar();
      setTimeout(() => {
        navigate(-1);
      }, 2500);
    }
  }, [response, check]);

  return (
    <Box title="Login">
      <AlertComponent
        open={openSnackbar}
        handleSnackbar={handleSnackbar}
        severity={severity}
      />

      <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Add next of kin form</DialogTitle>
        <DialogContent>
          <AddKinForm
            handleModal={handleModal}
            user={user}
            setCheck={setCheck}
          />
        </DialogContent>
      </Dialog>

      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="column" alignItems="center" spacing={6}>
              <Box>
                <img
                  src={"/images/logo/dSacco.png"}
                  alt="logo"
                  width={150}
                  height={120}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                  Welcome back, sign in.
                </Typography>
                <Typography sx={{ color: "text.secondary" }} align="center">
                  Select account to log in.
                </Typography>
              </Box>
            </Stack>

            <Stack
              sx={{
                mt: "20px",
              }}
            >
              {accounts.map((el, i) => (
                <Box
                  key={i}
                  sx={[
                    {
                      border: "1px solid black",
                      margin: "6px",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    },
                    {
                      "&:hover": {
                        backgroundColor: alpha(theme.palette.primary.main, 0.2),
                      },
                    },
                  ]}
                  onClick={() => {
                    logInUser(el);
                    setAccount(el);
                  }}
                >
                  {el.msisdn}

                  {loading && account?.msisdn === el.msisdn ? (
                    <CircularProgress size={20} />
                  ) : response &&
                    response.data.StatusCode === 0 &&
                    account?.msisdn === el.msisdn ? (
                    <CheckCircleIcon color="success" />
                  ) : response &&
                    response.data.StatusCode !== 0 &&
                    account?.msisdn === el.msisdn ? (
                    <ErrorIcon color="error" />
                  ) : null}
                </Box>
              ))}
            </Stack>

            <Button
              sx={{ mt: "20px" }}
              fullWidth
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Box>
  );
};

export default AccountsPage;
