import { LoadingButton } from "@mui/lab";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FetchHook from "../../../../Api/FetchHooks";
import PostHooks from "../../../../Api/PostHooks";

const ApplyLoanForm = ({
  handleModal,
  user,
  handleSnackbar,
  setSeverity,
  fetchUser,
  fetchLoan,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [product, setProduct] = useState();
  const [check, setCheck] = useState(0);
  const [productsOpt, setProducts] = useState();
  const [guarantorshipOpt, setGuarantorshipOpt] = useState([
    { label: "Self", value: "other_members" },
    { label: "Other members", value: "other_members" },
    { label: "Assets", value: "assets" },
    { label: "Pool fund", value: "pool_fund" },
  ]);
  const [guarantorship, setGuarantorship] = useState([]);

  const [productsLoading, productsResponse, productsRequest] = FetchHook();
  const [applyLoading, applyResponse, applyRequest] = PostHooks();

  const fetchUserProduct = () => {
    productsRequest({ url: `/product?status='active'` });
  };

  useEffect(() => {
    if (check === 0) {
      fetchUserProduct();
      setCheck(1);
    }

    if (productsResponse && productsResponse.status === 200) {
      setProducts(productsResponse.data.Loan);
    }
  }, [check, productsResponse]);

  const applyLoan = (e) => {
    let formData = {
      product_id: product.id,
      entity_id: user.id,
      amount: e.amount,
      guarantor_type: JSON.stringify(guarantorship),
      description: "Loan application request recorded",
      repayment_count: e.repayment_count,
    };

    let props = {
      url: "loan_request",
      body: formData,
    };
    applyRequest(props);
  };

  useEffect(() => {
    if (applyResponse && applyResponse.data.StatusCode === 0) {
      fetchUser();
      fetchLoan(0);
      handleSnackbar();
      handleModal();
      setSeverity({
        severity: "success",
        message: "Loan application successful!",
      });
    } else if (applyResponse && applyResponse.data.StatusCode !== 0) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: applyResponse.data.StatusMessage,
      });
    } else if (applyResponse && applyResponse.status !== 200) {
      handleSnackbar();
      setSeverity({
        severity: "error",
        message: applyResponse.response.data.StatusMessage,
      });
    }
  }, [applyResponse]);

  return (
    <form onSubmit={handleSubmit(applyLoan)}>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="product">Loan product</InputLabel>
          <Select
            labelId="product"
            id="product"
            defaultValue=""
            value={product}
            label="Account type"
            onChange={(e) => setProduct(e.target.value)}
            required
          >
            {productsOpt &&
              productsOpt.map((product) => (
                <MenuItem value={product} key={product.id}>
                  {product.loan_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.upto_saving_percentage}>
          <InputLabel htmlFor="amount">Amount</InputLabel>
          <OutlinedInput
            fullWidth
            id="amount"
            type="number"
            label="Amount"
            {...register("amount")}
            onWheel={(event) => {
              event.target.blur();
            }}
          />
          {errors.amount && <FormHelperText>Enter loan amount!</FormHelperText>}
        </FormControl>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        sx={{
          paddingTop: "20px",
        }}
      >
        <FormControl fullWidth error={!!errors.repayment_count}>
          <InputLabel htmlFor="amount">
            Repayment period (max: {product?.instalment_counts} min:{" "}
            {product?.minimum_instalment_count})
          </InputLabel>
          <OutlinedInput
            fullWidth
            id="repayment_count"
            type="number"
            label={`Repayment period (max: ${product?.instalment_counts} min:{" "}
            ${product?.minimum_instalment_count})`}
            {...register("repayment_count")}
            onWheel={(event) => {
              event.target.blur();
            }}
          />
          {errors.repayment_count && (
            <FormHelperText>Enter loan repayment count!</FormHelperText>
          )}
        </FormControl>
      </Grid>

      {product?.guarantorship === "YES" && (
        <Grid
          item
          xs={12}
          sx={{
            paddingTop: "20px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="guarantorship">Guarantorship</InputLabel>
            <Select
              labelId="guarantorship"
              id="guarantorship"
              defaultValue=""
              multiple
              value={guarantorship}
              label="Guarantorship"
              onChange={(e) => {
                setGuarantorship(e.target.value);
              }}
              required={product?.guarantorship === "YES"}
            >
              {guarantorshipOpt.map((el, i) => (
                <MenuItem key={i} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "50px",
        }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Button variant="outlined" type="reset" onClick={() => handleModal()}>
          Cancel
        </Button>
        <LoadingButton variant="contained" type="submit" loading={applyLoading}>
          Submit
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default ApplyLoanForm;
